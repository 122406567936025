import { NuvelTabsForm } from "components/nuvel/forms";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import { ProdutoModel } from "data/models";
import React from "react";

import TabEstoque from "./tabs/estoque";
import TabEtiquetas from "./tabs/etiquetas";
import TabImposto from "./tabs/imposto";
import TabPrecos from "./tabs/precos";
import TabPrincipal from "./tabs/principal";
import TabComposicao from "./tabs/composicao";

const RetProdutosForm: React.FC = () => {
  const [state, setState] = React.useState<ProdutoSerializer>({
    codigo: null,
    nome: "",
    codbarras: [],
    classificacao: null,
    unidade: null,
    departamento: null,
    cfop: "",
    ncm: "",
    cest: "",
    imposto: undefined,
    preco_venda: 0,
    preco_custo: 0,
    estoques: [],
    precos: [],
    usa_balanca: false,
    proibe_pesagem_manual: false,
    estoque_minimo: 0,
    tipo_composto: null,
    produto_composto: false,
    insumos: [],
    tags: [],
    grades: [],
    perecivel: false,
    perecivel_dias: 0,
    prioridade: 0,
    tipo_insumo: null,
    flag_1: false,
    flag_2: false,
    flag_3: false,
    flag_4: false,
    flag_5: false,
    flag_6: false,
    flag_7: false,
    flag_8: false,
    flag_9: false,
    flag_10: false,
    flag_11: false,
    registrar_individualmente: false,
    codigo_beneficiario: "",
    incinde_pis_cofins: false,
    cst_pis: 0,
    cst_cofins: 0,
    /**
     * precos: [{
     *  descricao: string,
     *  produto: id,
     *  precos: [{
     *    codbarra: id || txt,
     *    preco_venda: number
     *  }]
     * }]
     */
  });

  const Tabs = [
    {
      title: "Principal",
      name: "principal",
      children: <TabPrincipal state={state} setState={setState} />,
    },
    {
      title: "Preços",
      name: "precos",
      children: <TabPrecos state={state} setState={setState} />,
    },
    {
      title: "Estoque",
      name: "estoque",
      children: <TabEstoque state={state} setState={setState} />,
    },
    {
      title: "Composição",
      name: "composicao",
      children: <TabComposicao state={state} setState={setState} />,
      hidden: () => !state.produto_composto,
    },
    {
      title: "Imposto",
      name: "imposto",
      children: <TabImposto state={state} setState={setState} />,
    },
    {
      title: "Etiquetas",
      name: "etiquetas",
      children: <TabEtiquetas state={state} setState={setState} />,
    },
  ];

  return (
    <NuvelTabsForm
      tabs={Tabs}
      model={ProdutoModel}
      state={state}
      setState={setState}
    />
  );
};

export default RetProdutosForm;
