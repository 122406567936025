import { NuvelDefaultList } from "components/nuvel/lists";
import {
  RETAGUARDA_CATALOGO_FILTERS,
  RETAGUARDA_CATALOGO_ORDERING,
} from "constants/retaguarda/catalogo";
import { RETAGUARDA_CATALOGO_LIST } from "constants/retaguarda/catalogo";
import { CatalogoModel } from "data/models";
import React from "react";

const RetCatalogoList: React.FC = () => {
  return (
    <NuvelDefaultList
      model={CatalogoModel}
      columns={RETAGUARDA_CATALOGO_LIST}
      filters={RETAGUARDA_CATALOGO_FILTERS}
      ordering={RETAGUARDA_CATALOGO_ORDERING}
      actions={{
        create: true,
        update: true,
        delete: true,
      }}
    />
  );
};

export default RetCatalogoList;
