import { pdf } from "@react-pdf/renderer";
import printJS from "print-js";

export async function imprimirDoc(doc: React.ReactElement) {
  const startTime = performance.now();
  console.log(`PDF generation started at ${startTime}`);
  const blob = await pdf(doc).toBlob();

  const endTime = performance.now();
  const totalTime = endTime - startTime;
  console.log(
    `PDF generation ended at ${endTime} took ${totalTime} milliseconds.`
  );

  const pdfUrl = URL.createObjectURL(blob);
  printJS({
    printable: pdfUrl,
    onPrintDialogClose: () => {
      URL.revokeObjectURL(pdfUrl);
    },
  });
}
