import React from "react";
import SwipeableViews from "react-swipeable-views";
import { incluirState } from "../states";

import ListItems from "./listItems";
import {
  NotaDeSaidaSerializer,
  NotaDeSaidaSerializerFinalidadeEnum,
  NotaDeSaidaSerializerStatusEnum,
} from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import { NotaDeSaidaItensSerializer } from "data/interfaces/financeiro/NotaDeSaidaItensSerializer";
import { ProdutoListSerializer } from "data/interfaces/estoque/ProdutoListSerializer";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  SelectChangeEvent,
  Tab,
  Tabs,
  Theme,
  useTheme,
} from "@mui/material";
import TabPanel from "components/nuvel/TabPanel";
import { useAppContext } from "hooks";
import Modal from "components/nuvel/Modal";
import { handleChange } from "utils/functions";
import Grid from "components/nuvel/Grid";
import Select from "components/nuvel/Select";
import TextField from "components/nuvel/TextField";
import DatePicker from "components/nuvel/DatePicker";
import Box from "@mui/material/Box";
import { Add } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardWithValue from "components/nuvel/CardWithValue";
import nota_de_saida from "data/models/fiscal/nota_de_saida";
import { errorObjectConvert } from "utils/functions/error";

// import { Container } from './styles';
function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    "& .MuiBox-root": {
      paddingTop: theme.spacing(1),
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

interface CreateEditSaidaDeNotaProps {
  modal: {
    id: number;
    open: boolean;
  };
  handleModal: (id: number) => void;
  handleSecModal: (id: number) => void;
  stateNota: NotaDeSaidaSerializer;
  setStateNota: React.Dispatch<React.SetStateAction<NotaDeSaidaSerializer>>;
  setStateNotaItem: React.Dispatch<
    React.SetStateAction<NotaDeSaidaItensSerializer>
  >;
  refreshNota: () => void;
  listProds: {
    rows: ProdutoListSerializer[];
  };
}

function CreateEditSaidaDeNota(props: CreateEditSaidaDeNotaProps) {
  const {
    modal,
    handleModal,
    handleSecModal,
    stateNota,
    setStateNota,
    setStateNotaItem,
    refreshNota,
    listProds,
  } = props;
  const tabstyle = useStyles();
  const [tabs, setTabs] = React.useState(0);
  const theme = useTheme();
  const {
    showSnack,
    dialog: { showMessage },
  } = useAppContext();

  const deleteItem = (id: number) => {
    const _temp = [...stateNota.itens];
    _temp.splice(id, 1);
    return setStateNota((_v) => ({
      ..._v,
      itens: _temp,
    }));
  };

  const editItem = (id: number) => {
    setStateNotaItem({ ...stateNota.itens[id], id: id });
    return handleSecModal(1);
  };

  function handleChangeTab(_event: React.SyntheticEvent, newValue: number) {
    setTabs(newValue);
  }

  const submitForm = () => {
    const { id, ...corpo } = stateNota;
    let body;
    if (id) {
      body = stateNota;
    } else {
      body = { ...corpo };
    }
    nota_de_saida
      .save(body)
      .then((res) => {
        if (res) {
          showSnack("Criado com Sucesso!", 2, "success");
        } else {
          showSnack("Erro 400!", 1, "error");
        }
        setStateNota(incluirState);
        refreshNota();
        handleModal(1);
      })
      .catch((err) => {
        showMessage(
          errorObjectConvert(err),
          "Houve um erro ao processar seu pedido"
        );
      });
  };

  const getTitle = () => {
    if (stateNota.status === 2) {
      return "Visualização de Nota de Saída";
    }
    if ([0, 3].includes(stateNota.status)) {
      return "Edição de Nota de Saída";
    }
    return "Inclusão de Nota de Saída";
  };

  const addItemDisabled = () => {
    if (stateNota.status === NotaDeSaidaSerializerStatusEnum.Emitida) {
      return true;
    }
    if (stateNota.status === NotaDeSaidaSerializerStatusEnum.Cancelada) {
      return true;
    }
    if (stateNota.status === NotaDeSaidaSerializerStatusEnum.Excluída) {
      return true;
    }
    return !stateNota.emitente;
  };

  const saveDisabled = () => {
    return addItemDisabled() || !stateNota.total || !stateNota.subtotal;
  };

  return (
    <Modal
      title={getTitle()}
      fullScreen
      open={modal.id === 1 ? modal.open : false}
      btnCancel={() => {
        setStateNota(incluirState);
        handleModal(1);
      }}
      btnSave={submitForm}
      btnSaveDisabled={saveDisabled()}
    >
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Grid container spacing={2}>
            <Grid xs={12} md={2}>
              <Select
                label="Tipo de Operação"
                name="tipo"
                onChange={(e: SelectChangeEvent) =>
                  handleChange(e, setStateNota)
                }
                value={stateNota.tipo}
                options={[
                  { label: "Entrada", value: 0 },
                  { label: "Saída", value: 1 },
                ]}
              />
            </Grid>
            {stateNota.tipo === 1 && (
              <Grid xs={12} md={3}>
                <NuvelAutocomplete
                  label="Cliente"
                  name="cliente"
                  value={Number(stateNota.cliente)}
                  onChange={(e) => handleChange(e, setStateNota)}
                  reference="cliente"
                />
              </Grid>
            )}
            <Grid xs={12} md={3}>
              <NuvelAutocomplete
                label="Loja"
                name="emitente"
                value={Number(stateNota.emitente)}
                onChange={(e, _nv, object) => {
                  handleChange(e, setStateNota);
                  if (!object) return;
                  setStateNota((v) => ({
                    ...v,
                    serie: object.nfe_serie,
                    numero: object.nfe_sequencia,
                  }));
                }}
                reference="loja"
              />
            </Grid>
            <Grid>
              <TextField
                label="Número"
                name="numero"
                value={stateNota.numero}
                onChange={(e) => handleChange(e, setStateNota)}
              />
            </Grid>
            <Grid>
              <TextField
                label="Série"
                name="serie"
                value={stateNota.serie}
                onChange={(e) => handleChange(e, setStateNota)}
              />
            </Grid>
            <Grid>
              <Select
                label="Modelo"
                name="modelo"
                onChange={(e) => handleChange(e, setStateNota)}
                value={stateNota.modelo}
                options={[{ label: "55", value: 55 }]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid container spacing={2}>
            <Grid xs={12} md={2}>
              <Select
                label="Finalidade da NF-e"
                name="finalidade"
                onChange={(e) => handleChange(e, setStateNota)}
                value={stateNota.finalidade}
                options={[
                  { label: "NF-e normal", value: "1" },
                  { label: "NF-e complementar", value: "2" },
                  { label: "NF-e de ajuste", value: "3" },
                  { label: "Devolução de mercadoria", value: "4" },
                ]}
              />
            </Grid>
            <Grid xs={12} md={2}>
              <Select
                label="Natureza da Operação"
                name="natureza_operacao"
                onChange={(e) => handleChange(e, setStateNota)}
                value={stateNota.natureza_operacao}
                options={[
                  { label: "Venda", value: "Venda" },
                  { label: "Compra", value: "Compra" },
                  { label: "Transferência", value: "Transferência" },
                  { label: "Devolução", value: "Devolução" },
                  { label: "Importação", value: "Importação" },
                  { label: "Consignação", value: "Consignação" },
                  { label: "Remessa", value: "Remessa" },
                ]}
              />
            </Grid>
            <Grid xs={12} md={4}>
              <Select
                label="Presença da NF-e"
                name="presenca"
                onChange={(e) => handleChange(e, setStateNota)}
                value={stateNota.presenca}
                options={[
                  { label: "Não se aplica", value: 0 },
                  { label: "Operação presencial", value: 1 },
                  { label: "Operação não presencial pela internet", value: 2 },
                  {
                    label: "Operação não presencial, Teleatendimento",
                    value: 3,
                  },
                  {
                    label: "Operação presencial, entrega em domicílio",
                    value: 4,
                  },
                  {
                    label: "Operação presencial, fora do estabelecimento",
                    value: 5,
                  },
                  {
                    label: "Operação não presencial, outros",
                    value: 6,
                  },
                ]}
              />
            </Grid>
            <Grid xs={12} md={2}>
              <DatePicker
                label="Data Emissão"
                onChange={(e) => handleChange(e, setStateNota)}
                name="data_emissao"
                value={stateNota.data_emissao}
              />
            </Grid>
            <Grid xs={12} md={2}>
              <DatePicker
                label="Data Saída"
                onChange={(e) => handleChange(e, setStateNota)}
                name="data_saida"
                value={stateNota.data_saida}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <AppBar position="static" style={{ zIndex: 0 }}>
            <Tabs
              value={tabs}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              style={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <Tab label="Itens" {...a11yProps(0)} />
              <Tab label="Transportador" {...a11yProps(0)} />
              <Tab label="Observações" {...a11yProps(0)} />
              {stateNota.finalidade ===
                NotaDeSaidaSerializerFinalidadeEnum["Devolução/Retorno"] && (
                <Tab label="Notas Fiscais Referenciadas" {...a11yProps(0)} />
              )}
            </Tabs>
          </AppBar>
          <SwipeableViews
            index={tabs}
            onChangeIndex={(index) => setTabs(index)}
            style={{ width: "100%" }}
          >
            <TabPanel
              value={tabs}
              index={0}
              classes={{ root: tabstyle.tab }}
              style={{
                position: "relative",
              }}
            >
              <ListItems
                body={stateNota}
                deleteItem={deleteItem}
                editItem={editItem}
                listProds={listProds}
              />
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Box pt={2}>
                  <Fab
                    onClick={() => handleSecModal(1)}
                    style={{ width: 70, height: 70 }}
                    color="primary"
                    aria-label="add"
                    disabled={addItemDisabled()}
                  >
                    <Add />
                  </Fab>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={tabs} index={1}>
              <Grid container spacing={1}>
                <Grid xs={5}>
                  <NuvelAutocomplete
                    label="Transportador"
                    name="transportador"
                    value={Number(stateNota.transportador)}
                    onChange={(e) => handleChange(e, setStateNota)}
                    reference="transportador"
                  />
                </Grid>
                <Grid xs={3}>
                  <Select
                    label="Frete por Conta"
                    name="transportador_frete"
                    value={stateNota.transportador_frete}
                    onChange={(e) => handleChange(e, setStateNota)}
                    options={[
                      { label: "Emitente", value: 0 },
                      { label: "Destinatário", value: 1 },
                      { label: "Terceiros", value: 2 },
                      { label: "Sem Frete", value: 9 },
                    ]}
                  />
                </Grid>
                <Grid xs={2}>
                  <TextField
                    label="Placa"
                    name="transportador_placa"
                    value={stateNota.transportador_placa}
                    onChange={(e) => handleChange(e, setStateNota)}
                  />
                </Grid>
                <Grid xs={2}>
                  <TextField
                    label="UF"
                    name="transportador_uf"
                    value={stateNota.transportador_uf}
                    onChange={(e) => handleChange(e, setStateNota)}
                    normalize={(str) => str.toUpperCase()}
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    label="Quantidade"
                    name="transportador_quantidade"
                    value={stateNota.transportador_quantidade}
                    onChange={(e) => handleChange(e, setStateNota)}
                    type="number"
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    label="Espécie"
                    name="transportador_especie"
                    value={stateNota.transportador_especie}
                    onChange={(e) => handleChange(e, setStateNota)}
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    label="Marca"
                    name="transportador_marca"
                    value={stateNota.transportador_marca}
                    onChange={(e) => handleChange(e, setStateNota)}
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    type="number"
                    label="Número"
                    name="transportador_numero"
                    value={stateNota.transportador_numero}
                    onChange={(e) => handleChange(e, setStateNota)}
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    decimal
                    label="Peso Bruto"
                    name="transportador_peso_bruto"
                    value={stateNota.transportador_peso_bruto}
                    onChange={(e) => handleChange(e, setStateNota)}
                  />
                </Grid>
                <Grid xs={3}>
                  <TextField
                    decimal
                    label="Peso Líquido"
                    name="transportador_peso_liquido"
                    value={stateNota.transportador_peso_liquido}
                    onChange={(e) => handleChange(e, setStateNota)}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabs} index={2}>
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <TextField
                    label="Informações Adicionais"
                    name="informacoes_adicionais_interesse_fisco"
                    value={stateNota.informacoes_adicionais_interesse_fisco}
                    onChange={(e) => handleChange(e, setStateNota)}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 2000 }}
                    fullWidth
                  />
                </Grid>
                <Grid style={{ alignSelf: "center" }}>
                  <Typography variant="h6">
                    {stateNota.natureza_operacao}
                  </Typography>
                </Grid>
                <Grid xs={8}>
                  <TextField
                    label="Natureza da Operação - Informações Complementares"
                    name="natureza_operacao_desc"
                    value={stateNota.natureza_operacao_desc}
                    onChange={(e) => handleChange(e, setStateNota)}
                    inputProps={{ maxLength: 2000 }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </TabPanel>
            {stateNota.finalidade ===
            NotaDeSaidaSerializerFinalidadeEnum["Devolução/Retorno"] ? (
              <TabPanel value={tabs} index={3}>
                {stateNota.notas_fiscais_referenciadas
                  .split(",")
                  .map((v, i) => (
                    <Box
                      key={"notas_fiscais_referenciadas_" + i}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      style={{ marginTop: 24, marginBottom: 24 }}
                    >
                      <TextField
                        label="Chaves de acesso referenciadas"
                        name="notas_fiscais_referenciadas"
                        value={v}
                        onChange={(e) => {
                          const _temp = [
                            ...stateNota.notas_fiscais_referenciadas.split(","),
                          ];
                          _temp[i] = e.target.value;
                          setStateNota((v) => ({
                            ...v,
                            notas_fiscais_referenciadas: _temp.join(","),
                          }));
                        }}
                      />
                      <Button
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                          const _temp = [
                            ...stateNota.notas_fiscais_referenciadas.split(","),
                          ];
                          _temp.splice(i, 1);
                          setStateNota((v) => ({
                            ...v,
                            notas_fiscais_referenciadas: _temp.join(","),
                          }));
                        }}
                      >
                        Remover
                      </Button>
                    </Box>
                  ))}
                <Button
                  onClick={() => {
                    setStateNota((v) => ({
                      ...v,
                      notas_fiscais_referenciadas:
                        v.notas_fiscais_referenciadas + ",",
                    }));
                  }}
                >
                  Adicionar
                </Button>
              </TabPanel>
            ) : (
              <></>
            )}
          </SwipeableViews>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid xs={8}>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ marginTop: 3, marginBottom: 3 }}
          >
            <Grid>
              <CardWithValue
                title="Descontos"
                value={stateNota.total_desconto.toDecimal(2)}
              />
            </Grid>
            <Grid>
              <CardWithValue
                title="Acréscimos"
                value={stateNota.total_acrescimo.toDecimal(2)}
              />
            </Grid>
            <Grid>
              <CardWithValue
                title="Valor Total da Nota"
                value={stateNota.total.toDecimal(2)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default CreateEditSaidaDeNota;
