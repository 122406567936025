/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { NuvelPaginationComponent } from "components/nuvel/Pagination";
import dayjs from "dayjs";
import React from "react";

type Accessor<T> = string | ((row: T, index: number) => any);

interface RowProps<T> {
  columns: any;
  row: T;
  onRowClick?: (row: T, index: number) => void;
  cellStyle: any;
  rowProps?: (row: T) => any;
  index: number;
  formatbytype: (
    data: T,
    accessor: Accessor<T>,
    type: any,
    index: number
  ) => any;
}

function Row<T>(props: RowProps<T>) {
  return (
    <React.Fragment>
      <TableRow
        hover
        key={props.index}
        className={
          props.onRowClick
            ? {
                transition: "0.1s",
                cursor: "pointer",
              }
            : {
                transition: "0.1s",
              }
        }
        onClick={() =>
          props.onRowClick
            ? props.onRowClick(props.row, props.index)
            : undefined
        }
        {...(props.rowProps ? props.rowProps(props.row) : {})}
      >
        {props?.columns?.map((col: any, key1: any) => {
          return (
            <TableCell
              key={key1}
              sx={{
                padding: 1,
              }}
              style={{ ...props.cellStyle, ...col.style }}
              width={col.width}
              align={col.align}
            >
              {typeof col.accessor === "function"
                ? col.accessor(props.row, props.index)
                : props.formatbytype(
                    props.row,
                    col.accessor,
                    col.type,
                    props.index
                  )}
            </TableCell>
          );
        })}
      </TableRow>
    </React.Fragment>
  );
}

type ColumnType =
  | "text"
  | "date"
  | "datetime"
  | "time"
  | "percent"
  | "money"
  | "number";

interface Column<T> {
  accessor: Accessor<T>;
  label: string;
  type?: ColumnType;
  align?: "center" | "left" | "right" | "inherit" | "justify" | undefined;
  width?: string;
  style?: React.CSSProperties;
  hstyle?: React.CSSProperties;
  renderHeader?: (col: Column<T>) => React.ReactNode;
}

interface LegacySimpleTableProps<T> {
  columns: Column<T>[];
  rows: T[];
  className?: string;
  style?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  cellStyle?: React.CSSProperties;
  onRowClick?: (row: T, index: number) => void;
  rowProps?: (row: T) => any;
  totals?: any;
  setPage?: (page: number) => void;
  page?: number;
  pageSize?: number;
  totalCount?: number;
  setRows?: (rows: T[]) => void;
}

function LegacySimpleTable<T>(props: LegacySimpleTableProps<T>) {
  function formatbytype(
    data: T,
    accessor: Accessor<T>,
    type: ColumnType,
    index: number
  ) {
    if (!data) return;
    if (typeof accessor === "function") {
      return accessor(data, index);
    }
    const final_data =
      typeof data === "object" ? (data as any)[accessor] : data;
    switch (type) {
      case "date":
        return dayjs(final_data).format("DD/MM/YYYY");
      case "datetime":
        return dayjs(final_data).format("DD/MM/YYYY HH:mm");
      case "time":
        return dayjs(final_data).format("HH:mm");
      case "percent":
        return `${final_data.toDecimal(2)}%`;
      case "money":
        return final_data?.toBRL();
      default:
        return final_data;
    }
  }

  return (
    <Paper
      style={{
        overflowX: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Table className={props.className} style={props.style}>
        <TableHead>
          <TableRow>
            {props.columns?.map((col: any, key: any) => {
              if (col.renderHeader) return col.renderHeader(col);
              return (
                <TableCell
                  key={key}
                  sx={{
                    padding: 1,
                    fontWeight: "bold",
                  }}
                  style={{ ...props.headerStyle, ...col.hstyle }}
                  align={col.align}
                >
                  {col.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {(props.rows || []).map((row: any, key: any) => (
            <Row
              key={key}
              columns={props.columns}
              row={row}
              onRowClick={props.onRowClick}
              cellStyle={props.cellStyle}
              rowProps={props.rowProps}
              formatbytype={formatbytype}
              index={key}
            />
          ))}
          {props?.totals && (
            <TableRow
              hover
              sx={
                props.onRowClick
                  ? {
                      transition: "0.1s",
                      cursor: "pointer",
                    }
                  : {
                      transition: "0.1s",
                    }
              }
            >
              {props?.columns?.map((col, key1) => {
                if (typeof col.accessor === "function") {
                  return <TableCell key={key1} />;
                }
                if (!props.totals[col.accessor])
                  return <TableCell key={key1} />;
                return (
                  <TableCell
                    key={key1}
                    sx={{
                      padding: 1,
                    }}
                    style={{ ...props.cellStyle, ...col.style }}
                    width={col.width}
                    align={col.align}
                  >
                    {formatbytype(
                      props.totals[col.accessor],
                      col.accessor,
                      col.type || "text",
                      key1
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
      {props.setPage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <NuvelPaginationComponent
            count={props.totalCount || 0}
            page={props.page || 1}
            setPage={props.setPage}
            pageSize={props.pageSize || 10}
          />
        </div>
      )}
    </Paper>
  );
}

export default LegacySimpleTable;
