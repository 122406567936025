import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputField from "components/legacy/inputField";
import LegacySimpleTable from "components/legacy/legacy_simple_list";
import NuvelAccordion from "components/nuvel/Accordion";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelGrid from "components/nuvel/Grid";
import { CodBarraSerializer } from "data/interfaces/estoque/CodBarraSerializer";
import { ComposicaoSerializer } from "data/interfaces/estoque/ComposicaoSerializer";
import { ProdutoSerializer } from "data/interfaces/estoque/ProdutoSerializer";
import React from "react";

interface Props {
  state: ProdutoSerializer;
  setState: React.Dispatch<React.SetStateAction<ProdutoSerializer>>;
}

function TabPrincipal(props: Props) {
  const { state, setState } = props;
  const [localState, setLocalState] = React.useState<CodBarraSerializer | null>(
    null
  );

  const addInsumo = (
    insumo: CodBarraSerializer | null,
    cod_barra: CodBarraSerializer
  ) => {
    if (insumo?.id && cod_barra.cod_barra) {
      const codbarras = state.codbarras.map((c) => {
        if (c.cod_barra == cod_barra.cod_barra) {
          c.insumos = [
            ...(c.insumos || []),
            {
              insumo: insumo.id,
              insumo_nome: insumo.produto_nome,
              quantidade: 1,
            },
          ];
        }
        return c;
      });
      setState((o) => ({ ...o, codbarras }));
      setLocalState(null);
    }
  };

  const removeInsumo = (
    insumo: ComposicaoSerializer,
    cod_barra: CodBarraSerializer
  ) => {
    if (insumo.insumo && cod_barra.id) {
      const codbarras = state.codbarras.map((c) => {
        if (c.id == cod_barra.id) {
          c.insumos = c.insumos.filter((i) => i.insumo != insumo.insumo);
        }
        return c;
      });
      setState((o) => ({ ...o, codbarras }));
    }
  };

  const handleChangeInsumoValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    indexInsumo: number,
    indexCodBarra: number
  ) => {
    const { value, name } = e.target;
    const codbarras = [...state.codbarras];
    const insumos = [...codbarras[indexCodBarra].insumos];
    insumos[indexInsumo] = {
      ...insumos[indexInsumo],
      [name]: Number(value || "0"),
    };
    codbarras[indexCodBarra] = {
      ...codbarras[indexCodBarra],
      insumos,
    };
    return setState((_v) => ({
      ..._v,
      codbarras,
    }));
  };

  return (
    <NuvelGrid container spacing={1}>
      {state.codbarras.map((codbarra, indexCodBarra) => (
        <NuvelAccordion
          startExpanded={indexCodBarra === 0 ? true : false}
          title={codbarra.cod_barra}
          key={indexCodBarra}
          sx={{ width: "100%" }}
        >
          <NuvelGrid container spacing={1} sx={{ width: "100%" }}>
            <NuvelGrid xs={12} md={10}>
              <NuvelAutocomplete
                label="Produto"
                name="produto"
                value={Number(localState?.id)}
                onChange={(_e, _v, r) => {
                  setLocalState(r || null);
                }}
                filter={{
                  produto__classificacao: 10, // Insumos
                }}
                reference="cod_barra"
                clearAfterSelect
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={2}>
              <div
                style={{
                  height: "100%",
                  paddingTop: 5,
                  paddingBottom: 20,
                }}
              >
                <Button
                  onClick={() => addInsumo(localState, codbarra)}
                  color="primary"
                >
                  + INCLUIR ITEM
                </Button>
              </div>
            </NuvelGrid>
            <NuvelGrid xs={12}>
              <LegacySimpleTable
                style={{ width: "100%" }}
                columns={[
                  { accessor: "insumo_nome", label: "Nome", width: "80%" },
                  {
                    accessor: (insumo, index) => {
                      return (
                        <InputField
                          money
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeInsumoValue(e, index, indexCodBarra)
                          }
                          name="quantidade"
                          value={Number(insumo.quantidade)}
                          style={{ textAlignLast: "center" }}
                        />
                      );
                    },
                    label: "Quantidade",
                    align: "center",
                  },
                  {
                    accessor: (ins) => (
                      <IconButton
                        onClick={() => removeInsumo(ins, codbarra)}
                        size="small"
                      >
                        <Delete fontSize="inherit" />
                      </IconButton>
                    ),
                    label: "",
                    align: "right",
                  },
                ]}
                rows={codbarra.insumos}
                setRows={(r) => {
                  setState((o) => ({
                    ...o,
                    codbarras: o.codbarras.map((c) =>
                      c.id == codbarra.id ? { ...c, insumos: r } : c
                    ),
                  }));
                }}
                onRowClick={() => undefined}
              />
            </NuvelGrid>
          </NuvelGrid>
        </NuvelAccordion>
      ))}
    </NuvelGrid>
  );
}

export default TabPrincipal;
