import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Paper,
} from "@mui/material";
import { LocalShipping, Store } from "@mui/icons-material";
import React from "react";
import { useCatalog } from "layouts/catalogo/context";

interface DeliveryTypeSelectProps {
  value?: "pickup" | "delivery";
  onChange: (type: "pickup" | "delivery") => void;
}

export const DeliveryTypeSelect: React.FC<DeliveryTypeSelectProps> = ({
  value,
  onChange,
}) => {
  const { state } = useCatalog();
  const DELIVERY_FEE = state.store?.delivery_price || 0;

  return (
    <Paper sx={{ mt: 4, p: 2 }}>
      <Typography variant="h6" mb={2}>
        Forma de recebimento
      </Typography>

      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <RadioGroup
          value={value || ""}
          onChange={(e) => onChange(e.target.value as "pickup" | "delivery")}
        >
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
            }}
          >
            <FormControlLabel
              disabled={!state.store?.allow_pickup}
              value="pickup"
              control={<Radio />}
              label={
                <Box display="flex" alignItems="center" gap={1}>
                  <Store color="action" />
                  <Box>
                    <Typography variant="body1">Retirar no local</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Grátis
                    </Typography>
                  </Box>
                </Box>
              }
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                p: 1,
                m: 0,
              }}
            />

            <FormControlLabel
              disabled={!state.store?.allow_delivery}
              value="delivery"
              control={<Radio />}
              label={
                <Box display="flex" alignItems="center" gap={1}>
                  <LocalShipping color="action" />
                  <Box>
                    <Typography variant="body1">Entrega</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Taxa de entrega: {DELIVERY_FEE.toBRL()}
                    </Typography>
                  </Box>
                </Box>
              }
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                p: 1,
                m: 0,
              }}
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};
