import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import CartItems from "../components/cart/CartItems";
import DeliveryForm from "../components/cart/DeliveryForm";
import PaymentForm from "../components/cart/PaymentForm";
import { useCatalog } from "../context";

export type DeliveryType = "pickup" | "delivery";
export interface CartState {
  deliveryType?: DeliveryType;
  address?: {
    cep?: string;
    street?: string;
    number?: string;
    complement?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
  };
  payment?: {
    id?: number;
    change?: number; // troco para pagamento em dinheiro
  };
}

const Cart: React.FC = () => {
  const { state } = useCatalog();
  const [activeStep, setActiveStep] = useState(0);
  const [cartState, setCartState] = useState<CartState>({});

  const steps = [
    { label: "Carrinho", component: CartItems },
    ...(cartState.deliveryType === "delivery"
      ? [{ label: "Endereço", component: DeliveryForm }]
      : []),
    { label: "Pagamento", component: PaymentForm },
  ];

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  if (state.cart.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <Typography variant="h6" color="text.secondary">
          Seu carrinho está vazio
        </Typography>
      </Box>
    );
  }

  const CurrentComponent = steps[activeStep].component;

  return (
    <Box p={2}>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <CurrentComponent
        cartState={cartState}
        setCartState={setCartState}
        onNext={handleNext}
        onBack={handleBack}
        isLastStep={activeStep === steps.length - 1}
      />
    </Box>
  );
};

export default Cart;
