/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import LegacySimpleTable from "components/legacy/legacy_simple_list";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelCheckbox from "components/nuvel/Checkbox";
import NuvelDatePicker from "components/nuvel/DatePicker";
import { NuvelDefaultForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import { ProdutoModel, PromocaoModel } from "data/models";
import dayjs from "dayjs";
import React from "react";
import { handleChange } from "utils/functions";

function Form_Promocoes() {
  const [state, setState] = React.useState<any>({
    cod_barras: "",
    descricao: "",
    tipo: 0,
    tipo_entrada: 0,
    entrada: null,
    tipo_desconto: 0,
    desconto: 0,
    data_inicio: dayjs(),
    data_fim: dayjs(),
    data_indeterminada: 0,
  });

  const [localState, setLocalState] = React.useState<any>({
    temp_produto: null,
    produtos: [],
  });

  const addInsumo = (produto: any) => {
    setLocalState({
      produtos: [
        ...localState.produtos.filter((i: any) => i.id != produto.id),
        {
          id: produto.id,
          codigo: produto.codigo,
          nome: produto.nome,
          quantidade: 1,
        },
      ],
      temp_produto: null,
    });
  };

  const removeInsumo = (produto: any, _index?: any) => {
    setLocalState((o: any) => ({
      ...o,
      produtos: [...localState.produtos.filter((i: any) => i.id != produto.id)],
    }));
  };

  React.useEffect(() => {
    setState((o: any) => ({
      ...o,
      entrada:
        localState.produtos
          .map((p: any) => p.codigo + ";" + p.quantidade)
          .join(";") + ";",
    }));
  }, [localState.produtos]);

  function formatBackendEntranda(dados: any) {
    if (dados.tipo_entrada === 3) {
      //A entrada do backend é formada por codigo;quantidade;codigo;quantidade...
      //Separa em um array [codigo, quantidade, codigo, quantidade]
      const entradas = dados.entrada.split(";");
      const array_final: any = [];
      for (let index = 0; index < entradas.length; index++) {
        //Evita pegar entradas vazias
        if (entradas[index] !== "") {
          //Pega o código, e a quantidade (proximo item)
          array_final.push({
            codigo: entradas[index],
            quantidade: entradas[index + 1],
          });
          index++;
        }
      }
      //Pega no backend os produtos relatovios a estes códigos
      ProdutoModel.get(undefined, {
        codigo__in: array_final.map((p: any) => p.codigo).join(","),
      }).then((dados) => {
        //Através dos dados retornados, popula o localstate com os
        //nomes dos produtos
        setLocalState((o: any) => ({
          ...o,
          produtos: array_final.map((p: any) => ({
            ...p,
            nome: dados.rows.find((pd: any) => pd.codigo === p.codigo).nome,
          })),
        }));
      });
      //Não fique com raiva de mim, sei que isso não é elegante porém
      //a aplicação legacy funcionava desta forma, futuramente vamos
      //deixar isso em um array bonitinho sem precisar converter
      //Escrevi isso dia 22/03/2022 às 22:00 - Felipe Correa
    }
  }

  return (
    <NuvelDefaultForm
      state={state}
      setState={setState}
      model={PromocaoModel}
      onLoad={formatBackendEntranda}
    >
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={4}>
          <NuvelTextField
            label="Cód. Barras"
            onChange={(e) => handleChange(e, setState)}
            name="cod_barras"
            value={state.cod_barras}
          />
        </NuvelGrid>
        <NuvelGrid xs={8}>
          <NuvelTextField
            label="Descrição"
            onChange={(e) => handleChange(e, setState)}
            name="descricao"
            value={state.descricao}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelSelect
            label="Tipo"
            value={state.tipo}
            onChange={(e) => handleChange(e, setState)}
            name="tipo"
            options={[
              { label: "Customizada", value: 0 },
              { label: "Kit de Produto", value: 1 },
            ]}
          />
        </NuvelGrid>
        <NuvelGrid xs={8}>
          <NuvelSelect
            label="Tipo de Entrada"
            value={state.tipo_entrada}
            onChange={(e) => handleChange(e, setState)}
            name="tipo_entrada"
            options={[
              { label: "Produto", value: 0 },
              { label: "Grupo", value: 1 },
              { label: "Produtos (Kit)", value: 3 },
            ]}
          />
        </NuvelGrid>
        {[0, 1].includes(state.tipo_entrada) && (
          <NuvelGrid xs={12}>
            {state.tipo_entrada === 0 && (
              <NuvelAutocomplete
                label="Produto"
                name="entrada"
                value={state.entrada}
                onChange={(e) => handleChange(e, setState)}
                reference="produto_codigo"
              />
            )}
            {/* {state.tipo_entrada === 1 && (
              <DepartamentoSelect
                label="Departamento / Grupo / Subgrupo"
                name="departamento"
                value={state.departamento}
                onChange={(e: any) => handleChange(e, setState)}
              />
            )} */}
          </NuvelGrid>
        )}
        {state.tipo_entrada === 3 && (
          <>
            <NuvelGrid xs={12} md={8} lg={9}>
              <NuvelAutocomplete
                label="Produto"
                name="temp_produto"
                value={localState.temp_produto?.id}
                onChange={(_e, p) =>
                  setLocalState((o: any) => ({ ...o, temp_produto: p }))
                }
                reference="produto"
              />
            </NuvelGrid>
            <NuvelGrid xs={12} md={4} lg={3}>
              <div
                style={{
                  height: "100%",
                  paddingTop: 5,
                  paddingBottom: 20,
                }}
              >
                <Button
                  onClick={() => addInsumo(localState.temp_produto)}
                  color="primary"
                  variant="contained"
                >
                  {localState.temp_produto
                    ? "+ INCLUIR ITEM."
                    : "+ INCLUIR KIT"}
                </Button>
              </div>
            </NuvelGrid>
            <NuvelGrid xs={12}>
              <LegacySimpleTable
                style={{ width: "100%" }}
                columns={[
                  { accessor: "nome", label: "Nome", width: "80%" },
                  {
                    accessor: "quantidade",
                    type: "text",
                    label: "Quantidade",
                    align: "left",
                    width: "20%",
                  },
                  {
                    accessor: (ins: any, index: any) => (
                      <IconButton
                        onClick={() => removeInsumo(ins, index)}
                        size="small"
                      >
                        <Delete fontSize="inherit" />
                      </IconButton>
                    ),
                    label: "",
                    align: "right",
                  },
                ]}
                rows={localState.produtos}
                setRows={(r: any) =>
                  setLocalState((o: any) => ({ ...o, produtos: r }))
                }
                onRowClick={() => undefined}
              />
            </NuvelGrid>
          </>
        )}
        <NuvelGrid xs={8}>
          <NuvelSelect
            label="Tipo de Desconto"
            value={state.tipo_desconto}
            onChange={(e) => handleChange(e, setState)}
            name="tipo_desconto"
            options={[
              { value: 0, label: "Desconto em R$ do Total" },
              { value: 1, label: "Desconto em % do Total" },
              { value: 2, label: "Desconto em quantidade" },
              { value: 3, label: "Desconto em Exato do Total" },
              { value: 4, label: "Mudar valor total para" },
            ]}
          />
        </NuvelGrid>
        <NuvelGrid xs={4}>
          <NuvelTextField
            label="Desconto"
            onChange={(e) => handleChange(e, setState)}
            name="desconto"
            type={state.tipo_desconto === 2 ? "number" : undefined}
            perc={state.tipo_desconto === 1}
            money={[0, 3, 4].includes(state.tipo_desconto)}
            value={state.desconto}
          />
        </NuvelGrid>
        <NuvelGrid xs={6}>
          <NuvelDatePicker
            label="Data Início"
            onChange={(e) => handleChange(e, setState)}
            name="data_inicio"
            value={state.data_inicio}
            disabled={state.data_indeterminada}
          />
        </NuvelGrid>
        <NuvelGrid xs={6}>
          <NuvelDatePicker
            label="Data Fim"
            onChange={(e) => handleChange(e, setState)}
            name="data_fim"
            value={state.data_fim}
            disabled={state.data_indeterminada}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={4}>
          <NuvelCheckbox
            checked={state.data_indeterminada}
            name="data_indeterminada"
            onChange={(e: any) => handleChange(e, setState)}
            label="Data Indeterminada"
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelDefaultForm>
  );
}

export default Form_Promocoes;
