import {
  AppBar,
  Box,
  Button,
  Tabs as MuiTabs,
  Tab,
  Typography,
} from "@mui/material";
import { InternalModel, MainInterface } from "data/main";
import { useAppContext } from "hooks";
import useCurrentRoute from "hooks/useCurrentRoute";
import React, { useEffect, useMemo } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BaseForm from "../shared/form";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1.5, pl: 0, pr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface Tab<T> {
  title: string;
  name: string;
  children: React.ReactNode;
  hidden?: boolean | ((state: T) => boolean);
}

interface NuvelDefaultFormProps<
  T extends InternalModel,
  L extends InternalModel
> {
  model: MainInterface<T, L>;
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
  onLoad?: (data: T) => void;
  tabs: Tab<T>[];
}

const NuvelDefaultForm = <T extends InternalModel, L extends InternalModel>({
  model,
  setState,
  state,
  onLoad,
  tabs,
}: NuvelDefaultFormProps<T, L>) => {
  const {
    dialog: { showMessage },
    showSnack,
  } = useAppContext();
  const navigate = useNavigate();
  const isTabHidden = React.useCallback(
    (tab: Tab<T>) => {
      return typeof tab.hidden === "function" ? tab.hidden(state) : tab.hidden;
    },
    [state]
  );

  const availableTabs = useMemo(() => {
    return tabs.filter((tab) => !isTabHidden(tab));
  }, [tabs, isTabHidden]);

  const [loading, setLoading] = React.useState(false);
  const [urlParams, setUrlParams] = useSearchParams();
  const [tabIndex, setTabIndex] = React.useState(
    urlParams.get("tab")
      ? availableTabs.findIndex((tab) => tab.name === urlParams.get("tab"))
      : 0
  );
  const formRef = React.useRef<HTMLFormElement>(null);

  const { id } = useParams<{ id: string }>();
  const route = useCurrentRoute(1);
  const { state: locationState } = useLocation();

  const formExited = () => {
    if (locationState?.exitRoute) {
      navigate(locationState.exitRoute);
    } else {
      const newUrl = window.location.pathname.split("/").slice(0, -1).join("/");
      navigate(newUrl);
    }
  };

  useEffect(() => {
    if (urlParams.get("tab")) {
      setTabIndex(
        availableTabs.findIndex((tab) => tab.name === urlParams.get("tab"))
      );
    } else {
      setUrlParams(new URLSearchParams({ tab: availableTabs[0].name }), {
        replace: true,
        state: {
          exitRoute: locationState?.exitRoute,
        },
      });
    }
  }, [availableTabs, locationState?.exitRoute, setUrlParams, urlParams]);

  useEffect(() => {
    if (id && id !== "novo") {
      setLoading(true);
      model
        .get(Number(id))
        .then((data: T) => {
          setState(data);
          if (onLoad) {
            onLoad(data);
          }
          setLoading(false);
        })
        .catch(() => {
          showMessage(
            "Verifique sua conexão de internet, caso persista, favor consultar um administrador.",
            "Houve um erro ao processar solicitação."
          );
          formExited();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formRef.current && !formRef.current.checkValidity()) {
      Array.from(formRef.current.elements).forEach((element) => {
        if (
          element instanceof HTMLInputElement ||
          element instanceof HTMLSelectElement ||
          element instanceof HTMLTextAreaElement
        ) {
          if (!element.validity.valid) {
            showSnack(
              `${element.validationMessage}: ${element.name}`,
              4,
              "error"
            );
          }
        }
      });

      // Exibe as mensagens de erro padrão nos campos
      formRef.current.reportValidity();
      return;
    }

    setLoading(true);
    model
      .save(id !== "novo" ? { ...state, id: Number(id) } : state)
      .then((response) => {
        if (response) {
          showSnack("Salvo com sucesso!", 2, "success");
          formExited();
        } else {
          showSnack("Erro ao salvar!", 2, "error");
          setLoading(false);
        }
      });
  };

  const nextTab = useMemo((): Tab<T> => {
    let nextTabVar: Tab<T> = availableTabs[0];
    Object.keys(availableTabs).forEach((_, index) => {
      if (index > tabIndex && nextTabVar === availableTabs[0]) {
        nextTabVar = availableTabs[index];
      }
    });
    return nextTabVar;
  }, [availableTabs, tabIndex]);

  const onChangeTab = (val: string | number, isSubmit = false) => {
    const name = isSubmit ? nextTab.name : availableTabs[val as number].name;
    setUrlParams(new URLSearchParams({ tab: name }), {
      state: {
        exitRoute: locationState?.exitRoute,
      },
    });
  };

  const currentTab = availableTabs[tabIndex];

  const isFinalTab = currentTab === availableTabs[availableTabs.length - 1];

  return (
    <BaseForm
      handleSubmit={handleSubmit}
      loading={loading}
      route={route}
      navigate={navigate}
      formRef={formRef}
      extraButtons={
        !isFinalTab && (
          <Button
            variant="contained"
            onClick={() => onChangeTab(nextTab.name, true)}
          >
            Próximo
          </Button>
        )
      }
    >
      <AppBar position="static">
        <MuiTabs
          value={tabIndex}
          onChange={(_, val) => onChangeTab(val)}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {availableTabs.map((tabContent, index) => (
            <Tab label={tabContent.title} {...a11yProps(index)} />
          ))}
        </MuiTabs>
      </AppBar>
      {availableTabs.map((tabContent, index) => (
        <TabPanel value={tabIndex} index={index}>
          {tabContent.children}
        </TabPanel>
      ))}
    </BaseForm>
  );
};

export default NuvelDefaultForm;
