import { NuvelTabsForm } from "components/nuvel/forms";
import { ParceiroDeNegocioSerializer } from "data/interfaces/core/ParceiroDeNegocioSerializer";
import { ParceiroModel } from "data/models";
import React from "react";

import dayjs from "dayjs";
import ClienteTab from "./tabs/cliente";
import FuncionarioTab from "./tabs/funcionario";
import PrincipalTab from "./tabs/principal";

const RetParceiroForm: React.FC = () => {
  const [state, setState] = React.useState<ParceiroDeNegocioSerializer>({
    cpf_cnpj: "",
    rg_ie: "",
    im: "",
    razao_social_nome: "",
    nome_fantasia: "",
    data_nascimento: dayjs(),
    lojista: false,
    cliente: false,
    func: false,
    fornecedor: false,
    transpor: false,
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    cod_ibge: "",
    uf: "",
    bairro: "",
    cidade: "",
    pais: "",
    contatos: [],
    contas: [],
    cliente_ativo: true,
    cliente_filiacao: "",
    cliente_sexo: 1, // 1, 2, 3, 4
    cliente_estadocivil: 1, // 1, 2, 3, 4, 5
    cliente_conjugue: "",
    funcionario_ativo: true,
    funcionario_supervisor: false,
    funcionario_salario: 0,
    funcionario_cargo: null,
    funcionario_dataadmissao: dayjs(),
    funcionario_loginpdv: "",
    funcionario_senhapdv: "",
    funcionario_visualiza_fechamento: false,
    fornecedor_ativo: true,
    transportador_ativo: true,
  });

  const Tabs = [
    {
      title: "Principal",
      name: "principal",
      children: <PrincipalTab state={state} setState={setState} />,
    },
    {
      title: "Cliente",
      name: "cliente",
      children: <ClienteTab state={state} setState={setState} />,
      hidden: !state.cliente,
    },
    {
      title: "Funcionario",
      name: "funcionario",
      children: <FuncionarioTab state={state} setState={setState} />,
      hidden: !state.func,
    },
  ];

  return (
    <NuvelTabsForm
      tabs={Tabs}
      model={ParceiroModel}
      state={state}
      setState={setState}
    />
  );
};

export default RetParceiroForm;
