import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1.5, pl: 0, pr: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default TabPanel;
