import dayjs from "dayjs";
import {
  NotaDeSaidaSerializer,
  NotaDeSaidaSerializerFinalidadeEnum,
  NotaDeSaidaSerializerModeloEnum,
  NotaDeSaidaSerializerPresencaEnum,
  NotaDeSaidaSerializerTransportador_freteEnum,
  NotaDeSaidaSerializerTipoEnum,
  NotaDeSaidaSerializerStatusEnum,
} from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import { NotaDeSaidaItensSerializer } from "data/interfaces/financeiro/NotaDeSaidaItensSerializer";

export type FiltrosState = {
  cliente: number | null;
  numero: string | null;
  data_inicial: dayjs.Dayjs;
  data_final: dayjs.Dayjs;
};

export const filtrosState: FiltrosState = {
  cliente: null,
  numero: null,
  data_inicial: dayjs().add(-6, "months"),
  data_final: dayjs().add(6, "months"),
};

export const incluirState: NotaDeSaidaSerializer = {
  itens: [],
  cliente: "",
  emitente: "",
  numero: "",
  serie: "",
  modelo: NotaDeSaidaSerializerModeloEnum["NF-e"],
  finalidade: NotaDeSaidaSerializerFinalidadeEnum["NF-e_Normal"],
  natureza_operacao: "Venda",
  natureza_operacao_desc: "",
  presenca:
    NotaDeSaidaSerializerPresencaEnum["Operação_não_presencial,_outros."],
  informacoes_adicionais_interesse_fisco: "",
  transportador: "",
  transportador_frete: NotaDeSaidaSerializerTransportador_freteEnum.Emitente,
  transportador_placa: "",
  transportador_uf: "",
  transportador_quantidade: 0,
  transportador_especie: "",
  transportador_marca: "",
  transportador_numero: "",
  transportador_peso_bruto: 0,
  transportador_peso_liquido: 0,
  notas_fiscais_referenciadas: "",
  tipo: NotaDeSaidaSerializerTipoEnum.Saída,
  total: 0,
  subtotal: 0,
  total_desconto: 0,
  total_acrescimo: 0,
  status: NotaDeSaidaSerializerStatusEnum.Criada,
  data_emissao: dayjs(),
  data_saida: dayjs(),
};

export const incluirItemState: NotaDeSaidaItensSerializer = {
  quantidade: 0,
  desconto: 0,
  acrescimo: 0,
  frete: 0,
  produto: "",
  subtotal: 0.0,
  calc_desc: 0.0,
  calc_acres: 0.0,
  preco_venda: 0.0,
  total: 0.0,
  porcentagem_devolvida: 0.0,
  nome: "",
  codbarra: "",
  imposto: "",
  imposto_obj: undefined,
};
