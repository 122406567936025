import { RouteInterface } from "utils/router";
import CatalogoLayout from ".";

import { LoaderFunctionArgs } from "react-router-dom";
import { Store, Product, Department, PaymentForm } from "./context";
import { CatalogoModel } from "data/models";

interface APIResponse {
  id: number;
  nome: string;
  descricao?: string;
  lojas: number[];
  permite_retirada_local: boolean;
  taxa_entrega: string;
  is_catalogo_padrao: boolean;
  formas_pagamento: Array<{
    id: number;
    forma: number | null;
    forma_nome: string;
  }>;
  grupos: Array<{
    id: number;
    nome: string;
    descricao?: string;
    departamento?: number;
    ordem: number;
    produtos: Array<{
      id: number;
      cod_barra: {
        id: number;
        produto_nome: string;
      };
      preco: {
        id: number;
        preco_nome: string;
        preco_descricao: string;
        preco_custo: number;
        preco_venda: number;
      };
    }>;
  }>;
  licenca?: number;
}

const transformApiDataToCatalogFormat = (apiData: APIResponse) => {
  const departments: Department[] = apiData.grupos.map((grupo) => ({
    id: grupo.id,
    name: grupo.nome,
  }));

  const store: Store = {
    id: apiData.id.toString(),
    name: apiData.nome,
    address: "", // TODO: pegar do banco de dados
    phone: "", // TODO: pegar do banco de dados
    allow_delivery: true,
    allow_pickup: apiData.permite_retirada_local,
    delivery_price: Number(apiData.taxa_entrega) || 0,
    businessHours: {
      monday: { open: "09:00", close: "18:00", isOpen: true },
      tuesday: { open: "09:00", close: "18:00", isOpen: true },
      wednesday: { open: "09:00", close: "18:00", isOpen: true },
      thursday: { open: "09:00", close: "18:00", isOpen: true },
      friday: { open: "09:00", close: "18:00", isOpen: true },
      saturday: { open: "09:00", close: "18:00", isOpen: true },
      sunday: { open: "09:00", close: "18:00", isOpen: true },
    },
  };

  const products: Product[] = apiData.grupos.flatMap((grupo) =>
    grupo.produtos.map((produto) => ({
      id: produto.id.toString(),
      name: produto.cod_barra.produto_nome,
      price: produto.preco.preco_venda,
      department: grupo.id,
      stock: 0,
      image: "",
      description: "",
      quantity: 0,
    }))
  );

  const paymentForms: PaymentForm[] = apiData.formas_pagamento.map((forma) => ({
    id: forma.id,
    name: forma.forma_nome,
  }));

  return {
    store,
    departments,
    products,
    paymentForms,
  };
};

const retrieve_data = async (catalogo_id: string) => {
  try {
    const response = await CatalogoModel.action(
      "get",
      `retrieve`,
      {},
      undefined,
      {
        id: catalogo_id,
      }
    );

    if (response.data) {
      const transformedData = transformApiDataToCatalogFormat(response.data);

      localStorage.setItem("catalogo_id", catalogo_id);
      localStorage.setItem("catalogo_data", JSON.stringify(transformedData));

      return true;
    }

    return false;
  } catch (error) {
    console.error("Erro ao carregar dados do catálogo:", error);
    return false;
  }
};

const loader = async ({ params }: LoaderFunctionArgs) => {
  const data = await retrieve_data(params.catalogo_id as string);
  return data;
};

const CatalogoRoutes: RouteInterface[] = [
  {
    path: "/:catalogo_id",
    element: <CatalogoLayout />,
    loader: loader,
  },
];

export default CatalogoRoutes;
