import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import { CatalogoModel } from "data/models/catalogo";
import { useCatalog, useCatalogActions } from "layouts/catalogo/context";
import { CartState } from "layouts/catalogo/views/cart";
import React from "react";

interface PaymentFormProps {
  cartState: CartState;
  setCartState: (state: CartState) => void;
  onBack: () => void;
  isLastStep: boolean;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  cartState,
  setCartState,
  onBack,
}) => {
  const { state } = useCatalog();
  const { clearCart, setActiveWindow, getPaymentFormById } =
    useCatalogActions();

  const handleFinishOrder = () => {
    CatalogoModel.action("post", "pedido", cartState).then(() => {
      clearCart();
      setActiveWindow("catalog");
    });
  };

  return (
    <Box>
      <Paper sx={{ mt: 4, p: 2 }}>
        <Typography variant="h6" mb={2}>
          Forma de Pagamento
        </Typography>

        <RadioGroup
          value={cartState.payment?.id || ""}
          onChange={(e) =>
            setCartState({
              ...cartState,
              payment: { id: Number(e.target.value) },
            })
          }
        >
          {state.paymentForms.map((form) => (
            <FormControlLabel
              value={form.id}
              control={<Radio />}
              label={form.name}
            />
          ))}
        </RadioGroup>

        {getPaymentFormById(cartState.payment?.id || 0)?.name ===
          "Dinheiro" && (
          <TextField
            label="Troco para quanto?"
            type="number"
            fullWidth
            margin="normal"
            value={cartState.payment?.change || ""}
            onChange={(e) =>
              setCartState({
                ...cartState,
                payment: {
                  ...cartState.payment,
                  change: Number(e.target.value),
                },
              })
            }
          />
        )}
      </Paper>
      <Box display="flex" justifyContent="space-between" gap={2} mt={4}>
        <Button variant="outlined" onClick={onBack}>
          Voltar
        </Button>
        <Button
          variant="contained"
          onClick={handleFinishOrder}
          disabled={!cartState.payment?.id}
        >
          Finalizar Pedido
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentForm;
