import { NotaDeSaidaSerializer } from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "notadesaida";

class NotaDeSaidaModel extends MainModule<
  NotaDeSaidaSerializer,
  NotaDeSaidaSerializer
> {}

export default new NotaDeSaidaModel(model_url);
