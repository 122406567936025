import { getSingleProd, getTotaisProd } from "../utils";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { NotaDeSaidaSerializer } from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import { Create, Delete } from "@mui/icons-material";
import { ProdutoListSerializer } from "data/interfaces/estoque/ProdutoListSerializer";

interface ListItemsProps {
  body: NotaDeSaidaSerializer;
  deleteItem: (index: number) => void;
  editItem: (index: number) => void;
  listProds: { rows: ProdutoListSerializer[] };
}

function ListItems(props: ListItemsProps) {
  const { body, deleteItem, editItem, listProds } = props;
  const theme = useTheme();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left">Código</TableCell>
          <TableCell align="left">Produto</TableCell>
          <TableCell align="left">Qtde</TableCell>
          <TableCell align="left">Unitário</TableCell>
          <TableCell align="left">Total</TableCell>
          <TableCell style={{ width: "1%" }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {body.itens.map((prod, index) => (
          <TableRow key={index}>
            <TableCell>
              {getSingleProd(Number(prod.produto), listProds)?.codigo ||
                "Carregando"}
            </TableCell>
            <TableCell>
              {getSingleProd(Number(prod.produto), listProds)?.nome ||
                "Carregando"}
            </TableCell>
            <TableCell>{prod.quantidade}</TableCell>
            <TableCell>
              {getSingleProd(Number(prod.produto), listProds)?.preco_venda
                ? getSingleProd(
                    Number(prod.produto),
                    listProds
                  )?.preco_venda.toBRL()
                : 0}
            </TableCell>
            <TableCell>
              {getTotaisProd(prod, listProds).total.toBRL() || "Carregando"}
            </TableCell>
            <TableCell>
              <Box style={{ paddingTop: 0 }} display="flex">
                <IconButton
                  onClick={() => editItem(index)}
                  aria-label="edit"
                  size="small"
                >
                  <Create
                    style={{ color: theme.palette.primary.main }}
                    fontSize="inherit"
                  />
                </IconButton>
                <IconButton
                  onClick={() => deleteItem(index)}
                  aria-label="delete"
                  size="small"
                >
                  <Delete
                    style={{ color: theme.palette.primary.main }}
                    fontSize="inherit"
                  />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default ListItems;
