/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Typography, useTheme } from "@mui/material";
import NuvelAccordion from "components/nuvel/Accordion";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import NuvelCheckbox from "components/nuvel/Checkbox";
import { NuvelDefaultForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import PlanoDeContaRecebimentoSelect from "components/nuvel/plano_de_contas/recebimento";
import NuvelSelect from "components/nuvel/Select";
import NuvelTextField from "components/nuvel/TextField";
import AuthController from "data/controller/auth";
import { PDVSerializer } from "data/interfaces/pdv/PDVSerializer";
import { PDVModel } from "data/models";
import { useMemo, useState } from "react";
import { handleChange } from "utils/functions";
import { showMessage } from "utils/functions/dialog";
import { getDataFromBalancaPattern } from "utils/functions/getDataFromBalancaPattern";
import IfoodAuthModel from "data/models/ifood";
// eslint-disable-next-line react-refresh/only-export-components
function ColoredBalancaExample({
  example,
  result,
}: {
  example: string;
  result: string;
}) {
  const theme = useTheme();

  function mapColor(char: string) {
    switch (char) {
      case "C":
        return theme.palette.success.main;
      case "R":
        return theme.palette.warning.main;
      case "P":
        return theme.palette.error.main;
      case "D":
        return theme.palette.info.main;
      default:
        return "inherit";
    }
  }

  const data = getDataFromBalancaPattern(example, result, true);

  if (!data.codigo) {
    return (
      <Typography>Não foi possível encontrar o código do produto.</Typography>
    );
  }

  return (
    <Typography>
      {example.split("").map((char, index) => (
        <span key={index} style={{ color: mapColor(char) }}>
          {char}
        </span>
      ))}
      <span style={{ marginLeft: 5, marginRight: 5 }}>→</span>
      {example.split("").map((char, index) => (
        <span key={index} style={{ color: mapColor(char) }}>
          {result[index]}
        </span>
      ))}
      <span style={{ marginLeft: 5, marginRight: 5 }}>→</span>
      <span style={{ marginLeft: 5, marginRight: 5 }}>Cód. :</span>
      <span style={{ color: theme.palette.success.main }}>{data.codigo}</span>
      {data.preco && (
        <>
          <span style={{ marginLeft: 5, marginRight: 5 }}>Preço:</span>
          <span style={{ color: theme.palette.warning.main }}>
            {data.preco}
          </span>
        </>
      )}
      {data.peso && (
        <>
          <span style={{ marginLeft: 5, marginRight: 5 }}>Peso: </span>
          <span style={{ color: theme.palette.error.main }}>{data.peso}</span>
        </>
      )}
    </Typography>
  );
}

function Form_PontoDeVenda() {
  const theme = useTheme();
  const hasIfood = localStorage.getItem("ifood") === "true";
  const { data: user_is_support } = AuthController.useUserIsSupport();

  interface State extends PDVSerializer {
    ident: string;
    balanca_test: string;
  }

  const [state, setState] = useState<State>({
    descricao: "",
    loja: null,
    deposito: null,
    plano_de_conta: null,
    homologacao: true,
    sefaz_token: "",
    sefaz_csc: "",
    numero: "",
    sequencia: "",
    ident: "",
    emite_nfce: false,
    maquina: {
      guid: "",
      status: false,
    },
    printDirectly: false,
    balanca_pattern: "",
    balanca_test: "",
    theme: 1,
    pageSize: 180.0,
    pageMarginLeft: 0,
    pageMarginRight: 0,
    pageMarginTop: 0,
    pageMarginBottom: 0,
    zoom: 0.4,
    self_checkout: false,
    confirmar_impressao: false,
    habilita_touch: false,
    exigir_senha_cancelamento_item: false,
    exigir_senha_cancelamento_cupom: false,
    exigir_senha_fechamento_caixa: false,
    exigir_senha_desconto: false,
    exigir_senha_acrescimo: false,
    exigir_senha_sangria: false,
    exigir_senha_suprimento: false,
    qtd_vias_cupom: 1,
    qtd_vias_fechamento: 1,
    porta_impressora: 1,
    tipo_porta_impressora: 0,
    config_ativa_teclado: false,
    balanca_modelo: 0,
    balanca_porta: "COM1",
    balanca_baud_rate: "9600",
    balanca_data_bits: "8",
    balanca_stop_bits: "1",
    balanca_parity: "none",
    fechamento_cego: false,
    bloqueia_estoque_negativo: false,
    solicita_vendedor: false,
    versao_target: "",
  });

  const create_check_box = (label: string, name: keyof PDVSerializer) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      return (
        <NuvelCheckbox
          label={label}
          onChange={(e) => handleChange(e, setState)}
          name={name}
          checked={state[name] as boolean}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state[name]]);
  };

  const [ifoodConf, setIfoodConf] = useState<any>({});
  const [ifoodExtras, setIfoodExtras] = useState<any>({
    authCode: null,
    autenticado: false,
  });

  const solicitarLinkIfood = () => {
    IfoodAuthModel.action("post", "request-code-authentication", {
      token: localStorage.getItem("token"),
      pdv: state.id,
    }).then((res) => {
      window.open(res.data.verificationUrlComplete, "_blank");
      setIfoodConf(res.data);
    });
  };

  const solicitarAutenticacaoIfood = () => {
    // HCSM-DFDD
    IfoodAuthModel.action("post", "request-token", {
      ifood_return: ifoodExtras.authCode,
      token: localStorage.getItem("token"),
    }).then(() => {
      setIfoodExtras((_v: any) => ({
        ..._v,
        autenticado: true,
      }));
    });
  };

  return (
    <NuvelDefaultForm model={PDVModel} setState={setState} state={state}>
      <NuvelGrid container spacing={1}>
        <NuvelGrid xs={12} md={7}>
          <NuvelTextField
            label="Descrição"
            onChange={(e) => handleChange(e, setState)}
            name="descricao"
            value={state.descricao}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={5}>
          <NuvelAutocomplete
            label="Loja"
            name="loja"
            value={Number(state.loja)}
            onChange={(e) => handleChange(e, setState)}
            reference="loja"
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={4}>
          <NuvelAutocomplete
            label="Depósito"
            name="deposito"
            value={Number(state.deposito)}
            onChange={(e) => handleChange(e, setState)}
            reference="deposito"
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={4}>
          <PlanoDeContaRecebimentoSelect
            name="plano_de_conta"
            value={state.plano_de_conta}
            onChange={(e: any) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={4}>
          <NuvelSelect
            label="Tema"
            name="theme"
            options={[
              {
                label: "Padrão",
                value: 1,
              },
              {
                label: "Cosechas",
                value: 2,
              },
            ]}
            value={state.theme}
            onChange={(e) => handleChange(e, setState)}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Série / Número do Caixa"
            onChange={(e) => handleChange(e, setState)}
            name="numero"
            value={state.numero}
          />
        </NuvelGrid>
        <NuvelGrid xs={12} md={3}>
          <NuvelTextField
            label="Número / Sequencia"
            onChange={(e) => handleChange(e, setState)}
            name="sequencia"
            value={state.sequencia}
          />
        </NuvelGrid>
        {user_is_support && (
          <NuvelGrid xs={12} md={2}>
            <NuvelTextField
              label="Versão Target"
              onChange={(e) => handleChange(e, setState)}
              name="versao_target"
              value={state.versao_target}
            />
          </NuvelGrid>
        )}
        <NuvelGrid xs={12} md={2}>
          {create_check_box("Modo NFC-e", "emite_nfce")}
        </NuvelGrid>
        <NuvelGrid xs={12} md={2}>
          {create_check_box("Homologação", "homologacao")}
        </NuvelGrid>
        {hasIfood ? (
          <NuvelGrid xs={12} md={12}>
            <NuvelAccordion title="Configuração Ifood">
              <NuvelGrid container spacing={1}>
                {ifoodExtras.autenticado ? (
                  <Button variant="contained" fullWidth>
                    Autenticado com sucesso!
                  </Button>
                ) : (
                  <NuvelGrid xs={12}>
                    {ifoodConf?.verificationUrlComplete ? (
                      <Box display="flex">
                        <NuvelTextField
                          label="Código do Ifood"
                          onChange={(e) => handleChange(e, setIfoodExtras)}
                          name="authCode"
                          value={ifoodExtras.authCode}
                        />
                        {ifoodExtras.authCode ? (
                          <Button
                            onClick={() => solicitarAutenticacaoIfood()}
                            fullWidth
                          >
                            Autenticar
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              window.open(
                                ifoodConf.verificationUrlComplete,
                                "_blank"
                              )
                            }
                            fullWidth
                          >
                            Reabrir Página
                          </Button>
                        )}
                      </Box>
                    ) : (
                      <Button onClick={() => solicitarLinkIfood()} fullWidth>
                        Solicitar Link de Autenticação
                      </Button>
                    )}
                  </NuvelGrid>
                )}
              </NuvelGrid>
            </NuvelAccordion>
          </NuvelGrid>
        ) : null}
        <NuvelGrid xs={12} md={12}>
          <NuvelAccordion title="Fiscal">
            <NuvelGrid container spacing={1}>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="TOKEN"
                  onChange={(e) => handleChange(e, setState)}
                  name="sefaz_token"
                  value={state.sefaz_token}
                  required={state.emite_nfce}
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={9}>
                <NuvelTextField
                  label="CSC"
                  onChange={(e) => handleChange(e, setState)}
                  name="sefaz_csc"
                  value={state.sefaz_csc}
                  required={state.emite_nfce}
                />
              </NuvelGrid>
            </NuvelGrid>
          </NuvelAccordion>
        </NuvelGrid>
        <NuvelGrid xs={12} md={12}>
          <NuvelAccordion title="Máquina">
            <NuvelGrid container spacing={1}>
              <NuvelGrid xs={12} md={10}>
                <NuvelTextField
                  label="GUID da máquina"
                  value={state.maquina?.guid}
                  onChange={(e) =>
                    setState((v) => ({
                      ...v,
                      maquina: {
                        ...v.maquina,
                        guid: e.target.value,
                      },
                    }))
                  }
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                <Button
                  style={{ height: "100%" }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    PDVModel.action(
                      "post",
                      "aprovar_maquina",
                      {
                        guid: state.maquina?.guid,
                      },
                      state.id
                    )
                      .then(() => {
                        showMessage("Aprovado!", "Retorno");
                      })
                      .catch(() =>
                        showMessage(
                          "Entre em contato com o suporte!",
                          "Retorno"
                        )
                      );
                  }}
                >
                  Aprovar
                </Button>
              </NuvelGrid>
            </NuvelGrid>
          </NuvelAccordion>
        </NuvelGrid>
        <NuvelGrid xs={12} md={12}>
          <NuvelAccordion title="Exigir senha para...">
            <NuvelGrid container spacing={1}>
              <NuvelGrid xs={12} md={3}>
                {create_check_box(
                  "Cancelamento de Item",
                  "exigir_senha_cancelamento_item"
                )}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box(
                  "Cancelamento de Cupom",
                  "exigir_senha_cancelamento_cupom"
                )}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box(
                  "Fechamento de Caixa",
                  "exigir_senha_fechamento_caixa"
                )}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Desconto", "exigir_senha_desconto")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Acréscimo", "exigir_senha_acrescimo")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Sangria", "exigir_senha_sangria")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Suprimento", "exigir_senha_suprimento")}
              </NuvelGrid>
            </NuvelGrid>
          </NuvelAccordion>
        </NuvelGrid>

        <NuvelGrid xs={12} md={12}>
          <NuvelAccordion title="Configuraçôes adicionais">
            <NuvelGrid container spacing={1}>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Habilita Touch", "habilita_touch")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Self Checkout", "self_checkout")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Teclado Virtual", "config_ativa_teclado")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                {create_check_box("Fechamento Cego", "fechamento_cego")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                {create_check_box("Solicita Vendedor", "solicita_vendedor")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                {create_check_box(
                  "Bloqueia Estoque Negativo",
                  "bloqueia_estoque_negativo"
                )}
              </NuvelGrid>
            </NuvelGrid>
          </NuvelAccordion>
        </NuvelGrid>
        <NuvelGrid xs={12} md={12}>
          <NuvelAccordion title="Configuração de impressão">
            <NuvelGrid container spacing={1}>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="ZOOM"
                  onChange={(e) => handleChange(e, setState)}
                  name="zoom"
                  value={state.zoom}
                  decimal
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="Tamanho da página"
                  onChange={(e) => handleChange(e, setState)}
                  name="pageSize"
                  value={state.pageSize}
                  decimal
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="Margem esquerda"
                  onChange={(e) => handleChange(e, setState)}
                  name="pageMarginLeft"
                  value={state.pageMarginLeft}
                  decimal
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="Margem direita"
                  onChange={(e) => handleChange(e, setState)}
                  name="pageMarginRight"
                  value={state.pageMarginRight}
                  decimal
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="Margem superior"
                  onChange={(e) => handleChange(e, setState)}
                  name="pageMarginTop"
                  value={state.pageMarginTop}
                  decimal
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="Margem inferior"
                  onChange={(e) => handleChange(e, setState)}
                  name="pageMarginBottom"
                  value={state.pageMarginBottom}
                  decimal
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Confirmar impressão", "confirmar_impressao")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                {create_check_box("Uso mobile", "printDirectly")}
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="Qtd. Vias Cupom"
                  onChange={(e) => handleChange(e, setState)}
                  name="qtd_vias_cupom"
                  value={state.qtd_vias_cupom}
                  type="number"
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={3}>
                <NuvelTextField
                  label="Qtd. Vias Fechamento"
                  onChange={(e) => handleChange(e, setState)}
                  name="qtd_vias_fechamento"
                  value={state.qtd_vias_fechamento}
                  type="number"
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={6}>
                <NuvelSelect
                  label="Tipo de Porta da impressora"
                  name="tipo_porta_impressora"
                  options={[
                    {
                      label: "USB",
                      value: 0,
                    },
                    {
                      label: "Serial",
                      value: 1,
                    },
                    {
                      label: "Bluetooth",
                      value: 2,
                    },
                    {
                      label: "Ethernet",
                      value: 3,
                    },
                    {
                      label: "Nome",
                      value: 4,
                    },
                  ]}
                  value={state.tipo_porta_impressora}
                  onChange={(e) => handleChange(e, setState)}
                />
              </NuvelGrid>
              <NuvelGrid xs={12}>
                <NuvelTextField
                  label="Porta / Nome / Caminho da impressora"
                  onChange={(e) => handleChange(e, setState)}
                  name="porta_impressora"
                  value={state.porta_impressora}
                />
              </NuvelGrid>
            </NuvelGrid>
          </NuvelAccordion>
        </NuvelGrid>
        <NuvelGrid xs={12} md={12}>
          <NuvelAccordion title="Balança Serial">
            <NuvelGrid container spacing={1}>
              <NuvelGrid xs={12} md={4}>
                <NuvelTextField
                  label="Porta"
                  onChange={(e) => handleChange(e, setState)}
                  name="balanca_porta"
                  value={state.balanca_porta}
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                <NuvelTextField
                  label="Baud Rate"
                  type="number"
                  onChange={(e) => handleChange(e, setState)}
                  name="balanca_baud_rate"
                  value={state.balanca_baud_rate}
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                <NuvelTextField
                  label="Data Bits"
                  type="number"
                  onChange={(e) => handleChange(e, setState)}
                  name="balanca_data_bits"
                  value={state.balanca_data_bits}
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                <NuvelTextField
                  label="Stop Bits"
                  type="number"
                  onChange={(e) => handleChange(e, setState)}
                  name="balanca_stop_bits"
                  value={state.balanca_stop_bits}
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={2}>
                <NuvelSelect
                  label="Paridade"
                  name="balanca_parity"
                  options={[
                    {
                      label: "None",
                      value: "none",
                    },
                    {
                      label: "Even",
                      value: "even",
                    },
                    {
                      label: "Odd",
                      value: "odd",
                    },
                    {
                      label: "Mark",
                      value: "mark",
                    },
                    {
                      label: "Space",
                      value: "space",
                    },
                  ]}
                  value={state.balanca_parity}
                  onChange={(e) => handleChange(e, setState)}
                />
              </NuvelGrid>
              <NuvelGrid xs={12} md={6}>
                <NuvelSelect
                  label="Modelo da balança"
                  name="balanca_modelo"
                  options={[
                    {
                      label: "Balança Genérica por Kilo",
                      value: 0,
                    },
                    {
                      label: "Balança Genérica por Grama",
                      value: 1,
                    },
                  ]}
                  value={state.balanca_modelo}
                  onChange={(e) => handleChange(e, setState)}
                />
              </NuvelGrid>
            </NuvelGrid>
          </NuvelAccordion>
        </NuvelGrid>
        <NuvelGrid xs={12} md={12}>
          <NuvelAccordion title="Configuração de Balança de Etiqueta">
            <Box>
              <Typography>
                O valor deve ser <b>o mesmo impresso pela balança</b>. Os
                caracteres disponíveis são:
              </Typography>
              <Typography style={{ color: theme.palette.success.main }}>
                <b>C</b> - Código do Próduto
              </Typography>
              <Typography style={{ color: theme.palette.warning.main }}>
                <b>R</b> - Preço do Produto
              </Typography>
              <Typography style={{ color: theme.palette.error.main }}>
                <b>P</b> - Peso do Produto
              </Typography>
              <Typography style={{ color: theme.palette.info.main }}>
                <b>D</b> - Casas decimais do peso ou preço
              </Typography>
              <Typography>
                <b>X</b> - Ignorar dígito
              </Typography>
              <Typography style={{ marginTop: 10 }}>Exemplos:</Typography>
              <ColoredBalancaExample
                example="2CCCCCRRRDDX"
                result="200001000010"
              />
              <ColoredBalancaExample
                example="2CCCCCPPPDDX"
                result="200001000010"
              />
              <Box style={{ marginTop: 10 }}>
                <NuvelTextField
                  label="Padrão da balança"
                  onChange={(e) => handleChange(e, setState)}
                  name="balanca_pattern"
                  value={state.balanca_pattern}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <NuvelTextField
                  label="Teste da balança"
                  onChange={(e) => handleChange(e, setState)}
                  name="balanca_test"
                  value={state.balanca_test}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <Typography>Resultado:</Typography>
                <ColoredBalancaExample
                  example={state.balanca_pattern}
                  result={state.balanca_test}
                />
              </Box>
            </Box>
          </NuvelAccordion>
        </NuvelGrid>
      </NuvelGrid>
    </NuvelDefaultForm>
  );
}

export default Form_PontoDeVenda;
