import Grid from "components/nuvel/Grid";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import DatePicker from "components/nuvel/DatePicker";
import TextField from "components/nuvel/TextField";
import handleChange from "utils/functions/handleChange";
import { FiltrosState } from "./states";

interface FilterHeaderProps {
  state: FiltrosState;
  setState: (state: FiltrosState) => void;
}

export default function FilterHeader(props: FilterHeaderProps) {
  const { state, setState } = props;

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={4}>
        <NuvelAutocomplete
          label="Cliente"
          name="cliente"
          value={Number(state.cliente)}
          onChange={(e) => handleChange(e, setState)}
          reference="cliente"
        />
      </Grid>
      <Grid xs={12} md={2}>
        <TextField
          value={state.numero}
          name="numero"
          onChange={(e) => handleChange(e, setState)}
          label="Número"
          color="primary"
        />
      </Grid>
      <Grid xs={12} md={3}>
        <DatePicker
          label="Data Inicial"
          onChange={(e) => handleChange(e, setState)}
          name="data_inicial"
          value={state.data_inicial}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <DatePicker
          label="Data Final"
          onChange={(e) => handleChange(e, setState)}
          name="data_final"
          value={state.data_final}
        />
      </Grid>
    </Grid>
  );
}
