import {
  Box,
  Fab,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import {
  Add,
  Cancel,
  Create,
  Delete,
  EmailOutlined,
  Print,
  Send,
  Visibility,
} from "@mui/icons-material";
import { Preview } from "@mui/icons-material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import _pdf from "@react-pdf/renderer";
import { NuvelPaginationComponent } from "components/nuvel/Pagination";
import LoadingCover from "components/nuvel/LoadingCover";
import SimplePopper from "components/nuvel/SimplePopper";
import nota_de_saida from "data/models/fiscal/nota_de_saida";
import JsBarcode from "jsbarcode";
import React from "react";
import { downloadXml } from "utils/functions/downloadXml";
import { imprimirDoc } from "utils/functions/print";
import { useAppContext } from "hooks";
import { AxiosError } from "axios";
import {
  NotaDeSaidaSerializer,
  NotaDeSaidaSerializerStatusEnum,
} from "data/interfaces/financeiro/NotaDeSaidaSerializer";
import dayjs from "dayjs";
import parceiro from "data/models/core/parceiro";
import CupomNfe from "reports/retaguarda/nfe";
const { pdf } = _pdf;

interface SaidaTableProps {
  handleModal: (id: number) => void;
  listNota: {
    rows: NotaDeSaidaSerializer[];
    totalCount: number;
  };
  refreshNota: () => void;
  setStateNota: (state: NotaDeSaidaSerializer) => void;
  setPage: {
    page: number;
    setPage: (page: number) => void;
    totalCount: number;
    pageSize: number;
  };
}

export default function SaidaTable(props: SaidaTableProps) {
  const {
    showSnack,
    dialog: { showMessage, showInputDialog },
  } = useAppContext();
  const { handleModal, listNota, refreshNota, setStateNota } = props;
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();

  const list = listNota?.rows;

  const statusText = [
    "Criada",
    "Excluída",
    "Emitida",
    "Com erro",
    "Cancelada",
    "Inutilizada",
  ];

  const { data: listCliente } = parceiro.useModel({
    cliente: true,
    id__in: list?.map((x) => x.cliente).join(","),
  });

  const editNota = async (
    e: React.MouseEvent<HTMLElement>,
    nota_id: number | undefined
  ) => {
    if (!nota_id) return;
    setLoading(true);
    const nota = await nota_de_saida.get(nota_id);
    setStateNota(nota);
    handleModal(1);
    e.stopPropagation();
    setLoading(false);
  };

  const enviarEmail = async (
    e: React.MouseEvent<HTMLElement>,
    nota_id: number | undefined
  ) => {
    if (!nota_id) return;
    setLoading(true);
    e.stopPropagation();
    try {
      await nota_de_saida.action("post", "enviar", {}, nota_id);
    } catch (_err) {
      showSnack(`Erro ao enviar email, contate o suporte. ${_err}`, 2, "error");
    }
    setLoading(false);
  };

  const getNotaJson = (
    nota: {
      json: unknown;
      tipo: number;
      id: number;
      informacoes_adicionais_interesse_fisco: string;
    },
    isPreview: boolean
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let json = nota.json as any;
    if (
      json &&
      typeof json === "object" &&
      Object.keys(json).includes("nfeProc")
    ) {
      json = json.nfeProc;
    }

    if (json && typeof json === "object" && Object.keys(json).includes("NFe")) {
      json = json.NFe;
    }

    if (Object.keys(json).includes("infNFe")) {
      json = json.infNFe;
    }

    const canvas = document.createElement("canvas");

    if (isPreview) {
      json["@Id"] = "NOTA SEM VALOR FISCAL";
    } else {
      JsBarcode(canvas, json["@Id"].replace("NFe", ""), {
        fontSize: 70,
        width: 100,
      });
    }

    return { json, canvas };
  };

  const handleImpressao = async (
    e: React.MouseEvent<HTMLElement> | null,
    nota: {
      json: unknown;
      tipo: number;
      id: number;
      informacoes_adicionais_interesse_fisco: string;
    },
    isPreview: boolean
  ) => {
    setLoading(true);
    const { json, canvas } = getNotaJson(nota, isPreview);
    if (nota.json) {
      imprimirDoc(
        <CupomNfe
          _json={json}
          tipo={1}
          notaCompleta={nota.json}
          observacao={nota.informacoes_adicionais_interesse_fisco}
          barcode={canvas.toDataURL("image/png")}
        />
      );
    } else {
      showSnack("Não há xml para essa nota.", 2, "error");
    }
    if (e) {
      e.stopPropagation();
    }
    setLoading(false);
  };

  const salvarPdf = async (nota: {
    json: unknown;
    tipo: number;
    id: number;
    informacoes_adicionais_interesse_fisco: string;
  }) => {
    const { json, canvas } = getNotaJson(nota, false);

    const blob = await pdf(
      <CupomNfe
        _json={json}
        tipo={1}
        notaCompleta={nota.json}
        observacao={nota.informacoes_adicionais_interesse_fisco}
        barcode={canvas.toDataURL("image/png")}
      />
    ).toBlob();

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async function () {
      const base64data = reader.result;
      try {
        await nota_de_saida.action(
          "post",
          "salvar_pdf",
          {
            nota: base64data,
          },
          nota.id
        );
      } catch (err) {
        console.error(err);
      }
    };
  };

  const cancelar = async (
    e: React.MouseEvent<HTMLElement>,
    id: number | undefined
  ) => {
    if (!id) return;
    setLoading(true);
    e.stopPropagation();
    showInputDialog(
      "Informe o motivo do cancelamento",
      "Cancelar Nota",
      async (value) => {
        try {
          const response = await nota_de_saida.action(
            "post",
            "cancelar",
            { motivo: value },
            id
          );
          showSnack(response.data.detail, 2, "success");
        } catch (err: unknown) {
          if (err instanceof AxiosError) {
            showMessage(
              "Não foi possível cancelar a nota: \n" +
                (err.response?.data?.detail ||
                  err.response?.data?.message ||
                  err.message),
              "Erro ao cancelar nota"
            );
          } else {
            showMessage(
              "Não foi possível cancelar a nota.",
              "Erro ao cancelar nota"
            );
          }
        }
        refreshNota();
        setLoading(false);
      },
      {
        label: "Motivo",
        type: "text",
        multiline: true,
        rows: 3,
      },
      () => {
        refreshNota();
        setLoading(false);
      }
    );
  };

  const inutilizar = async (
    e: React.MouseEvent<HTMLElement>,
    id: number | undefined
  ) => {
    if (!id) return;
    setLoading(true);
    e.stopPropagation();
    try {
      showInputDialog(
        "Informe o motivo da inutilização",
        "Inutilizar Nota",
        async (value) => {
          try {
            const response = await nota_de_saida.action(
              "post",
              "inutilizar",
              { motivo: value },
              id
            );
            showSnack(response.data.detail, 2, "success");
          } catch (err: unknown) {
            if (err instanceof AxiosError) {
              showMessage(
                "Não foi possível inutilizar a nota: \n" +
                  (err.response?.data?.detail ||
                    err.response?.data?.message ||
                    err.message),
                "Erro ao inutilizar nota"
              );
            } else {
              showMessage(
                "Não foi possível inutilizar a nota.",
                "Erro ao inutilizar nota"
              );
            }
          }
          refreshNota();
          setLoading(false);
        },
        {
          label: "Motivo",
          type: "text",
          multiline: true,
          rows: 3,
        },
        () => {
          refreshNota();
          setLoading(false);
        }
      );
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        showMessage(
          "Não foi possível inutilizar a nota: \n" +
            (err.response?.data?.detail ||
              err.response?.data?.message ||
              err.message),
          "Erro ao inutilizar nota"
        );
      } else {
        showMessage(
          "Não foi possível inutilizar a nota.",
          "Erro ao inutilizar nota"
        );
      }
      setLoading(false);
    }
  };

  const emitir = async (
    e: React.MouseEvent<HTMLElement>,
    id: number | undefined
  ) => {
    if (!id) return;
    setLoading(true);
    e.stopPropagation();
    try {
      const response = await nota_de_saida.action("post", "emitir", {}, id);
      if (response.status === 200) {
        if (response.data.type === "success") {
          showSnack(response.data.detail, 2, "success");
          await salvarPdf(response.data.nota);
          handleImpressao(null, response.data.nota, false);
        } else if (response.data.type === "warning") {
          showSnack(response.data.detail, 2, "warning");
        }
      } else {
        showSnack("Erro ao emitir nota, contate o suporte.", 2, "error");
      }
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        showSnack(
          err.response?.data?.detail ??
            err.response?.data?.message ??
            err.message,
          2,
          "error"
        );
      } else {
        console.error(err);
        showSnack("Erro ao emitir nota, contate o suporte.", 2, "error");
      }
    }
    refreshNota();
    setLoading(false);
  };

  const preview = async (
    e: React.MouseEvent<HTMLElement>,
    nota_id: number | undefined
  ) => {
    if (!nota_id) return;
    setLoading(true);
    try {
      const response = await nota_de_saida.action(
        "post",
        "preview",
        {},
        nota_id
      );
      if (response.status === 200) {
        if (response.data.type === "success") {
          handleImpressao(null, response.data.nota, true);
        } else if (response.data.type === "warning") {
          showSnack(response.data.detail, 2, "warning");
        }
      } else {
        showSnack(
          "Erro ao pré-visualizar nota, contate o suporte.",
          2,
          "error"
        );
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        showSnack(
          err.response?.data?.detail ??
            err.response?.data?.message ??
            err.message,
          2,
          "error"
        );
      } else {
        console.error(err);
        showSnack(
          "Erro ao pré-visualizar nota, contate o suporte.",
          2,
          "error"
        );
      }
    }
    setLoading(false);
    e.stopPropagation();
  };

  function getActualStatus(nota: NotaDeSaidaSerializer) {
    if (nota.status === NotaDeSaidaSerializerStatusEnum.Criada) {
      return {
        color: theme.palette.info[theme.palette.mode],
        icon: "info",
      };
    }
    if (nota.status === NotaDeSaidaSerializerStatusEnum.Emitida) {
      return {
        color: theme.palette.success[theme.palette.mode],
        icon: "check_circle",
      };
    }
    if (nota.status === NotaDeSaidaSerializerStatusEnum.Com_erro) {
      return {
        color: theme.palette.warning[theme.palette.mode],
        icon: "warning",
      };
    }
    if (nota.status === NotaDeSaidaSerializerStatusEnum.Cancelada) {
      return {
        color: theme.palette.error[theme.palette.mode],
        icon: "error",
      };
    }
    return {
      color: theme.palette.warning[theme.palette.mode],
      icon: "warning",
    };
  }

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" padding="checkbox"></TableCell>
            <TableCell align="left">Número</TableCell>
            <TableCell align="left">Data Emissão</TableCell>
            <TableCell align="left">Cliente</TableCell>
            <TableCell align="left">Modelo</TableCell>
            <TableCell align="left">Série</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Total</TableCell>
            <TableCell align="left" style={{ width: "1%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map((nota, index) => (
            <TableRow hover key={index}>
              <TableCell padding="checkbox">
                {
                  <Box display="flex" justifyContent="center">
                    <Icon style={{ color: getActualStatus(nota).color }}>
                      {getActualStatus(nota).icon}
                    </Icon>
                  </Box>
                }
              </TableCell>
              <TableCell>{nota.numero}</TableCell>
              <TableCell>
                {dayjs(nota.data_emissao).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>
                {
                  listCliente?.rows.find((x) => x.id === nota.cliente)
                    ?.razao_social_nome
                }
              </TableCell>
              <TableCell>{nota.modelo}</TableCell>
              <TableCell>{nota.serie}</TableCell>
              <TableCell>{statusText[nota.status]}</TableCell>
              <TableCell>{nota.total.toBRL()}</TableCell>
              <TableCell>
                <Box style={{ paddingTop: 0 }} display="flex">
                  {[
                    NotaDeSaidaSerializerStatusEnum.Criada,
                    NotaDeSaidaSerializerStatusEnum.Com_erro,
                  ].includes(nota.status) ? (
                    <SimplePopper
                      label="Ações"
                      type="vertical"
                      options={[
                        {
                          label: "Pré-visualizar",
                          Icon: Preview,
                          function: (e: React.MouseEvent<HTMLElement>) =>
                            preview(e, nota.id),
                        },
                        {
                          label: "Emitir",
                          Icon: Send,
                          function: (e: React.MouseEvent<HTMLElement>) =>
                            emitir(e, nota.id),
                        },
                        {
                          label: "Editar",
                          Icon: Create,
                          function: (e: React.MouseEvent<HTMLElement>) =>
                            editNota(e, nota.id),
                        },
                        {
                          label: "Inutilizar",
                          Icon: Delete,
                          function: (e: React.MouseEvent<HTMLElement>) =>
                            inutilizar(e, nota.id),
                        },
                      ]}
                    />
                  ) : null}
                  {nota.status === NotaDeSaidaSerializerStatusEnum.Emitida ? (
                    <SimplePopper
                      label="Ações"
                      type="vertical"
                      options={[
                        {
                          label: "Visualizar",
                          Icon: Visibility,
                          function: (e: React.MouseEvent<HTMLElement>) =>
                            editNota(e, nota.id),
                        },
                        {
                          label: "Imprimir",
                          Icon: Print,
                          function: async (
                            e: React.MouseEvent<HTMLElement>
                          ) => {
                            const getNota = await nota_de_saida.get(nota.id);
                            handleImpressao(e, getNota, false);
                          },
                        },
                        {
                          label: "Cancelar",
                          Icon: Cancel,
                          function: (e: React.MouseEvent<HTMLElement>) =>
                            cancelar(e, nota.id),
                        },
                        {
                          label: "Enviar email",
                          Icon: EmailOutlined,
                          function: (e: React.MouseEvent<HTMLElement>) =>
                            enviarEmail(e, nota.id),
                        },
                        {
                          label: "Download XML",
                          Icon: FileCopyIcon,
                          function: async () => {
                            setLoading(true);
                            const getNota = await nota_de_saida.get(nota.id);
                            downloadXml(getNota.xml);
                            setLoading(false);
                          },
                        },
                      ]}
                    />
                  ) : null}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {props.setPage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <NuvelPaginationComponent
            count={props.setPage.totalCount}
            page={props.setPage.page}
            setPage={props.setPage.setPage}
            pageSize={props.setPage.pageSize || 10}
          />
        </div>
      )}
      <Box p={2} display="flex" justifyContent="flex-end" alignItems="center">
        <Box pt={2}>
          <Fab
            onClick={() => handleModal(1)}
            style={{ width: 70, height: 70 }}
            color="primary"
            aria-label="add"
          >
            <Add />
          </Fab>
        </Box>
      </Box>
      <LoadingCover enabled={loading} />
    </>
  );
}
