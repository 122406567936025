import { FormAction, FormProvider, FormState } from "./context";
import { InternalModel, MainInterface } from "data/main";
import FormComponent from "./FormComponent";
import { SelectOption } from "components/nuvel/Select";
import { ValidReference } from "constants/nuvel/Autocomplete";

export interface GridInternalProps {
  spacing?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

interface BaseAutoFormProps<T extends InternalModel> {
  grid?: GridInternalProps;
  name?: keyof T;
  label?: string;
  type: string;
  defaultValue?: string | number | boolean;
  children?: AutoFormProps<T>[];
  disabled?: boolean | ((state: T) => boolean);
  props?: { [key: string]: unknown };
  validation?: (state: T) => boolean;
}

type OmitType<T> = Omit<T, "type">;

interface TextFieldProps {
  type: "text" | "number" | "password" | "money" | "perc" | "decimal";
}

interface SelectProps<T extends InternalModel> {
  type: "select";
  options: SelectOption[] | ((state: T) => SelectOption[]);
  list_name?: string;
}

interface IconProps {
  type: "icon";
}

interface DateProps {
  type: "date";
}

interface AutocompleteProps {
  type: "autocomplete";
  reference?: ValidReference;
}

interface MuiAutocompleteProps {
  type: "muiAutocomplete";
  reference?: ValidReference;
  list_name: string;
}

interface CheckboxProps {
  type: "checkbox";
}

interface FileProps {
  type: "file";
}

interface AccordionProps<T extends InternalModel> {
  type: "accordion";
  startExpanded?: boolean;
  familly?: OmitType<AutoFormProps<T>>[];
}

interface GridProps {
  type: "grid";
}

export type AutoFormProps<T extends InternalModel> =
  | (BaseAutoFormProps<T> & TextFieldProps)
  | (BaseAutoFormProps<T> & SelectProps<T>)
  | (BaseAutoFormProps<T> & IconProps)
  | (BaseAutoFormProps<T> & DateProps)
  | (BaseAutoFormProps<T> & AutocompleteProps)
  | (BaseAutoFormProps<T> & MuiAutocompleteProps)
  | (BaseAutoFormProps<T> & CheckboxProps)
  | (BaseAutoFormProps<T> & FileProps)
  | (BaseAutoFormProps<T> & AccordionProps<T>)
  | (BaseAutoFormProps<T> & GridProps);

export interface AutoFormHooks {
  function: (state: FormState, setState: React.Dispatch<FormAction>) => void;
  watch: string[];
}
interface NuvelAutoFormProps<T extends InternalModel, L extends InternalModel> {
  model: MainInterface<T, L>;
  fields: AutoFormProps<T>[];
  hooks?: AutoFormHooks[];
}

const generateInitialState = <T extends InternalModel>(
  fields: AutoFormProps<T>[]
): Record<string, unknown> => {
  const state: Record<string, unknown> = {};
  fields.forEach((field) => {
    if (field.name) {
      state[field.name as string] = generateStateValue(field);
    }
    if (
      (field.type === "select" || field.type === "muiAutocomplete") &&
      field.list_name
    ) {
      state[field.list_name as string] = generateStateValue(field);
    }
    if (field.children) {
      Object.assign(state, generateInitialState(field.children));
    }
  });
  return state;
};

const generateStateValue = <T extends InternalModel>(
  field: AutoFormProps<T>
) => {
  if (field.defaultValue) return field.defaultValue;
  if (field.type === "checkbox") return false;
  if (field.type === "number") return 0;
  if (field.type === "date") return new Date();
  if (
    field.type === "money" ||
    field.type === "perc" ||
    field.type === "decimal"
  )
    return "0.00";
  if (field.type === "select")
    return typeof field.options === "function"
      ? null
      : field.options?.[0].value;
  if (
    field.type === "autocomplete" ||
    field.type === "muiAutocomplete" ||
    field.type === "password" ||
    field.type === "icon"
  )
    return "";
  if (field.children) return generateInitialState(field.children);
  return "";
};

const NuvelAutoForm = <T extends InternalModel, L extends InternalModel>({
  model,
  fields,
  hooks,
}: NuvelAutoFormProps<T, L>) => {
  const initialState = generateInitialState(fields) as T;

  return (
    <FormProvider initialState={initialState}>
      <FormComponent model={model} fields={fields} hooks={hooks} />
    </FormProvider>
  );
};

export default NuvelAutoForm;
