import { CalculaImpostoSerializer } from "data/interfaces/sefaz/calc_imposto";
import MainModule from "data/main";

/**
 * Aliás mestre do modelo
 */
const alias = "sefaz/calc_imposto";

class CalculaImpostoAPI extends MainModule<
  CalculaImpostoSerializer,
  CalculaImpostoSerializer
> {}

export default new CalculaImpostoAPI(alias);
