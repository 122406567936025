import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenerateFields from "./GenerateFields";
import { useFormContext } from "./context";
import { InternalModel, MainInterface } from "data/main";
import BaseForm from "../shared/form";
import useCurrentRoute from "hooks/useCurrentRoute";
import { AutoFormHooks, AutoFormProps } from "./NuvelAutoForm";

interface FormComponentProps<T extends InternalModel, L extends InternalModel> {
  model: MainInterface<T, L>;
  fields: AutoFormProps<T>[];
  hooks?: AutoFormHooks[];
}

const FormComponent = <T extends InternalModel, L extends InternalModel>({
  model,
  fields,
  hooks,
}: FormComponentProps<T, L>) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const route = useCurrentRoute(1);
  const [loading, setLoading] = React.useState(false);
  const { state, dispatch } = useFormContext();

  useEffect(() => {
    if (id && id !== "novo") {
      setLoading(true);
      model.get(Number(id)).then((data: T) => {
        dispatch({ type: "UPDATE_FORM", value: data, name: "" });
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    model
      .save((id !== "novo" ? { ...state, id: Number(id) } : state) as T)
      .then((response: boolean) => {
        if (response) {
          if (window.history.length > 1) {
            navigate(-1);
          } else {
            const newUrl = window.location.pathname
              .split("/")
              .slice(0, -1)
              .join("/");
            navigate(newUrl);
          }
        } else {
          console.error("Erro ao salvar!");
        }
        setLoading(false);
      });
  };

  hooks?.map((hook) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useEffect(() => {
      hook.function(state, dispatch);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...hook.watch]);
  });

  return (
    <BaseForm
      handleSubmit={(e) => handleSubmit(e)}
      route={route}
      navigate={navigate}
      loading={loading}
    >
      {fields.map((field) => (
        <GenerateFields
          key={field.name as string}
          field={field}
          state={state}
        />
      ))}
    </BaseForm>
  );
};

export default FormComponent;
