import {
  InputAdornment,
  InputProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { IMaskMixin } from "react-imask";
import IntlCurrencyInput from "react-intl-currency-input";

interface NuvelTextFieldProps
  extends Omit<TextFieldProps, "onChange" | "value"> {
  mask?: string;
  normalize?: (value: string) => string;
  money?: boolean;
  perc?: boolean;
  decimal?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => void;
  value: string | number | undefined | null;
  readOnly?: boolean;
  preserveMask?: boolean;
  max?: number;
  min?: number;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  max?: number;
  min?: number;
}

const InternalMaskTextField = IMaskMixin((props) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <TextField {...(props as any)} />
));

const currencyConfig: typeof IntlCurrencyInput.defaultProps.config = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "decimal",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const NumericFormatCustom = React.forwardRef<
  typeof IntlCurrencyInput,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, max, min, ...other } = props;

  return (
    <IntlCurrencyInput
      {...other}
      max={max}
      min={min}
      currency="BRL"
      config={currencyConfig}
      onChange={(_e: unknown, value: string) => {
        onChange({
          target: {
            name: props.name,
            value: value,
          },
        });
      }}
      inputRef={ref}
      defaultValue={0}
    />
  );
});

const NuvelTextField: React.FC<NuvelTextFieldProps> = ({
  value,
  onChange,
  mask,
  normalize,
  money,
  perc,
  decimal,
  readOnly = false,
  max,
  min,
  ...rest
}: NuvelTextFieldProps) => {
  const handleMaskedChange = (value: string) => {
    const newValue = normalize ? normalize(value) : value;
    if (onChange) {
      onChange(
        {
          target: {
            name: rest.name as string,
            value: newValue,
          },
        } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue
      );
    }
  };

  const inputProps = React.useMemo(() => {
    const finalProps: InputProps = {};
    if (perc) {
      finalProps.startAdornment = (
        <InputAdornment position="start">%</InputAdornment>
      );
    } else if (decimal) {
      finalProps.startAdornment = (
        <InputAdornment position="start"></InputAdornment>
      );
    } else if (money) {
      finalProps.startAdornment = (
        <InputAdornment position="start">R$</InputAdornment>
      );
    }
    return finalProps;
  }, [decimal, money, perc]);

  if (money || perc || decimal) {
    return (
      <TextField
        {...rest}
        label={rest.label}
        value={Number(value)}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          const newValue = e.target.value;
          if (onChange) {
            onChange(e, newValue);
          }
        }}
        name={rest.name}
        id={`number-format-${rest.name}`}
        slotProps={{
          input: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: NumericFormatCustom as any,
            inputProps: {
              max: max,
              min: min,
            },
            readOnly: readOnly,
            ...inputProps,
          },
        }}
        variant="filled"
        fullWidth
      />
    );
  } else if (mask) {
    return (
      <InternalMaskTextField
        mask={mask}
        value={value || ""}
        onAccept={handleMaskedChange}
        slotProps={{
          input: {
            readOnly: readOnly,
          },
        }}
        variant="filled"
        fullWidth
        label={rest.label}
      />
    );
  } else {
    return (
      <TextField
        variant="filled"
        fullWidth
        value={value || ""}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) =>
          normalize
            ? onChange && onChange(e, normalize(e.target.value))
            : onChange && onChange(e, e.target.value)
        }
        slotProps={{
          input: {
            readOnly: readOnly,
          },
        }}
        {...rest}
      />
    );
  }
};

export default NuvelTextField;
