import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { NuvelRawForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import { SimpleList } from "components/nuvel/lists";
import NuvelSelect from "components/nuvel/Select";
import {
  EtiquetaAtacado,
  EtiquetaJoia,
  EtiquetaSimples,
} from "components/retaguarda/reports";
import { ETIQUETAS } from "constants/retaguarda/cadastro/produtos";
import { EtiquetaEmLoteSerializer } from "data/interfaces/estoque/EtiquetaEmLoteSerializer";
import React from "react";
import { handleChange } from "utils/functions";
import { imprimirDoc } from "utils/functions/print";

interface StateInterface {
  formatoSelecionado: string;
  produtos: EtiquetaEmLoteSerializer[];
  produto_selecionado: string | number | undefined;
}

const RetEtiquetaEmLoteForm: React.FC = () => {
  const [state, setState] = React.useState<StateInterface>({
    formatoSelecionado: "ETIQUETA SIMPLES",
    produtos: [],
    produto_selecionado: undefined,
  });

  const onSubmit = (stopLoading: () => void) => {
    switch (state.formatoSelecionado) {
      case "ETIQUETA SIMPLES":
        imprimirDoc(
          <EtiquetaSimples
            prods={state.produtos.map((x) => ({
              produto_nome: x.produto_nome,
              preco_venda: x.produto.preco_venda,
              cod_barra: x.cod_barra,
            }))}
          />
        );
        break;
      case "ETIQUETA SIMPLES 3x4":
        imprimirDoc(
          <EtiquetaSimples
            prods={state.produtos.map((x) => ({
              produto_nome: x.produto_nome,
              preco_venda: x.produto.preco_venda,
              cod_barra: x.cod_barra,
            }))}
            altura={125}
            largura={95}
            wrap="wrap"
            maxHeight={80}
            cod_barras_size={45}
            cod_barras_height={90}
          />
        );
        break;
      case "ETIQUETA SIMPLES 3x4 (3 COLUNAS)":
        imprimirDoc(
          <EtiquetaSimples
            prods={state.produtos.map((x) => ({
              produto_nome: x.produto.nome,
              preco_venda: x.produto.preco_venda,
              cod_barra: x.cod_barra,
            }))}
            altura={46}
            largura={230}
            wrap="wrap"
            maxHeight={80}
            cod_barras_size={25}
            cod_barras_height={80}
            colunas={3}
            price_on_bottom
            margem={8}
            nome_font_multiplier={0.5}
            preco_font_multiplier={0.7}
            custom_barcode_style={{
              width: (230 - 60) / 3,
              marginLeft: "-30%",
            }}
          />
        );
        break;
      case "ETIQUETA SIMPLES 10x3":
        imprimirDoc(
          <EtiquetaSimples
            prods={state.produtos.map((x) => ({
              produto_nome: x.produto_nome,
              preco_venda: x.produto.preco_venda,
              cod_barra: x.cod_barra,
            }))}
            altura={94}
            largura={350}
            horizontal
          />
        );
        break;
      case "ETIQUETA SIMPLES 11x4":
        imprimirDoc(
          <EtiquetaSimples
            prods={state.produtos.map((x) => ({
              produto_nome: x.produto_nome,
              preco_venda: x.produto.preco_venda,
              cod_barra: x.cod_barra,
            }))}
            altura={125}
            largura={385}
            horizontal
          />
        );
        break;
      case "ETIQUETA ATACADO":
        imprimirDoc(
          <EtiquetaAtacado
            prods={state.produtos.map((x) => ({
              produto_nome: x.produto_nome,
              preco_venda: x.produto.preco_venda,
              cod_barra: x.cod_barra,
            }))}
          />
        );
        break;
      case "ETIQUETA JOIA":
        imprimirDoc(
          <EtiquetaJoia
            prods={state.produtos.map((x) => ({
              produto_nome: x.produto_nome,
              preco_venda: x.produto.preco_venda,
              cod_barra: x.cod_barra,
            }))}
          />
        );
        break;
      default:
        break;
    }
    stopLoading();
    return;
  };

  return (
    <NuvelRawForm onSubmit={onSubmit} title="Etiqueta em Lote">
      <NuvelGrid container spacing={1}>
        <NuvelGrid size={{ xs: 12, md: 4 }}>
          <NuvelSelect
            label="Formato de Etiqueta"
            name="formatoSelecionado"
            value={state.formatoSelecionado}
            onChange={(e) => handleChange(e, setState)}
            options={ETIQUETAS.map((i) => ({
              label: i,
              value: i,
            }))}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12, md: 8 }}>
          <NuvelAutocomplete
            label="Produto"
            name="produto_selecionado"
            onChange={(_e, _v, r) => {
              if (r) {
                const temp_produtos = [...state.produtos];
                if (!temp_produtos.find((x) => x.id === r.id)) {
                  temp_produtos.push(r);
                  setState({
                    ...state,
                    produtos: temp_produtos,
                  });
                }
              }
            }}
            reference="etiqueta_em_lote"
            value={state.produto_selecionado}
            clearAfterSelect
            filter={{
              id__not_in: state.produtos.map((x) => x.id).join(","),
            }}
          />
        </NuvelGrid>
        <NuvelGrid size={{ xs: 12 }}>
          <SimpleList
            columns={[
              {
                label: "Produto",
                accessor: "produto_nome",
              },
              {
                label: "",
                accessor: (_row: EtiquetaEmLoteSerializer, index: number) => (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        produtos: prev.produtos.filter((_p, i) => i !== index),
                      }));
                    }}
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.error.main}`,
                    })}
                  >
                    <Delete color="error" />
                  </IconButton>
                ),
                align: "right",
              },
            ]}
            data={state.produtos}
          />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelRawForm>
  );
};

export default RetEtiquetaEmLoteForm;
