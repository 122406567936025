/* eslint-disable @typescript-eslint/no-explicit-any */
import { AcessoModel, PermissaoModel } from "data/models";
import React, { useEffect, useState, ReactNode, useCallback } from "react";
import { useParams } from "react-router-dom";

export interface Permission {
  id: string;
  id_pai: string | null;
  acesso: string;
  nome: string;
  children: Permission[];
  value: string[];
}

interface PermControllerProps {
  children: (controller: PermControllerState) => ReactNode;
}

interface PermControllerState {
  perms: Permission[];
  permissions: Permission[];
  setPermissions: (permissions: Permission[]) => void;
  tempPermissions: Permission[];
  setTempPermissions: (tempPermissions: Permission[]) => void;
  name: string;
  setName: (name: string) => void;
}

const PermController: React.FC<PermControllerProps> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { data: _permissao } = PermissaoModel.useModel({ page_size: 1000 });
  const [perms, setPerms] = useState<Permission[]>([]);
  const [name, setName] = useState<string>("");
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [tempPermissions, setTempPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    if (!_permissao) return;

    const location = _permissao.rows;

    const buildTree = (
      items: Permission[],
      parentId: string | null = null
    ): Permission[] => {
      return items
        .filter((item) => item.id_pai === parentId)
        .map((item) => ({
          ...item,
          children: buildTree(items, item.id),
        }));
    };

    const adjustedLocation = location.map((item: any) => ({
      ...item,
      id_pai: item.id_pai === 0 ? null : item.id_pai,
    }));

    const tree = buildTree(adjustedLocation);
    setPerms(tree);
  }, [_permissao]);

  useEffect(() => {
    if (id && id !== "novo") {
      AcessoModel.get(Number(id))
        .then((data) => {
          const permissionsString = data.acessos;
          if (_permissao === null || _permissao === undefined) return;
          const parsedPermissions = parsePermissionsString(
            permissionsString,
            _permissao.rows as any
          );
          setPermissions(parsedPermissions);
          setName(data.nome);
        })
        .catch((error) => {
          console.error("Error fetching permissions:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, _permissao]);

  const parsePermissionsString = useCallback(
    (permissionsString: string, allPermissions: any): Permission[] => {
      const permissionsArray = permissionsString.split(";").filter(Boolean);
      const permissionsTree = buildPermissionsTree(
        permissionsArray,
        allPermissions
      );
      return permissionsTree;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const buildPermissionsTree = useCallback(
    (
      permissionsArray: string[],
      allPermissions: Permission[]
    ): Permission[] => {
      const root: Permission[] = [];

      permissionsArray.forEach((permString) => {
        const splitIndex = permString.lastIndexOf("_");
        if (splitIndex === -1) return;

        const access = permString.slice(0, splitIndex);
        const rights = permString.slice(splitIndex + 1).split("");

        const originalPerm = allPermissions.find(
          (perm) => perm.acesso === access
        );

        if (originalPerm) {
          const node: Permission = {
            ...originalPerm,
            value: rights,
            children: [],
          };
          addPermissionToTree(root, node);
        }
      });

      return root;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const addPermissionToTree = useCallback(
    (tree: Permission[], node: Permission) => {
      const parent = tree.find((n) => n.id === node.id_pai);

      if (parent) {
        parent.children.push(node);
      } else {
        tree.push(node);
      }
    },
    []
  );

  const returnController: PermControllerState = {
    perms,
    permissions,
    setPermissions,
    tempPermissions,
    setTempPermissions,
    name,
    setName,
  };

  return <>{children(returnController)}</>;
};

export default PermController;
