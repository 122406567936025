import { Box } from "@mui/material";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import React from "react";
import FormsTitle from "./title";
import FormsButtons from "./buttons";
import { RouteInterface } from "utils/router";
import { NavigateFunction } from "react-router-dom";

interface BaseFormProps {
  loading: boolean;
  route: RouteInterface | null;
  navigate: NavigateFunction;
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
  extraButtons?: React.ReactNode;
  title?: string | (() => React.ReactNode);
  formRef?: React.RefObject<HTMLFormElement>;
}

const BaseForm: React.FC<BaseFormProps> = ({
  handleSubmit,
  loading,
  route,
  navigate,
  children,
  extraButtons,
  title,
  formRef,
}) => {
  return (
    <Box
      ref={formRef}
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ width: "100%", height: "100%" }}
    >
      <Box sx={{ height: "calc(100% - 52.5px)" }}>
        <PaperWithLoad
          loading={loading}
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <FormsTitle title={title} route={route} />
          <Box
            p={2}
            pt={0}
            sx={{
              maxHeight: "calc(100% - 65px)",
              height: "100%",
              overflow: "auto",
            }}
          >
            {children}
          </Box>
        </PaperWithLoad>
      </Box>
      <FormsButtons navigate={navigate} extraButtons={extraButtons} />
    </Box>
  );
};

export default BaseForm;
