import { TableColumn, TableFilter } from "components/nuvel/lists";
import { CatalogoSerializer } from "data/interfaces/catalogo/CatalogoSerializer";

export const RETAGUARDA_CATALOGO_LIST: TableColumn<CatalogoSerializer>[] = [
  {
    label: "Nome",
    accessor: "nome",
    minWidth: 300,
  },
];

export const RETAGUARDA_CATALOGO_ORDERING: (keyof CatalogoSerializer)[] = [
  "nome",
];

export const RETAGUARDA_CATALOGO_FILTERS: TableFilter<CatalogoSerializer>[] = [
  {
    label: "Nome",
    accessor: "nome",
    support: ["icontains"],
  },
];
