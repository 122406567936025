import { useSnackbar } from "notistack";
import React from "react";
import {
  showConfirm,
  showConfirmSync,
  showInputDialog,
  showInputDialogSync,
  showLayoutDialog,
  showMessage,
} from "utils/functions/dialog";
import { decryptPermissions, encryptPermissions } from "utils/hash";

type SnackType = "error" | "warning" | "info" | "success";

enum DialogType {
  Message = 1,
  Confirm = 2,
  ConfirmSync = 3,
  Input = 4,
  InputSync = 5,
  Layout = 6,
}

interface Permissions {
  acesso: string;
  value: string[];
}
interface ConfigurationOptions {
  permissions: Permissions[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPermissions: (permissions?: any) => void;
  changeTheme: () => void;
  getPermissions: () => Permissions[];
}

interface SnackFunctions {
  showSnack: (msg: string, timer: number, type: SnackType) => void;
}

interface DialogFunctions {
  showDialog: (
    msg: string,
    title: string,
    type: DialogType,
    inputProps?: string,
    onConfirm?: () => void,
    onCancel?: () => void
  ) => void;
  showMessage: (
    msg: string,
    title: string,
    onConfirm?: (validation: boolean) => void
  ) => void;
  showConfirm: (
    msg: string,
    title: string,
    onConfirm?: (validation: boolean) => void
  ) => void;
  showConfirmSync: (msg: string, title: string) => Promise<boolean>;
  showInputDialog: (
    msg: string,
    title: string,
    onConfirm?: (text: string) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputProps?: any,
    onCancel?: () => void
  ) => void;
  showInputDialogSync: (msg: string, title: string) => Promise<string>;
  showLayoutDialog: (onConfirm?: (layout: number) => void) => void;
}

interface ActionFunctions {
  performAction: (action: string) => void;
}

export interface AppConstants extends SnackFunctions, ActionFunctions {
  dialog: DialogFunctions;
  configuration: ConfigurationOptions;
}

interface AppConstantsProviderProps {
  children: React.ReactNode;
  handleTheme: () => void;
}

export const AppContext = React.createContext<AppConstants>({} as AppConstants);

const AppConstantsProvider: React.FC<AppConstantsProviderProps> = ({
  children,
  handleTheme,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const setPermissions = (permissions?: string) => {
    if (permissions) {
      const root = [] as Permissions[];

      permissions
        .split(";")
        .filter(Boolean)
        .forEach((permString: string) => {
          const splitIndex = permString.lastIndexOf("_");
          if (splitIndex === -1) return;

          const access = permString.slice(0, splitIndex);
          const rights = permString.slice(splitIndex + 1).split("");

          root.push({
            acesso: access,
            value: rights,
          });
        });

      const encryptedPermissions = encryptPermissions(root);
      localStorage.setItem("permissions", encryptedPermissions);

      appConstants.configuration.permissions = root;
    } else {
      appConstants.configuration.permissions = [];
      localStorage.removeItem("permissions");
    }
  };

  const getPermissions = () => {
    if (appConstants.configuration.permissions.length > 0) {
      return appConstants.configuration.permissions;
    }
    const encryptedPermissions = localStorage.getItem("permissions");
    if (encryptedPermissions) {
      return decryptPermissions(encryptedPermissions);
    }
    return [];
  };

  const snackFunctions: SnackFunctions = {
    showSnack: (msg, timer, type) => {
      enqueueSnackbar(msg, {
        variant: type,
        autoHideDuration: timer * 1000,
      });
    },
  };

  const dialogFunctions: DialogFunctions = {
    showDialog: (msg, title, type, _inputProps, _onConfirm, _onCancel) => {
      if (type === DialogType.Message) {
        return showMessage(msg, title, _onConfirm);
      }
      if (type === DialogType.Confirm) {
        return showConfirm(msg, title, _onConfirm);
      }
      if (type === DialogType.ConfirmSync) {
        return showConfirmSync(msg, title);
      }
      if (type === DialogType.Input) {
        return showInputDialog(msg, title, _onConfirm, _inputProps, _onCancel);
      }
      if (type === DialogType.InputSync) {
        return showInputDialogSync(msg, title);
      }
      if (type === DialogType.Layout) {
        return showLayoutDialog(_onConfirm);
      }
    },
    showMessage: showMessage,
    showConfirm: showConfirm,
    showConfirmSync: showConfirmSync,
    showInputDialog: showInputDialog,
    showInputDialogSync: showInputDialogSync,
    showLayoutDialog: showLayoutDialog,
  };

  const actionFunctions: ActionFunctions = {
    performAction: (action) => {
      console.log(`Performing Action: ${action}`);
    },
  };

  const configurationOptions: ConfigurationOptions = {
    changeTheme: handleTheme,
    setPermissions: setPermissions,
    permissions: [],
    getPermissions: getPermissions,
  };

  const appConstants: AppConstants = {
    ...snackFunctions,
    ...actionFunctions,
    dialog: dialogFunctions,
    configuration: configurationOptions,
  };

  return (
    <AppContext.Provider value={appConstants}>{children}</AppContext.Provider>
  );
};

export default AppConstantsProvider;
