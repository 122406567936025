import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import CourierPrime from "reports/fonts/CourierPrime-Regular.ttf";
import CourierPrimeBold from "reports/fonts/CourierPrime-Bold.ttf";
import logo_nfe from "assets/img/logo-nfe.png";
import dayjs from "dayjs";

// Register font
Font.register({
  family: "Courier Prime",
  fonts: [
    {
      src: CourierPrime,
    },
    {
      src: CourierPrimeBold,
      fontWeight: "bold",
    },
  ],
});

const stylesPage = StyleSheet.create({
  page: {
    fontFamily: "Courier Prime",
    backgroundColor: "#FFFFFF",
    paddingTop: 18,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  border: {
    border: "0.5px solid black",
  },
  boxTipo: {
    padding: "4px 8px",
    marginTop: 5,
  },
  borderTop: {
    borderTop: "0.5px solid black",
  },
  borderLeft: {
    borderLeft: "0.5px solid black",
  },
  borderTopDashed: {
    borderTop: "0.5px dashed black",
  },
  header: {
    display: "flex",
    flexDirection: "column",
  },
  width100: {
    width: "100%",
  },
  width20: {
    width: "20%",
  },
  width25: {
    width: "25%",
  },
  width30: {
    width: "30%",
  },
  width40: {
    width: "40%",
  },
  width45: {
    width: "45%",
  },
  width50: {
    width: "50%",
  },
  width70: {
    width: "70%",
  },
  displayFlex: {
    display: "flex",
    flexDirection: "column",
  },
  displayStart: {
    justifyContent: "flex-start",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textLeft: {
    textAlign: "left",
  },
  textBold: {
    fontWeight: "bold",
  },
  textMedium: {
    fontWeight: "normal",
  },
  flexDirectionRow: {
    flexDirection: "row",
  },
  flexDirectionColumn: {
    flexDirection: "column",
  },
  h1: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  h3: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  h4: {
    fontSize: "8px",
    fontWeight: "bold",
  },
  h5: {
    fontSize: "7px",
  },
  text: {
    fontSize: "7px",
  },
  textSmall: {
    fontSize: "7px",
  },
  titleCell: {
    fontSize: "7px",
    fontWeight: "bold",
  },
  textCell: {
    fontSize: "7px",
  },
  cell: {
    height: "auto",
    padding: "0px 1.5px",
    alignItems: "stretch",
  },
  cellProduct: {
    padding: "2px",
  },
  topRight: {
    padding: "5px",
  },
  topCenter: {
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  general: {
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
    border: "0.5px solid black",
  },
  product: {
    fontSize: "7px",
  },
  inf_complementares: {
    minHeight: "70px",
  },
});

interface RowProductProps {
  key: string | number;
  cod: string;
  descricao: string;
  ncm: string;
  cst: string;
  cfop: string;
  unidade: string;
  quantidade: string;
  valor_unitario: string;
  valor_total: string;
  base_calculo_icms: string;
  valor_icms: string;
  valor_ipi: string;
  icms: string;
  ipi: string;
  lastItem: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles: any;
}

const RowProduct = ({
  key,
  cod,
  descricao,
  ncm,
  cst,
  cfop,
  unidade,
  quantidade,
  valor_unitario,
  valor_total,
  base_calculo_icms,
  valor_icms,
  valor_ipi,
  icms,
  ipi,
  lastItem,
  styles,
}: RowProductProps) => {
  return (
    <View
      key={key}
      style={{
        ...styles.displayFlex,
        ...styles.flexDirectionRow,
        width: "100%",
      }}
    >
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "6%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{cod}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "17%",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{descricao}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "8%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{ncm}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "5.33%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{cst}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "5.33%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{cfop}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "5.33%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{unidade}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "8%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{quantidade}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "8%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>
          {valor_unitario}
        </Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "8%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{valor_total}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "8%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>
          {base_calculo_icms}
        </Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "6%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{valor_icms}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "6%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{valor_ipi}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderRight: "none",
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "4%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{icms}</Text>
      </View>
      <View
        style={{
          ...styles.border,
          ...styles.displayFlex,
          borderTop: styles.borderTop.borderTop,
          borderTopStyle: "dashed",
          borderBottom: lastItem ? styles.general.border : "none",
          width: "4%",
          textAlign: "center",
          padding: styles.cellProduct.padding,
        }}
      >
        <Text style={{ fontSize: styles.product.fontSize }}>{ipi}</Text>
      </View>
    </View>
  );
};

interface CellProps {
  title: string;
  width: string;
  align: string;
  value?: string | null;
  removeRightBorder?: boolean;
  removeBottomBorder?: boolean;
  textMedium?: boolean;
  uppercase?: boolean;
  minHeight?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles: any;
}

const Cell = ({
  title,
  width,
  align,
  value,
  removeRightBorder,
  removeBottomBorder,
  textMedium,
  uppercase,
  minHeight,
  styles,
}: CellProps) => {
  return (
    <View
      style={{
        width,
        ...styles.border,
        ...styles.displayFlex,
        ...styles.flexDirectionColumn,
        borderRight: removeRightBorder ? "none" : styles.general.border,
        borderBottom: removeBottomBorder ? "none" : styles.general.border,
        padding: styles.cell.padding,
        height: styles.cell.height,
      }}
    >
      <Text style={{ ...styles.titleCell }}>{title}</Text>
      <Text
        style={{
          ...styles.textCell,
          textAlign: align,
          fontWeight: textMedium ? "medium" : "normal",
          textTransform: uppercase ? "uppercase" : "none",
          minHeight: minHeight ? minHeight : styles.cell.minHeight,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

Cell.defaultProps = {
  textMedium: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getImpostoICMS = (cst: { [x: string]: any }) => {
  const chave = Object.keys(cst);
  return cst[chave[0]];
};

const formatNumero = (numero: string) => {
  let numeroFormatado = numero;
  if (numero) {
    numeroFormatado = numero.replace(/(\d{4})/g, "$1 ");
  }
  return numeroFormatado;
};

const formatarCnpj = (cnpj: string) => {
  let cnpjFormatado = cnpj;
  if (cnpj) {
    cnpjFormatado = cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }
  return cnpjFormatado;
};

const formatarCpf = (cpf: string) => {
  let cpfFormatado = cpf;
  if (cpf) {
    cpfFormatado = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return cpfFormatado;
};

const formatarCep = (cep: string) => {
  let cepFormatado = cep;
  if (cep) {
    cepFormatado = cep.toString().replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
  }
  return cepFormatado;
};

const formatarIE = (ie: string) => {
  let ieFormatado = ie;
  if (ie) {
    ieFormatado = ie.replace(/(\d{2})(\d{3})(\d{2})(\d{1})/, "$1.$2.$3-$4");
  }
  return ieFormatado;
};

const pegarProtocolo = (json: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: { [x: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nfeProc?: any;
}) => {
  try {
    if (Object.keys(json).includes("nfeProc")) {
      json = json.nfeProc;
    }

    return json["ns0:protNFe"]["ns0:infProt"];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return "";
  }
};

interface CupomNfeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _json: any;
  tipo: string | number;
  barcode: string;
  observacao?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notaCompleta?: any;
}

const CupomNfe = ({
  _json,
  tipo,
  barcode,
  observacao,
  notaCompleta,
}: CupomNfeProps) => {
  const infNFe = _json;

  const emitente = infNFe.emit;
  const nota = infNFe.ide;
  const destinatario = infNFe.dest;
  const total = infNFe.total;
  const transportadora = infNFe.transp;
  const protocolo = pegarProtocolo(notaCompleta);

  const produtos = Array.isArray(infNFe.det) ? infNFe.det : [infNFe.det];

  const styles = stylesPage;

  return (
    <>
      <Document>
        <Page style={styles.page}>
          <View
            style={{
              width: "100%",
              ...styles.displayFlex,
              ...styles.displayStart,
              ...styles.flexDirectionRow,
              justifyContent: "space-around",
            }}
          >
            <View
              style={{
                ...styles.displayFlex,
                ...styles.width70,
              }}
            >
              <View
                style={{
                  display: "flex",
                  ...styles.width100,
                }}
              >
                <Cell
                  width="100%"
                  title={`Recebemos de ${emitente.xNome}  os produtos/serviços constantes na nota fiscal indicada ao lado`}
                  value=""
                  align="left"
                  removeBottomBorder={true}
                  styles={styles}
                />
              </View>
              <View
                style={{
                  ...styles.displayFlex,
                  ...styles.borderTop,
                  ...styles.flexDirectionRow,
                }}
              >
                <Cell
                  width="30%"
                  title="Data de Recebimento"
                  value={dayjs(infNFe.ide.dhEmi).format("DD/MM/YYYY HH:mm:ss")}
                  align="left"
                  removeRightBorder={true}
                  styles={styles}
                />
                <Cell
                  width="70%"
                  title="Identificação e Assinatura do Recebedor"
                  value=""
                  align="left"
                  styles={styles}
                />
              </View>
            </View>
            <View
              style={{
                ...styles.border,
                ...styles.displayFlex,
                ...styles.width20,
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  ...styles.textCenter,
                  ...styles.textBold,
                  ...styles.h3,
                }}
              >
                NF-e
              </Text>
              <Text
                style={{
                  ...styles.textCenter,
                  ...styles.textBold,
                  ...styles.text,
                }}
              >
                N° {nota.nNF}
              </Text>
              <Text
                style={{
                  ...styles.textCenter,
                  ...styles.textBold,
                  ...styles.text,
                }}
              >
                Série: {nota.serie}
              </Text>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              ...styles.displayFlex,
              ...styles.flexDirectionRow,
              justifyContent: "space-around",
              paddingLeft: "2.5%",
              paddingRight: "2.5%",
            }}
          >
            <View
              style={{
                borderBottom: "1px dashed black",
                width: "100%",
                display: "flex",
                height: 1,
                marginTop: 7,
              }}
            ></View>
          </View>

          <View
            style={{
              width: "100%",
              ...styles.displayFlex,
              ...styles.flexDirectionRow,
              justifyContent: "space-around",
              paddingLeft: styles.general.paddingLeft,
              paddingRight: styles.general.paddingRight,
              marginTop: 8,
            }}
          >
            <View
              style={{
                ...styles.border,
                ...styles.displayFlex,
                ...styles.width45,
                ...styles.justifyContentCenter,
                padding: "0px 10px",
              }}
            >
              <View
                style={{
                  ...styles.displayFlex,
                  ...styles.flexDirectionRow,
                }}
              >
                <View
                  style={{
                    ...styles.displayFlex,
                    ...styles.width30,
                    justifyContent: "center",
                  }}
                >
                  <Image src={logo_nfe} style={{ width: "100%" }} />
                </View>
                <View
                  style={{
                    ...styles.width70,
                    ...styles.displayFlex,
                    ...styles.textRight,
                    ...styles.justifyContentCenter,
                    marginTop: "10px",
                  }}
                >
                  <Text
                    style={{
                      ...styles.h5,
                      ...styles.textBold,
                    }}
                  >
                    {emitente.xNome}
                  </Text>
                  <Text
                    style={{
                      ...styles.h5,
                    }}
                  >
                    {emitente.enderEmit.xLgr}, {emitente.enderEmit.nro}{" "}
                    {emitente.enderEmit.xBairro} <br />{" "}
                    {formatarCep(emitente.enderEmit.CEP)} -{" "}
                    {emitente.enderEmit.xMun} - {emitente.enderEmit.UF}
                  </Text>
                  <Text
                    style={{
                      ...styles.h5,
                    }}
                  >
                    TEL/FAX: {infNFe.infRespTec?.fone}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.width25,
                paddingLeft: styles.topCenter.paddingLeft,
                paddingRight: styles.topCenter.paddingRight,
              }}
            >
              <Text
                style={{
                  ...styles.h3,
                  ...styles.textCenter,
                  ...styles.textBold,
                }}
              >
                DANFE
              </Text>
              <Text
                style={{
                  ...styles.h5,
                  ...styles.textCenter,
                }}
              >
                Documento Auxiliar da Nota Fiscal Eletrônica
              </Text>
              <View
                style={{
                  ...styles.displayFlex,
                  ...styles.flexDirectionRow,
                  justifyContent: "center",
                  marginTop: styles.boxTipo.marginTop,
                }}
              >
                <View
                  style={{
                    ...styles.displayFlex,
                    ...styles.flexDirectionColumn,
                    paddingRight: "10px",
                  }}
                >
                  <Text
                    style={{
                      ...styles.h5,
                    }}
                  >
                    0 - Entrada
                  </Text>
                  <Text
                    style={{
                      ...styles.h5,
                    }}
                  >
                    1 - Saída
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    padding: styles.boxTipo.padding,
                  }}
                >
                  <Text
                    style={{
                      ...styles.h5,
                    }}
                  >
                    {tipo}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.displayFlex,
                  ...styles.flexDirectionColumn,
                  padding: "10px",
                }}
              >
                <Text
                  style={{
                    ...styles.h4,
                    ...styles.textBold,
                  }}
                >
                  Nº 00<Text style={{ ...styles.text }}>{nota.nNF}</Text>
                </Text>
                <Text
                  style={{
                    ...styles.h5,
                  }}
                >
                  SÉRIE: {nota.serie}
                </Text>
                <Text
                  style={{
                    ...styles.h5,
                  }}
                >
                  FOLHA: 1 de 1
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.width50,
              }}
            >
              <View
                style={{
                  ...styles.border,
                  ...styles.displayFlex,
                  ...styles.flexDirectionColumn,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                    }}
                  >
                    <View
                      style={{
                        position: "relative",
                        height: "20px",
                        width: "100%",
                        overflow: "hidden",
                        borderBottom: "5px solid #fff",
                      }}
                    >
                      <Image
                        src={barcode}
                        style={{
                          width: "100%",
                          height: "50px",
                          position: "absolute",
                        }}
                      />
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.borderTop,
                    ...styles.displayFlex,
                    ...styles.flexDirectionColumn,
                    padding: "5px",
                  }}
                >
                  <Text style={{ ...styles.text }}>Chave de Acessos</Text>
                  <Text
                    style={{
                      ...styles.text,
                      ...styles.textSmall,
                      fontWeight: "bold",
                    }}
                  >
                    {formatNumero(infNFe["@Id"].replace("NFe", ""))}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.border,
                  ...styles.displayFlex,
                  padding: styles.topRight.padding,
                }}
              >
                <Text style={{ ...styles.textSmall }}>
                  Consulta de autenticidade no portal nacional da NF-e
                  www.nfe.azenda.gov.br/portal ou no site da Sefaz Autorizadora
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              ...styles.displayFlex,
              ...styles.flexDirectionColumn,
              justifyContent: "space-around",
              paddingLeft: styles.general.paddingLeft,
              paddingRight: styles.general.paddingRight,
              marginTop: 20,
            }}
          >
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="55%"
                title="Natureza da operação"
                value={nota.natOp}
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="45%"
                title="Protocolo de autorização de uso"
                value={`${protocolo["ns0:nProt"]} ${dayjs(
                  protocolo["ns0:dhRecbto"]
                ).format("DD/MM/YYYY HH:mm:ss")}`}
                align="left"
                removeBottomBorder={true}
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="33.33%"
                title="Inscrição estadual"
                value={formatarIE(emitente.IE)}
                align="left"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="33.33%"
                title=""
                value=""
                align="left"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="33.33%"
                title="CNPJ"
                value={formatarCnpj(emitente.CNPJ)}
                align="left"
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
                marginTop: "16px",
              }}
            >
              <Text
                style={{
                  ...styles.h4,
                }}
              >
                Destinatário/Remetente
              </Text>
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="60%"
                title="Nome/Razão Social"
                value={destinatario.xNome}
                align="left"
                uppercase={true}
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title={destinatario.CNPJ ? "CNPJ" : "CPF"}
                value={
                  destinatario.CNPJ
                    ? formatarCnpj(destinatario.CNPJ)
                    : formatarCpf(destinatario.CPF)
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Data da emissão"
                value={dayjs(nota.dhEmi).format("DD/MM/YYYY")}
                align="left"
                removeBottomBorder={true}
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="45%"
                title="Endereço"
                value={`${destinatario.enderDest.xLgr}, ${destinatario.enderDest.nro}`}
                align="left"
                uppercase={true}
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Bairro/Distrito"
                value={destinatario.enderDest.xBairro}
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="15%"
                title="CEP"
                value={formatarCep(destinatario.enderDest.CEP)}
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Data de Saída/Entrada"
                value={dayjs(nota.dhSaiEnt).format("DD/MM/YYYY")}
                align="left"
                removeBottomBorder={true}
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="25%"
                title="Município"
                value={destinatario.enderDest.xMun}
                align="left"
                uppercase={true}
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="15%"
                title="Fone/Fax"
                value=""
                align="left"
                uppercase={true}
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="5%"
                title="UF"
                value={destinatario.enderDest.UF}
                align="left"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="35%"
                title="Inscrição Estadual"
                value={formatarIE(destinatario.IE)}
                align="left"
                uppercase={true}
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Hora de Saída"
                value={dayjs(nota.dhSaiEnt).format("HH:mm:ss")}
                align="left"
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
                marginTop: "16px",
              }}
            >
              <Text
                style={{
                  ...styles.h4,
                }}
              >
                Cálculo do imposto
              </Text>
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="20%"
                title="Base de cálculo de ICMS"
                value={`${total.ICMSTot.vBC.toBRL()}`}
                align="center"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Valor do ICMS"
                value={`${total.ICMSTot.vICMS.toBRL()}`}
                align="center"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="BC ICMS ST"
                value={`${total.ICMSTot.vBCST.toBRL()}`}
                align="center"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="V. ICMS Sub."
                value={total.ICMSTot.vST.toBRL()}
                align="center"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Valor total dos produtos"
                value={total.ICMSTot.vProd.toBRL()}
                align="center"
                removeBottomBorder={true}
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="14.66%"
                title="Valor do Frete"
                value={total.ICMSTot.vFrete.toBRL()}
                align="center"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="14.66%"
                title="Valor do Seguro"
                value={total.ICMSTot.vSeg.toBRL()}
                align="center"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="14.66%"
                title="Desconto"
                value={total.ICMSTot.vDesc.toBRL()}
                align="center"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="22.66%"
                title="Outras despesas acessórias"
                value={total.ICMSTot.vOutro.toBRL()}
                align="center"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="16.66%"
                title="Valor do IPI / Devol"
                value={(
                  Number(total.ICMSTot.vIPI) || Number(total.ICMSTot.vIPIDevol)
                ).toBRL()}
                align="center"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="16.66%"
                title="Valor total da nota"
                value={total.ICMSTot.vNF.toBRL()}
                align="center"
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
                marginTop: "16px",
              }}
            >
              <Text
                style={{
                  ...styles.h4,
                }}
              >
                Transportador/Volumes transportados
              </Text>
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="40%"
                title="Razão Social"
                value={
                  transportadora.transporta
                    ? transportadora.transporta.xNome
                    : null
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="17.5%"
                title="Frete por conta"
                value={
                  transportadora.modFrete === 0
                    ? "0 - Emitente"
                    : "1 - Destinatário"
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="12.5%"
                title="Código ANTT"
                value={
                  transportadora.veicTransp
                    ? transportadora.veicTransp.RNTC
                    : null
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="12.5%"
                title="Placa do Veículo"
                value={
                  transportadora.veicTransp
                    ? transportadora.veicTransp.placa
                    : null
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="5%"
                title="UF"
                value={
                  transportadora.transporta
                    ? transportadora.transporta.UF
                    : null
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="12.5%"
                title="CNPJ/CPF"
                value={
                  transportadora.transporta
                    ? formatarCnpj(transportadora.transporta.CNPJ)
                    : null
                }
                align="left"
                removeBottomBorder={true}
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="50%"
                title="Endereço"
                value={
                  transportadora.transporta
                    ? transportadora.transporta.xEnder
                    : null
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="22.5%"
                title="Município"
                value={
                  transportadora.transporta
                    ? transportadora.transporta.xMun
                    : null
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="5%"
                title="UF"
                value={
                  transportadora.transporta
                    ? transportadora.transporta.UF
                    : null
                }
                align="left"
                removeRightBorder={true}
                removeBottomBorder={true}
                styles={styles}
              />
              <Cell
                width="22.5%"
                title="Inscrição Estadual"
                value={
                  transportadora.transporta
                    ? formatarIE(transportadora.transporta.IE)
                    : null
                }
                align="left"
                removeBottomBorder={true}
                styles={styles}
              />
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
              }}
            >
              <Cell
                width="13.33%"
                title="Quantidade"
                value={transportadora.vol ? transportadora.vol.qVol : null}
                align="left"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Espécie"
                value={transportadora.vol ? transportadora.vol.esp : null}
                align="left"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Marca"
                value={transportadora.vol ? transportadora.vol.marca : null}
                align="left"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="20%"
                title="Numeração"
                value={transportadora.vol ? transportadora.vol.nVol : null}
                align="left"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="13.33%"
                title="Peso Bruto"
                value={transportadora.vol ? transportadora.vol.pesoB : null}
                align="left"
                removeRightBorder={true}
                styles={styles}
              />
              <Cell
                width="13.33%"
                title="Peso Líquido"
                value={transportadora.vol ? transportadora.vol.pesoL : null}
                align="left"
                styles={styles}
              />
            </View>

            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
                marginTop: "16px",
              }}
            >
              <Text
                style={{
                  ...styles.h4,
                }}
              >
                Dados do Produto/Serviço
              </Text>
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionColumn,
              }}
            >
              <View
                style={{
                  ...styles.displayFlex,
                  ...styles.flexDirectionRow,
                  width: "100%",
                }}
              >
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "6%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    Cod.
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    borderRight: "none",
                    justifyContent: "center",
                    width: "17%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    Desc. Prod/Serv
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "8%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    NCM SH
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "5.33%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    CST / CSOSN
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "5.33%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    CFOP
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "5.33%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    UN
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "8%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    Qtde.
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "8%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    Preço un
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "8%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    Preço total
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "8%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    BC ICMS / ST
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "6%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    Vlr ICMS / ST
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    justifyContent: "center",
                    borderRight: "none",
                    width: "6%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: styles.product.fontSize,
                      textAlign: "center",
                    }}
                  >
                    Vlr IPI / Dev
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.border,
                    ...styles.displayFlex,
                    ...styles.flexDirectionColumn,
                    justifyContent: "center",
                    width: "8%",
                  }}
                >
                  <View
                    style={{
                      ...styles.displayFlex,
                      borderBottom: styles.general.border,
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: styles.product.fontSize,
                        textAlign: "center",
                      }}
                    >
                      Alíquota
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.displayFlex,
                      ...styles.flexDirectionRow,
                    }}
                  >
                    <View
                      style={{
                        ...styles.displayFlex,
                        borderRight: styles.general.border,
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: styles.product.fontSize,
                          textAlign: "center",
                        }}
                      >
                        ICMS
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.displayFlex,
                        width: "50%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: styles.product.fontSize,
                          textAlign: "center",
                        }}
                      >
                        IPI
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              {produtos.map(
                (
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  produto: any,
                  idx: string | number
                ) => {
                  const prod = produto.prod;
                  const produtoImpostoIcms = getImpostoICMS(
                    produto.imposto.ICMS
                  );
                  const produtoImpostoIpi = produto.imposto.IPI?.IPITrib || {};
                  return (
                    <RowProduct
                      key={idx}
                      cod={prod.cProd}
                      descricao={prod.xProd}
                      ncm={prod.NCM}
                      cst={produtoImpostoIcms.CST || produtoImpostoIcms.CSOSN}
                      cfop={prod.CFOP}
                      unidade={prod.uTrib}
                      quantidade={prod.qTrib}
                      valor_unitario={prod.vUnTrib.toBRL()}
                      valor_total={prod.vProd.toBRL()}
                      base_calculo_icms={
                        produtoImpostoIcms.vBC ||
                        produtoImpostoIcms.vBCST ||
                        produtoImpostoIcms.vBCSTRet ||
                        produtoImpostoIcms.vBCSTDest ||
                        prod.vProd
                      }
                      valor_icms={
                        produtoImpostoIcms.vICMS ||
                        produtoImpostoIcms.vICMSST ||
                        produtoImpostoIcms.vICMSSTRet ||
                        produtoImpostoIcms.vICMSSTDest ||
                        produtoImpostoIcms.vCredICMSSN ||
                        0
                      }
                      valor_ipi={produtoImpostoIpi.vIPI || 0}
                      icms={
                        produtoImpostoIcms.pICMS ||
                        produtoImpostoIcms.pICMSST ||
                        produtoImpostoIcms.pICMSSTRet ||
                        produtoImpostoIcms.pCredSN
                      }
                      ipi={
                        produtoImpostoIpi.pIPI ||
                        produtoImpostoIpi.pIPIDevol ||
                        produtoImpostoIpi.pIPIDevolSN ||
                        0
                      }
                      styles={styles}
                      lastItem={idx === produtos.length - 1}
                    />
                  );
                }
              )}
            </View>

            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
                borderTop: styles.general.border,
                marginTop: "16px",
              }}
            >
              <Text
                style={{
                  ...styles.h4,
                }}
              >
                Dados adicionais
              </Text>
            </View>
            <View
              style={{
                ...styles.displayFlex,
                ...styles.flexDirectionRow,
                paddingBottom: 16,
              }}
            >
              <Cell
                width="60%"
                title="Informações complementares"
                value={observacao}
                align="left"
                removeRightBorder={true}
                minHeight={styles.inf_complementares.minHeight}
                styles={styles}
              />
              <Cell
                width="40%"
                title="RESERVADO AO FISCO"
                value=""
                align="left"
                minHeight={styles.inf_complementares.minHeight}
                styles={styles}
              />
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default CupomNfe;
