import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LayoutDefault } from "components";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

const LoginLayout: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <LayoutDefault solid_background>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={[
            isMobile
              ? {
                  height: "100%",
                }
              : {
                  height: "96%",
                },
          ]}
        >
          <Suspense>
            <Outlet />
          </Suspense>
        </Box>
        <Box
          sx={[
            {
              height: "4%",
              backgroundColor: "white",
              justifyContent: "center",
            },
            isMobile
              ? {
                  display: "none",
                }
              : {
                  display: "flex",
                },
          ]}
        >
          <Divider variant="fullWidth" orientation="vertical" />
          <Box ml={1} mr={1} display="flex" alignItems="center">
            <Typography variant="body2" color="#8a8d91">
              Copyright © {new Date().getFullYear()} Nuvel. All rights reserved.
            </Typography>
          </Box>
          <Divider variant="fullWidth" orientation="vertical" />
          <Box ml={1} mr={1} display="flex" alignItems="center">
            <Typography variant="body2" color="#8a8d91">
              Termos de Uso
            </Typography>
          </Box>
          <Divider variant="fullWidth" orientation="vertical" />
        </Box>
      </Box>
    </LayoutDefault>
  );
};

export default LoginLayout;
