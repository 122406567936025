import { SelectOption } from "components/nuvel/Select";

interface MotivoDeson {
  [key: number]: SelectOption[];
}

export const CST_PIS_COFINS_LIST = [
  {
    value: "01",
    label: "01 - Operação Tributável com Alíquota Básica",
  },
  {
    value: "02",
    label: "02 - Operação Tributável com Alíquota Diferenciada",
  },
  {
    value: "03",
    label:
      "03 - Operação Tributável com Alíquota por Unidade de Medida de Produto",
  },
  {
    value: "04",
    label: "04 - Operação Tributável Monofásica – Revenda a Alíquota Zero",
  },
  {
    value: "05",
    label: "05 - Operação Tributável por Substituição Tributária",
  },
  {
    value: "06",
    label: "06 - Operação Tributável a Alíquota Zero",
  },
  {
    value: "07",
    label: "07 - Operação Isenta da Contribuição",
  },
  {
    value: "08",
    label: "08 - Operação sem Incidência da Contribuição",
  },
  {
    value: "09",
    label: "09 - Operação com Suspensão da Contribuição",
  },
  {
    value: "49",
    label: "49 - Outras Operações de Saída",
  },
  {
    value: "50",
    label:
      "50 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
  },
  {
    value: "51",
    label:
      "51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
  },
  {
    value: "52",
    label:
      "52 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita de Exportação",
  },
  {
    value: "53",
    label:
      "53 - Operação com Direito a Crédito – Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
  },
  {
    value: "54",
    label:
      "54 - Operação com Direito a Crédito – Vinculada a Receitas Tributadas no Mercado",
  },
  {
    value: "55",
    label:
      "55 - Operação com Direito a Crédito – Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação",
  },
  {
    value: "56",
    label:
      "56 - Operação com Direito a Crédito – Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação",
  },
  {
    value: "60",
    label:
      "60 - Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
  },
  {
    value: "61",
    label:
      "61 - Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
  },
  {
    value: "62",
    label:
      "62 - Crédito Presumido – Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação",
  },
  {
    value: "63",
    label:
      "63 - Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
  },
  {
    value: "64",
    label:
      "64 - Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
  },
  {
    value: "65",
    label:
      "65 - Crédito Presumido – Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação",
  },
  {
    value: "66",
    label:
      "66 - Crédito Presumido – Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação",
  },
  {
    value: "67",
    label: "67 - Crédito Presumido – Outras Operações",
  },
  {
    value: "70",
    label: "70 - Operação de Aquisição sem Direito a Crédito",
  },
  {
    value: "71",
    label: "71 - Operação de Aquisição com Isenção",
  },
  {
    value: "72",
    label: "72 - Operação de Aquisição com Suspensão",
  },
  {
    value: "73",
    label: "73 - Operação de Aquisição a Alíquota Zero",
  },
  {
    value: "74",
    label: "74 - Operação de Aquisição sem Incidência da Contribuição",
  },
  {
    value: "75",
    label: "75 - Operação de Aquisição por Substituição Tributária",
  },
  {
    value: "98",
    label: "98 - Outras Operações de Entrada",
  },
  {
    value: "99",
    label: "99 - Outras Operações",
  },
];

export const CSOSN = [
  { value: "", label: "Nenhum" },
  {
    value: "101",
    label: "101 - Tributada pelo Simples Nacional com permissão de crédito",
  },
  {
    value: "102",
    label: "102 - Tributada pelo Simples Nacional sem permissão de crédito",
  },
  {
    value: "103",
    disabled: true,
    label:
      "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta",
  },
  {
    value: "201",
    label:
      "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "202",
    label:
      "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
  },
  {
    value: "203",
    disabled: true,
    label:
      "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
  },
  { value: "300", disabled: true, label: "300 - Imune" },
  {
    value: "400",
    label: "400 - Não tributada pelo Simples Nacional",
  },
  {
    value: "500",
    label:
      "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
  },
  { value: "900", label: "900 - Outro" },
];

export const CST_LIST = [
  { value: "", label: "Nenhum" },
  { value: "0", label: "00 - Tributada integralmente" },
  { value: "00", label: "00 - Tributada integralmente" },
  {
    value: "10",
    label: "10 - Tributada e com cobrança do ICMS por substituição tributária",
  },
  { value: "20", label: "20 - Com redução da BC" },
  {
    value: "30",
    disabled: true,
    label:
      "30 - Isenta / não tributada e com cobrança do ICMS por substituição tributária",
  },
  { value: "40", label: "40 - Isenta" },
  { value: "41", label: "41 - Não tributada" },
  { value: "50", disabled: true, label: "50 - Com suspensão" },
  { value: "51", disabled: true, label: "51 - Com diferimento" },
  {
    value: "60",
    label: "60 - ICMS cobrado anteriormente por substituição tributária",
  },
  {
    value: "61",
    label:
      "61 - Tributação Monofásica sobre Combustíveis cobrada anteriormente",
  },
  {
    value: "70",
    disabled: true,
    label:
      "70 - Com redução da BC e cobrança do ICMS por substituição tributária",
  },
  { value: "90", disabled: true, label: "90 - Outras" },
];

export const MOTIVO_DESONERACAO: MotivoDeson = {
  20: [
    { value: "6", label: "6 - Utilitários Motocicleta Área Livre" },
    { value: "7", label: "7 - SUFRAMA" },
    { value: "9", label: "9 - Outros" },
  ],
  30: [
    { value: "6", label: "6 - Utilitários Motocicleta Área Livre" },
    { value: "7", label: "7 - SUFRAMA" },
    { value: "9", label: "9 - Outros" },
  ],
  40: [
    {
      value: "9",
      label: "9 - Outros",
    },
  ],
  70: [
    { value: "3", label: "3 - Uso na agropecuária" },
    { value: "9", label: "9 - SUFRAMA" },
    { value: "12", label: "12 - Fomento agropecuário" },
  ],
  90: [
    { value: "3", label: "3 - Uso na agropecuária" },
    { value: "9", label: "9 - Outros" },
    { value: "12", label: "12 - Fomento agropecuário" },
  ],
};

export const MODALIDADE_BC_ICMS_ST = [
  {
    label: "Preço tabelado ou máximo sugerido",
    value: 0,
  },
  {
    label: "Lista Negativa (valor)",
    value: 1,
  },
  {
    label: "Lista Positiva (valor)",
    value: 2,
  },
  {
    label: "Lista Neutra (valor)",
    value: 3,
  },
  {
    label: "Margem Valor Agregado (%)",
    value: 4,
  },
  {
    label: "Pauta (valor)",
    value: 5,
  },
];

export const CST_IPI = [
  { value: "", label: "Nenhum" },
  { value: "0", label: "0 - Entrada com Recuperação de Crédito" },
  { value: "1", label: "1 - Entrada Tributável com Alíquota Zero" },
  { value: "2", label: "2 - Entrada Isenta" },
  { value: "3", label: "3 - Entrada Não-Tributada" },
  { value: "4", label: "4 - Entrada Imune" },
  { value: "5", label: "5 - Entrada com Suspensão" },
  { value: "49", label: "49 - Outras Entradas" },
  { value: "50", label: "50 - Saída Tributada" },
  { value: "51", label: "51 - Saída Tributável com Alíquota Zero" },
  { value: "52", label: "52 - Saída Isenta" },
  { value: "53", label: "53 - Saída Não-Tributada" },
  { value: "54", label: "54 - Saída Imune" },
  { value: "55", label: "55 - Saída com Suspensão" },
  { value: "99", label: "99 - Outras Saídas" },
];

export const UF_LIST = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

// Nome ; Tipo (R = Real, P = Porcentagem, I = Inteiro)
export const CAMPOS_IMPOSTO_TO_VALID_NOME = {
  cod_icms: "Cód. ICMS;I",
  v_icms: "Valor ICMS;R",
  p_icms: "Alíquota ICMS;P",
  v_fcp: "Valor FCP;R",
  p_fcp: "Alíquota FCP;P",
  v_icms_deson: "Valor ICMS Deson;R",
  modalidade_bc_st: "Modalidade BC ST;I",
  v_icms_st: "Valor ICMS ST;R",
  v_bc_icms_st: "BC ICMS ST;R",
  p_icms_st: "Alíquota ICMS ST;P",
  v_fcp_st: "Valor FCP ST;R",
  p_fcp_st: "Alíquota FCP ST;P",
  v_bc_fcp_st: "BC FCP ST;R",
  p_icms_st_ret: "Aliq ICMS ST Retido;P",
  v_icms_st_ret: "Valor ICMS ST Retido;R",
  v_bc_icms_st_ret: "BC ICMS ST Retido;R",
  p_fcp_st_ret: "Aliq FCP ST Retido;P",
  v_fcp_st_ret: "Valor FCP ST Retido;R",
  v_bc_fcp_st_ret: "BC FCP ST Retido;R",
  q_bc_mono_ret: "Quantidade BC Mono Ret;R",
  ad_rem_icms_ret: "Adicionais Rem ICMS Ret;R",
  v_icms_mono_ret: "Valor ICMS Mono Ret;R",
  v_icms_credito: "Valor ICMS Crédito;R",
  base_calculo: "Base de Cálculo;R",
  fat_red: "Fator de Redução da BC;P",
  v_bc_ipi: "BC IPI;R",
  p_ipi: "Alíquota IPI;P",
  v_ipi: "Valor IPI;R",
  v_ipi_devol: "Valor IPI Devolvido;R",
};
