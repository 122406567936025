import React, { useEffect } from "react";
import Home from "./views/home";
import Cart from "./views/cart";
import { useCatalogActions, useCatalog } from "./context";

interface ViewsWrapperProps {
  route: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  catalogo_data: any;
}

const ViewsWrapper: React.FC<ViewsWrapperProps> = ({
  catalogo_data,
  route,
}) => {
  const { loadStoredCatalogData } = useCatalogActions();
  const { state } = useCatalog();

  console.log(state);

  useEffect(() => {
    if (catalogo_data) {
      loadStoredCatalogData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogo_data]);

  if (!state.departments || !state.products) {
    return null;
  }

  return (
    <>
      {route === 0 && <Home />}
      {route === 1 && <Cart />}
    </>
  );
};

export default ViewsWrapper;
