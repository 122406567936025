import { Box, Button } from "@mui/material";
import React from "react";
import { NavigateFunction } from "react-router-dom";

interface FormsButtonsProps {
  navigate: NavigateFunction;
  extraButtons?: React.ReactNode;
  extraButtonsPosition?: "left" | "right";
}

const FormsButtons: React.FC<FormsButtonsProps> = ({
  navigate,
  extraButtons,
  extraButtonsPosition = "right",
}) => {
  return (
    <Box
      pt={2}
      sx={{ height: "52.5px" }}
      display="flex"
      justifyContent="space-between"
    >
      <Button
        variant="contained"
        onClick={() => {
          if (window.history.length > 1) {
            navigate(-1);
          } else {
            const newUrl = window.location.pathname
              .split("/")
              .slice(0, -1)
              .join("/");
            navigate(newUrl);
          }
        }}
      >
        Voltar
      </Button>
      <Box display="flex" gap={1}>
        <Button variant="contained" type="submit">
          Salvar
        </Button>
        {extraButtonsPosition === "right" && extraButtons}
      </Box>
    </Box>
  );
};

export default FormsButtons;
