/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  TextField,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Grid,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { mmToPixels, pixelsToMm } from "./utils";
import ElementoEtiqueta from "./ElementoEtiqueta";
import PainelPropriedades from "./PainelPropriedades";
import { ElementoBase, Template, EtiquetaEditorProps, Produto } from "./types";
import EtiquetaGrid from "./EtiquetaGrid";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "hooks";
import { EtiquetasModel } from "data/models";
import PrintIcon from "@mui/icons-material/Print";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { textToBase64Barcode } from "../../../utils/functions/textToBase64Barcode";

const elementosDisponiveis = [
  { tipo: "texto", nome: "Texto (Nome do Produto)", campo: "produto_nome" },
  { tipo: "codigoBarras", nome: "Código de Barras", campo: "cod_barra" },
  { tipo: "preco", nome: "Preço", campo: "preco_venda" },
];

// Tamanhos predefinidos em mm
const tamanhosPreDefinidos = [
  { id: "personalizado", nome: "Personalizado", largura: 0, altura: 0 },
  { id: "pequena", nome: "Pequena (30x12mm)", largura: 30, altura: 12 },
  { id: "media", nome: "Média (50x30mm)", largura: 50, altura: 30 },
  { id: "grande", nome: "Grande (100x50mm)", largura: 100, altura: 50 },
];

const DPMM = 8;

const EtiquetaEditor: React.FC<EtiquetaEditorProps> = ({
  produtoExemplo: produtoInicial,
}) => {
  const [template, setTemplate] = useState<Template>({
    id: "1",
    nome: "Novo Template",
    largura: mmToPixels(100, DPMM),
    altura: mmToPixels(50, DPMM),
    dpmm: DPMM,
    elementos: [],
    colunas: 1,
    espacamentoColunas: 2,
  });
  const [elementoSelecionado, setElementoSelecionado] =
    useState<ElementoBase | null>(null);
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState("personalizado");
  const [produtoExemplo, setProdutoExemplo] = useState<Produto | undefined>(
    produtoInicial
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [mostrarGrid, setMostrarGrid] = useState(true);
  const [gridSize] = useState(10);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [templateNome, setTemplateNome] = useState("");
  const [templateDescricao, setTemplateDescricao] = useState("");
  const [salvando, setSalvando] = useState(false);
  const navigate = useNavigate();
  const { showSnack } = useAppContext();

  const { id } = useParams();

  useEffect(() => {
    const carregarTemplate = async () => {
      if (!id) return;
      if (id === "novo") return;

      try {
        const res = await EtiquetasModel.get(Number(id));

        // Converte as dimensões de mm para pixels
        const novoTemplate = {
          id: res.id,
          nome: res.nome,
          largura: mmToPixels(
            res.configuracao.dimensoes.largura,
            res.configuracao.dimensoes.dpmm
          ),
          altura: mmToPixels(
            res.configuracao.dimensoes.altura,
            res.configuracao.dimensoes.dpmm
          ),
          dpmm: res.configuracao.dimensoes.dpmm,
          elementos: res.configuracao.elementos.map((elem: ElementoBase) => ({
            ...elem,
            posicao: {
              x: mmToPixels(elem.posicao.x, res.configuracao.dimensoes.dpmm),
              y: mmToPixels(elem.posicao.y, res.configuracao.dimensoes.dpmm),
            },
            tamanho: {
              width: mmToPixels(
                elem.tamanho?.width || 100,
                res.configuracao.dimensoes.dpmm
              ),
              height: mmToPixels(
                elem.tamanho?.height || 30,
                res.configuracao.dimensoes.dpmm
              ),
            },
          })),
          colunas: res.configuracao.colunas,
          espacamentoColunas: res.configuracao.espacamentoColunas,
        };

        setTemplate(novoTemplate);
        setTemplateNome(res.nome);
        setTemplateDescricao(res.descricao || "");
      } catch (error) {
        console.error("Erro ao carregar template:", error);
        showSnack("Erro ao carregar template", 2, "error");
      }
    };

    carregarTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleTamanhoChange = (tamanhoId: string) => {
    setTamanhoSelecionado(tamanhoId);
    const tamanho = tamanhosPreDefinidos.find((t) => t.id === tamanhoId);
    if (tamanho && tamanhoId !== "personalizado") {
      setTemplate((prev) => ({
        ...prev,
        largura: mmToPixels(tamanho.largura, prev.dpmm),
        altura: mmToPixels(tamanho.altura, prev.dpmm),
      }));
    }
  };

  const handleDimensaoChange = (
    dimensao: "largura" | "altura",
    valorMm: number
  ) => {
    setTemplate((prev) => ({
      ...prev,
      [dimensao]: mmToPixels(valorMm, prev.dpmm),
    }));
    setTamanhoSelecionado("personalizado");
  };

  const removerElemento = (elementoId: string) => {
    setTemplate((prev) => ({
      ...prev,
      elementos: prev.elementos.filter((elem) => elem.id !== elementoId),
    }));
    if (elementoSelecionado?.id === elementoId) {
      setElementoSelecionado(null);
    }
  };

  const adicionarElemento = (tipo: string) => {
    const novoElemento: ElementoBase = {
      id: `elemento-${template.elementos.length + 1}`,
      tipo: tipo as "texto" | "codigoBarras" | "preco",
      campo: elementosDisponiveis.find((e) => e.tipo === tipo)?.campo || "",
      posicao: { x: 10, y: 10 },
      estilo: {
        fontSize: 12,
        textAlign: "left",
      },
    };

    setTemplate({
      ...template,
      elementos: [...template.elementos, novoElemento],
    });
  };

  const atualizarElemento = (elementoAtualizado: ElementoBase) => {
    setTemplate({
      ...template,
      elementos: template.elementos.map((elem) =>
        elem.id === elementoAtualizado.id ? elementoAtualizado : elem
      ),
    });
    setElementoSelecionado(elementoAtualizado);
  };

  const handleResize = (elementoId: string, width: number, height: number) => {
    setTemplate({
      ...template,
      elementos: template.elementos.map((elem) =>
        elem.id === elementoId
          ? {
              ...elem,
              tamanho: { width, height },
            }
          : elem
      ),
    });
  };

  // Função para limpar seleção
  const limparSelecao = () => {
    setElementoSelecionado(null);
  };

  // Event listener para a tecla ESC
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        limparSelecao();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Função para clicar fora e limpar seleção
  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      limparSelecao();
    }
  };

  const handleSaveTemplate = async () => {
    try {
      setSalvando(true);

      const templateData = {
        id: Number(id),
        nome: templateNome,
        descricao: templateDescricao,
        ativo: true,
        configuracao: {
          dimensoes: {
            largura: pixelsToMm(template.largura, template.dpmm),
            altura: pixelsToMm(template.altura, template.dpmm),
            dpmm: template.dpmm,
          },
          colunas: template.colunas,
          espacamentoColunas: template.espacamentoColunas,
          elementos: template.elementos.map((elem) => ({
            ...elem,
            tamanho: {
              width: pixelsToMm(elem.tamanho?.width || 100, template.dpmm),
              height: pixelsToMm(elem.tamanho?.height || 30, template.dpmm),
            },
            posicao: {
              x: pixelsToMm(elem.posicao.x, template.dpmm),
              y: pixelsToMm(elem.posicao.y, template.dpmm),
            },
          })),
        },
      };

      await EtiquetasModel.save(templateData);
      showSnack("Template salvo com sucesso!", 2, "success");
      navigate(-1);
      setOpenSaveDialog(false);
    } catch (error) {
      showSnack("Erro ao salvar template", 2, "error");
    } finally {
      setSalvando(false);
    }
  };

  const renderizarEtiquetaLimpa = (produto: Produto, template: Template) => {
    const container = document.createElement("div");
    container.style.cssText = `
      width: ${template.largura}px;
      height: ${template.altura}px;
      position: relative;
      background-color: #ffffff;
      overflow: hidden;
      flex-shrink: 0;
    `;

    const fragmento = document.createDocumentFragment();

    template.elementos.forEach((elemento) => {
      const div = document.createElement("div");
      div.style.cssText = `
        position: absolute;
        left: ${elemento.posicao.x}px;
        top: ${elemento.posicao.y}px;
        width: ${elemento.tamanho?.width || 100}px;
        height: ${elemento.tamanho?.height || 30}px;
        overflow: hidden;
        font-size: ${elemento.estilo?.fontSize || 12}px;
        text-align: ${elemento.estilo?.textAlign || "left"};
        font-weight: ${elemento.estilo?.fontWeight || "normal"};
      `;

      switch (elemento.tipo) {
        case "texto":
          div.textContent = String(
            produto[elemento.campo as keyof Produto] || ""
          );
          break;
        case "preco": {
          const preco = Number(produto[elemento.campo as keyof Produto] || 0);
          div.textContent = preco.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
          break;
        }
        case "codigoBarras": {
          const img = new Image();
          img.src = textToBase64Barcode(
            String(produto[elemento.campo as keyof Produto] || ""),
            12,
            30
          );
          img.style.cssText = "width: 100%; height: 100%; object-fit: contain;";
          div.appendChild(img);
          break;
        }
      }

      fragmento.appendChild(div);
    });

    container.appendChild(fragmento);
    return container;
  };

  const handlePrintTest = async () => {
    if (!produtoExemplo) {
      showSnack("Selecione um produto para teste", 2, "warning");
      return;
    }

    try {
      // Criar container principal que vai conter todas as colunas
      const containerPrincipal = document.createElement("div");
      containerPrincipal.style.display = "flex";
      containerPrincipal.style.width = `${
        template.largura * template.colunas +
        template.espacamentoColunas * template.dpmm * (template.colunas - 1)
      }px`;

      // Criar as colunas com espaçamento
      for (let i = 0; i < template.colunas; i++) {
        const etiquetaLimpa = renderizarEtiquetaLimpa(produtoExemplo, template);
        containerPrincipal.appendChild(etiquetaLimpa);

        // Adiciona espaçamento após cada etiqueta, exceto a última
        if (i < template.colunas - 1) {
          const containerSpacing = document.createElement("div");
          containerSpacing.style.width = `${Math.round(
            template.espacamentoColunas * template.dpmm
          )}px`;
          // containerSpacing.style.backgroundColor = "red";
          containerSpacing.style.height = `${template.altura}px`;
          containerPrincipal.appendChild(containerSpacing);
        }
      }

      document.body.appendChild(containerPrincipal);

      const canvas = await html2canvas(containerPrincipal, {
        scale: template.dpmm,
        backgroundColor: "#ffffff",
        logging: false,
        useCORS: true,
        allowTaint: true,
        imageTimeout: 0,
        width: containerPrincipal.offsetWidth,
        height: containerPrincipal.offsetHeight,
        onclone: (clonedDoc) => {
          const elements = clonedDoc.getElementsByTagName("*");
          for (let i = 0; i < elements.length; i++) {
            const element = elements[i] as HTMLElement;
            if (element.style) {
              element.style.transform = "none";
            }
          }
        },
      });

      document.body.removeChild(containerPrincipal);

      // Calcular dimensões em milímetros
      const larguraEtiquetaMm = pixelsToMm(template.largura, template.dpmm);
      const alturaEtiquetaMm = pixelsToMm(template.altura, template.dpmm);
      const espacamentoMm = template.espacamentoColunas;

      // Calcular largura total em milímetros
      const larguraTotalMm =
        larguraEtiquetaMm * template.colunas +
        espacamentoMm * (template.colunas - 1);

      const pdf = new jsPDF({
        orientation:
          larguraTotalMm > alturaEtiquetaMm ? "landscape" : "portrait",
        unit: "mm",
        format: [larguraTotalMm, alturaEtiquetaMm],
        compress: true,
      });

      pdf.addImage(
        canvas.toDataURL("image/jpeg", 0.8),
        "JPEG",
        0,
        0,
        larguraTotalMm,
        alturaEtiquetaMm,
        undefined,
        "FAST"
      );

      pdf.autoPrint();
      pdf.output("dataurlnewwindow");
    } catch (error) {
      console.error("Erro ao gerar impressão:", error);
      showSnack("Erro ao gerar impressão", 2, "error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
        height: "calc(100vh - 100px)",
      }}
    >
      {/* Cabeçalho com botões */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Editor de Etiquetas</Typography>
        <Box>
          <Button
            sx={{ mr: 1 }}
            variant="contained"
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
          <Button
            sx={{ mr: 1 }}
            variant="contained"
            color="secondary"
            startIcon={<PrintIcon />}
            onClick={handlePrintTest}
            disabled={!produtoExemplo}
          >
            Imprimir Teste
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={() => setOpenSaveDialog(true)}
          >
            Salvar Template
          </Button>
        </Box>
      </Box>

      {/* Seleção de produto exemplo */}
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Produto para Preview</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <NuvelAutocomplete
              label="Selecione um produto"
              name="produto_exemplo"
              onChange={(_e, _v, r) => {
                if (r) {
                  setProdutoExemplo({
                    ...r,
                    produto_nome: r.produto?.nome,
                    preco_venda: Number(r.produto?.preco_venda),
                  });
                }
              }}
              reference="etiqueta_em_lote"
              value={produtoExemplo?.id}
              clearAfterSelect={false}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Área principal */}
      <Box sx={{ display: "flex", gap: 2, flex: 1 }}>
        {/* Painel de elementos e configurações */}
        <Paper sx={{ width: 250, p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Configurações da Etiqueta
          </Typography>

          {/* Configurações de tamanho */}
          <Box sx={{ mb: 3 }}>
            <FormControl fullWidth size="small" sx={{ mb: 2 }}>
              <InputLabel>Tamanho Predefinido</InputLabel>
              <Select
                value={tamanhoSelecionado}
                label="Tamanho Predefinido"
                onChange={(e) => handleTamanhoChange(e.target.value)}
              >
                {tamanhosPreDefinidos.map((tamanho) => (
                  <MenuItem key={tamanho.id} value={tamanho.id}>
                    {tamanho.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              size="small"
              label="DPMM"
              type="number"
              value={template.dpmm}
              onChange={(e) =>
                setTemplate({
                  ...template,
                  dpmm: Number(e.target.value),
                })
              }
              sx={{ mb: 1 }}
            />
            <TextField
              fullWidth
              size="small"
              label="Largura (mm)"
              type="number"
              value={pixelsToMm(template.largura, template.dpmm)}
              onChange={(e) =>
                handleDimensaoChange("largura", Number(e.target.value))
              }
              sx={{ mb: 1 }}
            />
            <TextField
              fullWidth
              size="small"
              label="Altura (mm)"
              type="number"
              value={pixelsToMm(template.altura, template.dpmm)}
              onChange={(e) =>
                handleDimensaoChange("altura", Number(e.target.value))
              }
              sx={{ mb: 1 }}
            />

            <TextField
              fullWidth
              size="small"
              label="Número de Colunas"
              type="number"
              value={template.colunas}
              onChange={(e) =>
                setTemplate((prev) => ({
                  ...prev,
                  colunas: Math.max(1, Math.min(10, Number(e.target.value))),
                }))
              }
              inputProps={{ min: 1, max: 10 }}
              sx={{ mb: 1 }}
            />

            {template.colunas > 1 && (
              <TextField
                fullWidth
                size="small"
                label="Espaçamento (mm)"
                type="number"
                value={template.espacamentoColunas}
                onChange={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                    espacamentoColunas: Math.max(0, Number(e.target.value)),
                  }))
                }
                inputProps={{ min: 0, step: 0.5 }}
              />
            )}
          </Box>

          <Divider sx={{ my: 2 }} />

          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Elementos Disponíveis
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {elementosDisponiveis.map((elem) => (
              <Button
                key={elem.tipo}
                variant="contained"
                onClick={() => adicionarElemento(elem.tipo)}
                fullWidth
                size="small"
              >
                {elem.nome}
              </Button>
            ))}
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={mostrarGrid}
                onChange={(e) => setMostrarGrid(e.target.checked)}
              />
            }
            label="Mostrar Grade"
          />
        </Paper>

        {/* Área de edição */}
        <Paper
          sx={{
            flex: 1,
            position: "relative",
            height: 500,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f5f5f5",
          }}
          onClick={handleBackgroundClick}
        >
          <Box
            ref={containerRef}
            sx={{
              position: "relative",
              width: template.largura,
              height: template.altura,
              backgroundColor: "white",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              transform: `scale(${Math.min(
                450 / template.largura,
                400 / template.altura,
                1
              )})`,
            }}
          >
            {mostrarGrid && (
              <EtiquetaGrid
                width={template.largura}
                height={template.altura}
                spacing={gridSize}
              />
            )}
            {template.elementos.map((elemento) => (
              <ElementoEtiqueta
                key={elemento.id}
                elemento={elemento}
                selecionado={elementoSelecionado?.id === elemento.id}
                onClick={() => setElementoSelecionado(elemento)}
                onDragEnd={(x, y) =>
                  atualizarElemento({
                    ...elemento,
                    posicao: { x, y },
                  })
                }
                onResize={(width, height) =>
                  handleResize(elemento.id, width, height)
                }
                containerRef={containerRef}
                produtoExemplo={produtoExemplo}
                gridSize={gridSize}
              />
            ))}
          </Box>
        </Paper>

        {/* Painel de propriedades */}
        <Paper sx={{ width: 250 }}>
          <Box
            sx={{
              p: 2,
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Propriedades do Elemento</Typography>
            {elementoSelecionado && (
              <Box sx={{ display: "flex", gap: 1 }}>
                <Tooltip title="Remover elemento">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => removerElemento(elementoSelecionado.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancelar seleção (ESC)">
                  <IconButton size="small" onClick={limparSelecao}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          <PainelPropriedades
            elemento={elementoSelecionado}
            onUpdate={atualizarElemento}
          />
        </Paper>
      </Box>

      {/* Dialog de salvamento */}
      <Dialog open={openSaveDialog} onClose={() => setOpenSaveDialog(false)}>
        <DialogTitle>Salvar Template de Etiqueta</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              fullWidth
              label="Nome do Template"
              value={templateNome}
              onChange={(e) => setTemplateNome(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Descrição"
              value={templateDescricao}
              onChange={(e) => setTemplateDescricao(e.target.value)}
              multiline
              rows={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSaveDialog(false)} disabled={salvando}>
            Cancelar
          </Button>
          <Button
            onClick={handleSaveTemplate}
            variant="contained"
            disabled={!templateNome || salvando}
          >
            {salvando ? "Salvando..." : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EtiquetaEditor;
