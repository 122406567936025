export const downloadXml = (url: string) => {
  url = url.replace("http://", "https://");
  const fileName = url.substring(url.lastIndexOf("/") + 1);
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Erro ao baixar o arquivo XML");
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    })
    .catch((error) => {
      console.error("Ocorreu um erro:", error);
    });
};
