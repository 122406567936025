import React from "react";
import {
  Fab,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  styled,
  Menu,
} from "@mui/material";
import { Add, ExpandMore, MoreHoriz, MoreVert } from "@mui/icons-material";

const AbsoluteFab = styled(Fab)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(3),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

interface NuvelSimplePopperProps {
  options: {
    label: string;
    Icon?: React.ElementType;
    disabled?: boolean;
    function?: (e: React.MouseEvent<HTMLElement>) => void;
  }[];
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "warning";
  label: string;
  type?: "lateral" | "vertical" | "fab";
  lbExpand?: boolean;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

export default function NuvelSimplePopper({
  options,
  color = "primary",
  label,
  type,
  lbExpand,
  startIcon,
  disabled,
  fullWidth,
  onClick,
}: NuvelSimplePopperProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <>
      {type === "lateral" ? (
        <StyledIconButton
          size="small"
          disabled={disabled ? true : false}
          onClick={handleClick}
        >
          <MoreHoriz />
        </StyledIconButton>
      ) : type === "vertical" ? (
        <StyledIconButton
          size="small"
          disabled={disabled ? true : false}
          onClick={handleClick}
        >
          <MoreVert />
        </StyledIconButton>
      ) : type === "fab" ? (
        <Tooltip title={label} aria-label={label}>
          <AbsoluteFab
            disabled={disabled ? true : false}
            onClick={handleClick}
            color="primary"
            aria-label={label}
          >
            <Add />
          </AbsoluteFab>
        </Tooltip>
      ) : (
        <Button
          disabled={disabled ? true : false}
          startIcon={startIcon ? startIcon : null}
          color={color}
          onClick={handleClick}
          style={
            fullWidth ? { marginLeft: 10, width: "100%" } : { marginLeft: 10 }
          }
        >
          {label}{" "}
          {lbExpand ? null : (
            <>
              {" "}
              &nbsp;|
              <ExpandMore />{" "}
            </>
          )}
        </Button>
      )}
      <Menu
        id={id}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        style={{ zIndex: 9999999 }}
        onKeyDown={handleListKeyDown}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {options ? (
          options.map((item, idx) => {
            return (
              <MenuItem
                disabled={item.disabled ? true : false}
                key={idx}
                onClick={
                  item.function
                    ? (e) => {
                        if (item.function) {
                          item.function(e);
                        }
                        handleClick(e);
                        if (onClick) {
                          onClick();
                        }
                      }
                    : handleClick
                }
              >
                {item.Icon ? <item.Icon style={{ marginRight: 8 }} /> : null}
                {item.label}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem onClick={handleClick}>Sem Opções</MenuItem>
        )}
      </Menu>
    </>
  );
}
