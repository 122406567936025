/* eslint-disable @typescript-eslint/no-explicit-any */
import FilialSelection from "components/legacy/FilialTable";
import NuvelAutocomplete from "components/nuvel/Autocomplete";
import { NuvelDefaultForm } from "components/nuvel/forms";
import NuvelGrid from "components/nuvel/Grid";
import NuvelTextField from "components/nuvel/TextField";
import { UserModel } from "data/models";
import React from "react";
import { handleChange } from "utils/functions";

function RetUserForm() {
  const [state, setState] = React.useState<any>({
    id: null,
    usuario: "",
    email: "",
    password: "",
    confirm_Password: "",
    acesso: null,
  });

  return (
    <NuvelDefaultForm setState={setState} state={state} model={UserModel}>
      <NuvelGrid container spacing={2}>
        <NuvelGrid xs={12}>
          <NuvelTextField
            label="Usuário"
            onChange={(e) => {
              setState((v: any) => ({
                ...v,
                usuario: e.target.value,
              }));
            }}
            name="usuario"
            value={state.usuario}
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <NuvelTextField
            label="Email"
            onChange={(e) => handleChange(e, setState)}
            name="email"
            value={state.email}
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <NuvelTextField
            label="Senha"
            name="password"
            value={state.password}
            onChange={(e) => handleChange(e, setState)}
            error={state.password !== state.confirm_Password ? true : false}
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <NuvelTextField
            label="Confirmar Senha"
            name="confirm_Password"
            value={state.confirm_Password}
            onChange={(e) => handleChange(e, setState)}
            type="password"
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <NuvelAutocomplete
            label="Grupo de Permissões"
            name="acesso"
            value={Number(state.acesso)}
            onChange={(e) => {
              setState((v: any) => ({
                ...v,
                acesso: Number(e.target.value) || "",
              }));
            }}
            reference="acesso"
          />
        </NuvelGrid>
        <NuvelGrid xs={12}>
          <FilialSelection getter={state} setter={setState} />
        </NuvelGrid>
      </NuvelGrid>
    </NuvelDefaultForm>
  );
}

export default RetUserForm;
