import {
  Box,
  Divider,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { Add, Delete, Remove } from "@mui/icons-material";
import React from "react";
import { Product, useCatalog, useCatalogActions } from "../../context";
import PlaceholderImage from "../PlaceholderImage";

const CartItem: React.FC<{ product: Product }> = ({ product }) => {
  const { addToCart, removeFromCart } = useCatalogActions();
  const theme = useTheme();
  const disableQuantity = product.quantity === 1;

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box
          width={theme.spacing(10)}
          height={theme.spacing(10)}
          borderRadius={1}
          overflow="hidden"
        >
          {product.image ? (
            <img
              src={product.image}
              alt={product.name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <PlaceholderImage text={product.name} width="100%" height="100%" />
          )}
        </Box>

        <Box ml={2} flexGrow={1}>
          <Typography variant="h6">{product.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {product.price.toBRL()} cada
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" gap={1} ml={2}>
          {product.quantity === 1 ? (
            <IconButton
              onClick={() => removeFromCart(product.id)}
              sx={{
                border: (theme) => `1px solid ${theme.palette.error.main}`,
                color: (theme) => theme.palette.error.main,
              }}
            >
              <Delete />
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                addToCart({ ...product, quantity: product.quantity - 1 })
              }
              sx={{
                border: (theme) => `1px solid ${theme.palette.error.main}`,
                color: (theme) => theme.palette.error.main,
              }}
              disabled={disableQuantity}
            >
              <Remove />
            </IconButton>
          )}

          <Typography
            sx={{
              minWidth: theme.spacing(4),
              textAlign: "center",
            }}
          >
            {product.quantity}
          </Typography>

          <IconButton
            onClick={() =>
              addToCart({ ...product, quantity: product.quantity + 1 })
            }
            sx={{
              border: (theme) => `1px solid ${theme.palette.success.main}`,
              color: (theme) => theme.palette.success.main,
            }}
          >
            <Add />
          </IconButton>
        </Box>
      </Box>

      <Typography
        variant="body2"
        color="primary"
        sx={{
          mt: 1,
          textAlign: "right",
        }}
      >
        Subtotal: {(product.price * product.quantity).toBRL()}
      </Typography>
    </Box>
  );
};

export const CartItemsList: React.FC = () => {
  const { state } = useCatalog();

  if (state.cart.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <Typography variant="h6" color="text.secondary">
          Seu carrinho está vazio
        </Typography>
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography variant="h6" mb={2}>
          Itens do carrinho
        </Typography>

        <Box>
          {state.cart.map((product, index) => (
            <Box key={product.id} width="100%">
              <CartItem product={product} />
              {index < state.cart.length - 1 && <Divider sx={{ my: 2 }} />}
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};
