import React from "react";

import Filters from "./filtros";
import ListNotas from "./list";

import DialogNota from "./dialogs/criacao";
import DialogItem from "./dialogs/item";

import produtos from "data/models/estoque/produto";
import nota_de_saida from "data/models/fiscal/nota_de_saida";
import { filtrosState, incluirItemState, incluirState } from "./states";
import dayjs from "dayjs";
import { Divider } from "@mui/material";
import { Box } from "@mui/material";
import Paper from "components/nuvel/Paper";

function Form_NotaDeSaida() {
  // STATE TELA DE INCLUSÃO / EDIÇÃO = ID MODAL => 1
  const [stateNota, setStateNota] = React.useState(incluirState);
  // STATE TELA DE INCLUSÃO / EDIÇÃO = ID MODAL => 1
  const [stateNotaItem, setStateNotaItem] = React.useState(incluirItemState);
  // STATE FILTROS
  const [filtros, setFiltros] = React.useState(filtrosState);
  // Page
  const [page, setPage] = React.useState(1);

  const { data: listNota, mutate: refreshNota } = nota_de_saida.useModel({
    cliente: filtros.cliente ? filtros.cliente : undefined,
    page: page,
    data_emissao__gte: dayjs(filtros.data_inicial).format(
      "YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"
    ),
    data_emissao__lte: dayjs(filtros.data_final).format(
      "YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]"
    ),
    numero__icontains: filtros.numero ? filtros.numero : undefined,
  });

  const [modal, setModal] = React.useState({
    open: false,
    id: 0,
  });
  const [modalSec, setSecModal] = React.useState({
    open: false,
    id: 0,
  });

  const handleModal = (id: number) => {
    setModal((_v) => ({
      open: _v.id === id ? !modal.open : true,
      id: id,
    }));
  };
  const handleSecModal = (id: number) => {
    setSecModal((_v) => ({
      open: _v.id === id ? !modalSec.open : true,
      id: id,
    }));
  };
  const { data: listProds } = produtos.useModel({
    id__in: stateNota.itens.map((x) => x.produto).join(","),
    page_size: 9999,
  });

  return (
    <>
      <Paper square>
        <Box p={2}>
          <Filters state={filtros} setState={setFiltros} />
        </Box>
        <Divider />
        <ListNotas
          listNota={listNota || { rows: [], totalCount: 0 }}
          handleModal={handleModal}
          refreshNota={refreshNota}
          setStateNota={setStateNota}
          setPage={{
            page: page,
            setPage: setPage,
            totalCount: listNota?.totalCount || 0,
            pageSize: 10,
          }}
        />
      </Paper>

      <DialogNota
        handleModal={handleModal}
        modal={modal}
        setStateNota={setStateNota}
        stateNota={stateNota}
        setStateNotaItem={setStateNotaItem}
        handleSecModal={handleSecModal}
        refreshNota={refreshNota}
        listProds={listProds || { rows: [] }}
      />

      <DialogItem
        modalSec={modalSec}
        handleSecModal={handleSecModal}
        stateNotaItem={stateNotaItem}
        setStateNotaItem={setStateNotaItem}
        setStateNota={setStateNota}
        stateNota={stateNota}
        listProds={listProds || { rows: [] }}
      />
    </>
  );
}

export default Form_NotaDeSaida;
