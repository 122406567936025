import dayjs from "dayjs";
import { NotaDeSaidaItensSerializer } from "./NotaDeSaidaItensSerializer.ts";

export enum NotaDeSaidaSerializerFinalidadeEnum {
  "NF-e_Normal" = "1",
  "NF-e_complementar" = "2",
  "_NF-e_de_ajuste" = "3",
  "Devolução/Retorno" = "4",
}

export enum NotaDeSaidaSerializerModeloEnum {
  "NF-e" = "55",
}

export enum NotaDeSaidaSerializerPresencaEnum {
  "_Não_se_aplica_(por_exemplo,_para_a_Nota_Fiscal_complementar_ou_de_ajuste)" = "0",
  "Operação_presencial" = "1",
  "Operação_não_presencial,_pela_Internet." = "2",
  "Operação_não_presencial,_Teleatendimento." = "3",
  "NFC-e_em_operação_com_entrega_em_domicílio." = "4",
  "Operação_presencial,_fora_do_estabelecimento." = "5",
  "Operação_não_presencial,_outros." = "6",
}

export enum NotaDeSaidaSerializerStatusEnum {
  Criada = 0,
  Excluída = 1,
  Emitida = 2,
  Com_erro = 3,
  Cancelada = 4,
}

export enum NotaDeSaidaSerializerTipoEnum {
  Entrada = 0,
  Saída = 1,
}

export enum NotaDeSaidaSerializerTransportador_freteEnum {
  Emitente = 0,
  Destinatário = 1,
  Terceiros = 2,
  Sem_Frete = 9,
}

export interface NotaDeSaidaSerializer {
  id?: number;
  itens: NotaDeSaidaItensSerializer[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json?: any;
  xml?: string;
  codigo?: number;
  data_inclusao?: dayjs.Dayjs;
  data_alteracao?: dayjs.Dayjs;
  chave?: string;
  numero?: number | string;
  serie?: number | string;
  modelo: NotaDeSaidaSerializerModeloEnum;
  finalidade: NotaDeSaidaSerializerFinalidadeEnum;
  natureza_operacao: string;
  natureza_operacao_desc: string;
  tipo: NotaDeSaidaSerializerTipoEnum;
  presenca: NotaDeSaidaSerializerPresencaEnum;
  data_emissao?: dayjs.Dayjs;
  data_saida?: dayjs.Dayjs;
  transportador_frete: NotaDeSaidaSerializerTransportador_freteEnum;
  transportador_placa: string;
  transportador_uf: string;
  transportador_quantidade: number;
  transportador_especie: string;
  transportador_marca: string;
  transportador_numero: string;
  transportador_peso_bruto: number;
  transportador_peso_liquido: number;
  protocolo_nfe?: string;
  cstat_nfe?: number;
  xmotivo_nfe?: string;
  justificativa_cancelamento?: string;
  status: NotaDeSaidaSerializerStatusEnum;
  pdf?: File;
  informacoes_adicionais_interesse_fisco?: string;
  notas_fiscais_referenciadas: string;
  codigo_numerico?: string;
  licenca?: number | string;
  cliente?: number | string;
  emitente?: number | string;
  transportador?: number | string;
  total: number;
  subtotal: number;
  total_desconto: number;
  total_acrescimo: number;
}
