import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NuvelModal from "components/nuvel/Modal";
import { TransacaoFaturamentoSerializer } from "data/interfaces/financeiro/TransacaoFaturamentoSerializer";
import { TransacaoFaturamentoModel } from "data/models";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";

interface IModalOperacoes {
  id: number;
  modal: {
    id: number;
    open: boolean;
    conta_id: number | undefined;
  };
  handleModal: (id: number) => void;
}

interface IList {
  page: number;
  totalCount: number;
  rows: TransacaoFaturamentoSerializer[];
}

const ModalOperacoes: React.FC<IModalOperacoes> = ({
  id,
  modal,
  handleModal,
}) => {
  const isOpen = id === modal.id && modal.open;

  const [list, setList] = useState<{
    entradas: IList;
    saidas: IList;
  }>({
    entradas: {
      page: 0,
      totalCount: 0,
      rows: [],
    },
    saidas: {
      page: 0,
      totalCount: 0,
      rows: [],
    },
  });

  const loadData = useCallback(async () => {
    const entradas = await TransacaoFaturamentoModel.get(undefined, {
      conta_entrada: modal.conta_id,
    });
    const saidas = await TransacaoFaturamentoModel.get(undefined, {
      conta_saida: modal.conta_id,
    });

    setList({
      entradas,
      saidas,
    });
  }, [modal.conta_id]);

  useEffect(() => {
    if (isOpen) {
      loadData();
    }
  }, [isOpen, loadData]);

  return (
    <NuvelModal
      title="Operações do Cliente"
      open={isOpen}
      btnSave={() => handleModal(id)}
      saveOnly
      saveText="Fechar"
      fullWidth
      maxWidth="sm"
    >
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} align="center">
                Entradas
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.entradas?.rows?.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    {dayjs(item.data_inclusao).format("DD/MM/YYYY hh:mm")}
                  </TableCell>
                  <TableCell>{item.valor?.toBRL()}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} align="center">
                Saídas
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.saidas?.rows?.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    {dayjs(item.data_inclusao).format("DD/MM/YYYY hh:mm")}
                  </TableCell>
                  <TableCell>{item.valor?.toBRL()}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </NuvelModal>
  );
};

export default ModalOperacoes;
