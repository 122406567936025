// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorObjectConvert(err: any) {
  //Converte o erro em um Array com as entradas.

  if (err.response) {
    if (typeof err.response.data === "object") {
      if (err.response.data.message) {
        return err.response.data.message;
      }
      return Object.entries(err.response.data);
    } else {
      return err.response.data;
    }
  } else {
    return err.message;
  }
}
