import { NuvelSelectValue } from "utils/types";

/* eslint-disable @typescript-eslint/no-explicit-any */
const handleChange = (
  e:
    | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    | {
        target: {
          name: string;
          value: NuvelSelectValue | boolean;
        };
      },
  setState: React.Dispatch<React.SetStateAction<any>>
) => {
  const { name, value } = e.target;
  setState((prevState: any) => ({
    ...prevState,
    [name]: value,
  }));
};

export default handleChange;
