/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import AUTOCOMPLETE_MODELS, {
  AutocompleteModel,
  ReferenceModelMap,
  ValidReference,
} from "constants/nuvel/Autocomplete";
import useDebounce from "hooks/useDebounce";
import { Chip } from "@mui/material";
import { NuvelMultipleSelectValue, NuvelSelectValue } from "utils/types";

interface OptionType {
  label: string;
  value: number | string;
  object?: any;
}

export interface NuvelAutocompleteEvent {
  target: { name: string; value: NuvelSelectValue };
}

export interface NuvelAutocompleteBaseProps<
  K extends ValidReference = ValidReference
> {
  reference: K;
  name: string;
  label: string;
  disabled?: boolean;
  filter?: {
    [key: string]: any;
  };
  clearAfterSelect?: boolean;
  helperText?: string;
  error?: boolean;
}
interface NuvelAutocompleteSingleProps<K extends ValidReference>
  extends NuvelAutocompleteBaseProps<K> {
  multiple?: false;
  value: NuvelSelectValue;
  onChange: (
    event: NuvelAutocompleteEvent,
    value: NuvelSelectValue,
    fullValue: ReferenceModelMap[K] | undefined,
    reason?: string,
    details?: any
  ) => void;
  onFetchCurrentObject?: (value: ReferenceModelMap[K]) => void;
}

type MaybeArray<T, M extends boolean> = M extends true ? T[] : T;

interface NuvelAutocompleteMultipleProps<K extends ValidReference>
  extends NuvelAutocompleteBaseProps<K> {
  multiple: true;
  value: NuvelMultipleSelectValue;
  onChange: (
    event: NuvelAutocompleteEvent,
    value: NuvelMultipleSelectValue,
    fullValue: ReferenceModelMap[K][] | undefined,
    reason?: string,
    details?: any
  ) => void;
  onFetchCurrentObject?: (value: ReferenceModelMap[K][]) => void;
}
function NuvelAutocomplete<K extends ValidReference>({
  name,
  reference,
  label,
  value,
  onChange,
  multiple = false,
  filter,
  clearAfterSelect = false,
  helperText,
  error,
  onFetchCurrentObject,
  ...props
}: NuvelAutocompleteSingleProps<K> | NuvelAutocompleteMultipleProps<K>) {
  const [options, setOptions] = React.useState<OptionType[] | undefined>();
  const [inputValue, setInputValue] = React.useState<string>("");

  const debouncedInputValue = useDebounce(inputValue, 250);

  const referenced_model = React.useMemo(() => {
    return AUTOCOMPLETE_MODELS.find(
      (model) => model.reference === reference
    ) as AutocompleteModel<ReferenceModelMap[K], ReferenceModelMap[K]>;
  }, [reference]);

  if (!referenced_model) {
    throw new Error(
      `Modelo de autocomplete não encontrado para a referência ${reference}`
    );
  }

  const { data, isValidating } = referenced_model.model.useModel({
    search: debouncedInputValue,
    ...(referenced_model.filters || {}),
    ...(filter || {}),
  });
  type CurrentObjectType = MaybeArray<ReferenceModelMap[K], typeof multiple>;
  const [currentObject, setCurrentObject] =
    React.useState<CurrentObjectType | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (data && referenced_model && !isValidating) {
      const finalOptions: OptionType[] = [];
      finalOptions.push(
        ...data.rows
          .filter((item: any) => item[referenced_model.label_by] !== "")
          .map((item: any) => ({
            label: item[referenced_model.label_by] as string,
            value: item[referenced_model.value_by] as number | string,
            object: item,
          }))
      );
      if (currentObject) {
        let isCurrentObjectInOptions = false;
        if (multiple && Array.isArray(currentObject)) {
          isCurrentObjectInOptions = currentObject.every((item: any) =>
            finalOptions.some(
              (option) => option.value === item[referenced_model.value_by]
            )
          );
        } else if (currentObject) {
          isCurrentObjectInOptions = finalOptions.some(
            (option) =>
              option.value ===
              (currentObject as ReferenceModelMap[K])[referenced_model.value_by]
          );
        }

        if (!isCurrentObjectInOptions) {
          if (multiple && Array.isArray(currentObject)) {
            finalOptions.push(
              ...currentObject.map((item: any) => ({
                label: item[referenced_model.label_by] as string,
                value: item[referenced_model.value_by] as number | string,
                object: item,
              }))
            );
          } else {
            finalOptions.push({
              label: (currentObject as ReferenceModelMap[K])[
                referenced_model.label_by
              ] as string,
              value: (currentObject as ReferenceModelMap[K])[
                referenced_model.value_by
              ] as number | string,
              object: currentObject as ReferenceModelMap[K],
            });
          }
        }
      }

      setOptions(finalOptions);
    }
  }, [data, referenced_model, isValidating, multiple, currentObject]);

  const fetchCurrentObject = React.useCallback(
    async (value: (string | number)[] | number | string) => {
      setIsLoading(true);
      const objects = await referenced_model.model.get(undefined, {
        [String(referenced_model.value_by) + "__in"]: value,
      });
      if (multiple) {
        setCurrentObject(objects.rows);
        // @ts-expect-error TS cannot understand this yet
        onFetchCurrentObject?.(objects.rows);
      } else {
        setCurrentObject(objects.rows?.[0]);
        // @ts-expect-error TS cannot understand this yet
        onFetchCurrentObject?.(objects.rows?.[0]);
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multiple, referenced_model.model]
  );

  React.useEffect(() => {
    if (value) {
      fetchCurrentObject(value);
    } else {
      setCurrentObject(null);
    }
  }, [fetchCurrentObject, value]);

  const selectedOptions = React.useMemo(() => {
    if (multiple) {
      return (
        options?.filter((option) =>
          (value as (string | number)[]).includes(option.value)
        ) || []
      );
    } else {
      return options?.find((option) => option.value === value) || null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const handleInputChange = React.useCallback(
    (_event: React.SyntheticEvent, newInputValue: string) => {
      setInputValue(newInputValue);
    },
    []
  );

  const handleChange = React.useCallback(
    (
      _event: React.SyntheticEvent,
      newValue: OptionType | OptionType[] | null,
      fullValue: ReferenceModelMap[K],
      _reason: string,
      _details?: any
    ) => {
      let value: NuvelSelectValue | NuvelMultipleSelectValue = null;
      if (newValue && multiple) {
        value = (newValue as OptionType[]).map((option) => option.value);
      } else if (newValue) {
        value = (newValue as OptionType)?.value;
      }
      onChange(
        {
          target: {
            name,
            // @ts-expect-error TS cannot understand this yet
            value,
          },
        },
        value,
        fullValue,
        _reason,
        _details
      );
      if (clearAfterSelect) {
        setInputValue("");
      }
    },
    [multiple, clearAfterSelect, onChange, name]
  );

  return (
    <Autocomplete<OptionType, boolean, false, false>
      {...props}
      multiple={multiple}
      loading={options === undefined || isLoading || isValidating}
      disablePortal
      id={`combo-box-${name}`}
      options={options || []}
      value={multiple ? selectedOptions : selectedOptions || null} // Ajuste aqui
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={(e, v, r, d) =>
        handleChange(
          e,
          v,
          Array.isArray(v) ? v?.map((item) => item.object) : v?.object,
          r,
          d
        )
      }
      fullWidth
      renderInput={(params) => (
        <TextField
          variant="filled"
          {...params}
          label={label}
          helperText={helperText}
          error={error}
        />
      )}
      inputMode="search"
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            color="primary"
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
}

export default React.memo(NuvelAutocomplete) as typeof NuvelAutocomplete;
