/* eslint-disable @typescript-eslint/no-explicit-any */
import { CaixaPDVSerializer } from "data/interfaces/pdv/CaixaPDVSerializer";
import { LojaModel } from "data/models";
import CaixafechamentoCego from "reports/retaguarda/fechamento_cego";
import { imprimirDoc } from "utils/functions/print";

const validatePagamentoIgual = (item: any, itemComparado: any[]) => {
  const pagamentoComparado = itemComparado.find(
    (itemComparado) => item.forma === itemComparado.forma
  );

  if (
    pagamentoComparado === undefined ||
    parseFloat(item.total) !== parseFloat(pagamentoComparado.total)
  ) {
    return true;
  }
  return false;
};

const sortByName = (d: any[]) => {
  if (d && d.length > 0) {
    return d.sort((a: { forma_nome: any }, b: { forma_nome: any }) => {
      if (a.forma_nome < b.forma_nome) {
        return -1;
      }
      if (a.forma_nome > b.forma_nome) {
        return 1;
      }
      return 0;
    });
  }
  return [];
};

const calculateSangriaSuprimento = (operacoes: any[], tipo: any) => {
  if (operacoes && operacoes.length > 0) {
    return parseFloat(
      operacoes.reduce((acc: any, item: { tipo: any; valor: any }) => {
        if (item.tipo === tipo) {
          return parseFloat(acc) + parseFloat(item.valor);
        }
        return parseFloat(acc);
      }, 0)
    );
  }
  return 0;
};

const validateField = (field1: any, field2: any) => {
  if (parseFloat(field1) !== parseFloat(field2)) {
    return true;
  }
  return false;
};

const printCupom = async (item: CaixaPDVSerializer) => {
  const _loja = await LojaModel.get(item.loja as number);

  await imprimirDoc(
    <CaixafechamentoCego
      loja={_loja}
      validateField={validateField}
      validatePagamentoIgual={validatePagamentoIgual}
      sortByName={sortByName}
      calculateSangriaSuprimento={calculateSangriaSuprimento}
      caixa={item}
      fechamento_cego={item.fechamentos_cegos[0]}
    />
  );
};

export {
  printCupom,
  validateField,
  calculateSangriaSuprimento,
  sortByName,
  validatePagamentoIgual,
};
