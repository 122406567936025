import { CatalogoSerializer } from "data/interfaces/catalogo/CatalogoSerializer";
import MainModule from "data/main.ts";

export const model_url: string = "catalogo";

class CatalogoModel extends MainModule<
  CatalogoSerializer,
  CatalogoSerializer
> {}

export default new CatalogoModel(model_url);
