import { Box, Button, Typography, useTheme } from "@mui/material";
import { AuthControlle } from "data/controller";
import React from "react";

const WebHome: React.FC = () => {
  const theme = useTheme();
  const licenca = localStorage.getItem("licenca");
  const { data: user } = AuthControlle.useCurrentUser();
  const [error, setError] = React.useState<Error | null>(null);

  if (error) {
    throw error;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="80vh"
    >
      <Typography variant="h4">
        <span style={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
          Nuvel Retaguarda
        </span>
      </Typography>
      <Typography variant="h6">
        Olá{" "}
        <span style={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
          {user?.usuario}
        </span>
        ! Seja bem-vindo novamente 😄
      </Typography>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.href = "https://legacy.nuvel.com.br";
        }}
      >
        Não quero o novo! Me leva para o antigo!
      </Button> */}
      {licenca === "demo" && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setError(new Error("Simulação de erro 500"));
          }}
          sx={{ mt: 2 }}
        >
          DEMO: simulação de erro 500
        </Button>
      )}
    </Box>
  );
};

export default WebHome;
