/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from "react";
import { RelatorioModel } from "data/models/relatorio";
import { useAppContext } from "hooks";
import { Relatorio } from "components/retaguarda/relatorios";

interface ReportData {
  rows: any[];
  totalCount: number;
  totais?: any;
}

interface Report {
  data: ReportData;
  config: Relatorio;
  page: number;
}

const useReport = (relatorios: Relatorio[], relatorio: number) => {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<Report>({
    data: { rows: [], totalCount: 0 },
    config: {} as Relatorio,
    page: 1,
  });
  const {
    dialog: { showMessage },
  } = useAppContext();

  const handleSubmit = useCallback(
    (
      e?: React.FormEvent<HTMLFormElement>,
      currentPage = 1,
      pageSize = 10,
      after?: any
    ) => {
      if (e) e.preventDefault();
      setLoading(true);

      RelatorioModel.action(
        "get",
        relatorios[relatorio].url,
        undefined,
        undefined,
        {
          ...relatorios[relatorio].filtros,
          page: currentPage,
          page_size: pageSize,
          totais: relatorios[relatorio].totais,
        }
      ).then((response) => {
        setLoading(false);
        if (response.data.totalCount === 0) {
          return showMessage(
            "Não foi possível gerar o relatório, pois não há dados para o filtro realizado.",
            "Nenhum dado encontrado."
          );
        }
        if (after) {
          after({
            data: response.data,
            config: relatorios[relatorio],
            page: currentPage,
          });
        } else {
          setReport({
            data: response.data,
            config: relatorios[relatorio],
            page: currentPage,
          });
        }
      });
    },
    [relatorios, relatorio, showMessage]
  );

  return { loading, report, handleSubmit };
};

export default useReport;
