import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {
  Collapse,
  Divider,
  IconButton,
  Pagination,
  TableHead,
  Typography,
} from "@mui/material";
import PaperWithLoad from "components/nuvel/PaperWithLoad";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import dayjs from "dayjs";

export interface SimpleTableColumn<T> {
  label: string;
  accessor: keyof T | ((row: T, index: number) => React.ReactNode);
  flex?: number;
  type?: string;
  align?: "left" | "right" | "center";
  minWidth?: number;
}

export interface SimpleListChild<T> {
  label: string;
  key: string;
  columns: SimpleTableColumn<T>[];
  flex?: number;
}

interface SimpleListProps<T> {
  columns: SimpleTableColumn<T>[];
  data: T[];
  childs?: SimpleListChild<T>[];
  page?: number;
  total_pages?: number;
  handleChangePage?: (event: React.ChangeEvent<unknown>, value: number) => void;
  totals?: T;
}

interface SimpleListRowProps<T> {
  columns: SimpleTableColumn<T>[];
  row: T;
  childs?: SimpleListChild<T>[];
  key: number | string;
  index: number;
  onRowClick?: (row: T, key: number | string) => void;
  formatbytype: (
    data: T,
    accessor: keyof T,
    type: SimpleTableColumn<T>["type"],
    _index: number | string
  ) => string;
}

export type Order = "asc" | "desc";

const SimpleListRow = <T,>({
  columns,
  row,
  childs,
  onRowClick,
  formatbytype,
  index,
}: SimpleListRowProps<T>) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        hover
        key={index}
        onClick={() => (onRowClick ? onRowClick(row, index) : undefined)}
      >
        {childs && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((col, key1) => (
          <TableCell key={key1} align={col.align}>
            {typeof col.accessor === "function"
              ? col.accessor(row, index)
              : formatbytype(row, col.accessor as keyof T, col.type, index)}
          </TableCell>
        ))}
      </TableRow>
      {childs?.map((child) => (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 70 }}
            colSpan={columns.length + 1}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Typography variant="body1" mt={2} ml={2}>
                {(row[child.key as keyof T] as unknown[]).length ?? 0}{" "}
                {child.label}
              </Typography>
            </Collapse>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <SimpleList
                columns={child.columns}
                data={row[child.key as keyof T] as T[]}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
};

const SimpleList = <T,>({
  columns,
  data,
  page,
  total_pages,
  handleChangePage,
  childs,
  totals,
}: SimpleListProps<T>) => {
  function formatbytype(
    data: T,
    accessor: keyof T,
    type: SimpleTableColumn<T>["type"],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _index?: number | string
  ) {
    if (data) {
      const final_data = String(
        typeof data === "object" ? data[accessor] : data
      );

      switch (type) {
        case "date":
          return dayjs(final_data).format("DD/MM/YYYY");
        case "datetime":
          return dayjs(final_data).format("DD/MM/YYYY HH:mm");
        case "time":
          return dayjs(final_data).format("HH:mm");
        case "percent":
          return `${Number(final_data).toFixed(2)}%`;
        case "money":
          return Number(final_data)?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        default:
          return final_data;
      }
    }
    return data[accessor] as string;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PaperWithLoad
        loading={!data}
        sx={{
          width: "100%",
          borderRadius: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        elevation={0}
      >
        <TableContainer
          sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
        >
          <Table
            sx={{ flex: "1 1 auto" }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow>
                {childs && <TableCell />}
                {columns.map((column) => (
                  <TableCell
                    key={column.accessor as string}
                    align={column.align || "left"}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                maxHeight: 500,
                flex: "1 1 auto",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {data?.map((row: T, index: number) => (
                <React.Fragment key={index}>
                  <SimpleListRow
                    columns={columns}
                    row={row}
                    key={index}
                    childs={childs}
                    onRowClick={() => {}}
                    formatbytype={formatbytype}
                    index={index}
                  />
                </React.Fragment>
              ))}
              {totals && (
                <TableRow hover>
                  {childs && <TableCell />}
                  {columns?.map((col, key1) => {
                    if (typeof col.accessor === "function") {
                      return null;
                    }
                    if (!totals[col.accessor]) return <TableCell key={key1} />;
                    return (
                      <TableCell key={key1} align={col.align}>
                        {formatbytype(
                          totals[col.accessor] as T,
                          col.accessor,
                          col.type
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {page && total_pages && handleChangePage && (
          <>
            <Divider variant="middle" />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: 56 }}
            >
              <Pagination
                page={page}
                onChange={handleChangePage}
                count={total_pages}
                variant="outlined"
                color="primary"
              />
            </Box>
          </>
        )}
      </PaperWithLoad>
    </Box>
  );
};

const MemoizedSimpleList = React.memo(SimpleList) as <T>(
  props: SimpleListProps<T>
) => JSX.Element;
export default MemoizedSimpleList;
